import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-posaction',
  templateUrl: './posaction.component.html',
  styleUrls: ['./posaction.component.css']
})
export class PosactionComponent implements OnInit {
AddForm: FormGroup;
isSubmitted  = false;
 Pos_Type:any;


 
  constructor(
    public dialogRef: MatDialogRef<PosactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
	public formBuilder: FormBuilder
	) { 
		this.Pos_Type = this.data.Type;
		
		if(this.data.Type == 'SP' || this.data.Type == 'Dealer'  || this.data.Type ==  'Franchise' ){ 
			 this.AddForm  =  this.formBuilder.group({
					SP: [''],  
					Dealer: [''],  
					POSC: [''],  
			 });
		} 
		if(this.data.Type == 'POS'){
			 this.AddForm  =  this.formBuilder.group({
					SP: [''],  
					Dealer: [''],  
					POSC: [''],  	 
					Franchise: [''],  	 
			 });
		}
		 
	}

  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
  ngOnInit() {
	  //alert(this.data.Type);
	  
		
  }
  
  onChangeCategory(e){  
      console.log(e.target.value);
   }
	
  SubmitForm(){
	    
		if(this.AddForm.invalid){
		  return;
		}else{
		 
			console.log(this.AddForm.value);
			const formData = new FormData(); 
			
			 
			formData.append('Assign_Types',JSON.stringify(this.AddForm.value));
			formData.append('User_Id',this.api.GetUserData('User_Id'));
			formData.append('POS_Type',this.Pos_Type);
			 
			
			//this.api.IsLoading();
			this.api.HttpPostType('em/POS/Assign',formData).then((result) => {
			//this.api.HideLoading();
			
				if(result['Status'] == true){
					this.CloseModel();
					
					this.api.ToastMessage(result['Message']); 
				}else{
					this.api.ErrorMsg(result['Message']);
				}
				
			}, (err) => { 
			  // Error log
			  this.api.HideLoading();
			  //console.log(err.message);
			 this.api.ErrorMsg(err.message);
			});
  
		}
  }
  

}
