// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//declare var require: any;
//const pack = require('./environment.json');
//alert(pack.name);

var user = JSON.parse(localStorage.getItem('User'));
//var baseUrl = "https://api.policyonweb.com/API/v1"; 
//var baseUrl = "https://uat.policyonweb.com/API/v1";  
var baseUrl = "https://preprodapi.policyonweb.com/v1"; 
var BaseUrlCrm = "http://13.127.142.101/sanity/ci/bony/crmdev/api"; 
         
if(user != null){
	if(user['Is_UAT'] == 1){
		baseUrl = "https://api.policyonweb.com/UAT/v1"; 
	}
}  

export const environment = {
  production: false,
	apiUrl : baseUrl,
	BaseUrlCrm: BaseUrlCrm,
	SOCKET_ENDPOINT: 'http://13.234.102.79:3000', 
}; 
  
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
