import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-paymentlink',
  templateUrl: './paymentlink.component.html',
  styleUrls: ['./paymentlink.component.css']
})
export class PaymentlinkComponent implements OnInit {

PaymentLinkStr:any='';
MobileNo:any;
MobileNoDiv:any='No';
snackbar_msg:any='';

  constructor(public api : ApiService) { 
	this.PaymentLinkStr='https://silversquare.co.in/payment';
  }

  ngOnInit() {
  }
  
  CopyLink(){
	this.MobileNoDiv='No';
	
	var textToBeCopied = this.PaymentLinkStr;  
	let textarea = null;
	
    textarea = document.createElement("textarea");
    textarea.style.height = "0px";
    textarea.style.left = "-100px";
    textarea.style.opacity = "0";
    textarea.style.position = "fixed";
    textarea.style.top = "-100px";
    textarea.style.width = "0px";
    document.body.appendChild(textarea);
    // Set and select the value (creating an active Selection range).
    textarea.value = textToBeCopied;
    textarea.select();
    // Ask the browser to copy the current selection to the clipboard.
    let successful = document.execCommand("copy");
    if (successful) {
      // do something
	  this.Totast('Copied to clipboard.');
    } else {
      // handle the error
    }
    if (textarea && textarea.parentNode) {
      textarea.parentNode.removeChild(textarea);
    }  
	 
       
  }
  
  ShareWhatsApp(){
	this.MobileNoDiv='No';
	window.open("whatsapp://send?text="+this.PaymentLinkStr);
  }
  
  
  OpenMobileBoxDiv(){
	 this.MobileNoDiv='Yes';
  }
  
  
  SendTextSMS(){
	 var phoneno = /^\d{10}$/; 
	 var newstr = new String(this.MobileNo);
	 if((newstr!='') && (!newstr.match(phoneno)) ){
		//alert("Please enter vaild mobile no.");
		 
		this.Totast('Please enter vaild mobile no.');
		
	 }else{
		  
		   const formData = new FormData();
		 
		   formData.append('User_Id',this.api.GetUserId());
		   formData.append('MobileNo',this.MobileNo);
		   formData.append('PaymentLink',this.PaymentLinkStr);
		  
			this.api.IsLoading();
			this.api.HttpPostType('other/Paymentlink/SendLinkOnMobileNo',formData).then((result) => {
			this.api.HideLoading();
			
				if(result['Status'] == true){
					this.MobileNoDiv='No';
					this.MobileNo = ''; 
					this.Totast('SMS Sent Success.');
				}else{
					 this.api.ErrorMsg(result['Message']);
				}
				
			}, (err) => { 
			  // Error log
			  this.api.HideLoading();
			  //console.log(err.message);
			   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
			}); 
			
	 }
  
	
  }
  
  Totast(msg){
	 this.snackbar_msg = msg;
	 var x = document.getElementById("snackbar"); 
		x.className = "show";
		setTimeout(() => { x.className = x.className.replace("show", ""); }, 3000); 
  }

}
