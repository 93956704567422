import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-agent-sr-action',
  templateUrl: './agent-sr-action.component.html',
  styleUrls: ['./agent-sr-action.component.css']
})
export class AgentSrActionComponent implements OnInit {

Id:any;
row:any=[];
 
  constructor(
	public dialogRef: MatDialogRef<AgentSrActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
  ) { }

  ngOnInit() {
	this.Id = this.data.Id;
	this.GetSR();   
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
  GetSR(){
	//this.api.IsLoading();
	this.api.Call('sr/MainSR/ViewById?Id='+this.Id).then((result) => {
	//this.api.HideLoading();
	
		if(result['Status'] == true){
			//this.CloseModel();
			
			this.row = result['Data'];
			
			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }

}
