import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-agent-orc-details-view',
  templateUrl: './agent-orc-details-view.component.html',
  styleUrls: ['./agent-orc-details-view.component.css']
})
export class AgentOrcDetailsViewComponent implements OnInit {

QCForm: FormGroup;
isSubmitted  = false;
 
Id:any;
Agent_Id:any;
Base_Url:any;
row:any=[];
RM_Users:any=[];
RM_Id:any=[];
Documents:any=[];
User_Rights:any=[];
 
 
selectedFiles: File;
PanCard:File;
AadharCardFront:File;
AadharCardBack:File;
Qualification:File;
Cheque:File;
Photo:File;
Signature:File;

IsDisabled:any=true;

  constructor(
	public dialogRef: MatDialogRef<AgentOrcDetailsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	private formBuilder: FormBuilder,
	public api : ApiService,
  ) { 
	   
	this.QCForm  =  this.formBuilder.group({
		QC_Status: ['', [Validators.required]], 
		QC_Remark: ['', [Validators.required]], 
	});
  }

  ngOnInit() {
	this.Id = this.data.Id;
	this.GetDetails();   
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
   get FC() { return this.QCForm.controls; }
  
  QC_Status(e){
	  var Type = e.target.value;
	  console.log(Type);
	  
	  const QC_Remark_Control = this.QCForm.get('QC_Remark');
	  
	  if(Type=='1'){
		QC_Remark_Control.setValidators(null);
	  }else{
		QC_Remark_Control.setValidators([Validators.required]); 
	  }
	  
	  QC_Remark_Control.updateValueAndValidity();
	  
  }
   
  SubmitQC(){
 
    this.isSubmitted = true;
    if(this.QCForm.invalid){
      return;
    }else{
		 
		var fields = this.QCForm.value; 
		const formData = new FormData();
		
			formData.append('User_Id',this.api.GetUserId()); 
			 
			formData.append('ORC_QC',this.User_Rights['ORC_QC']); 
			formData.append('ORC_Banking_QC',this.User_Rights['ORC_Banking_QC']); 
			
			formData.append('QC_Id',this.row.ORC_Primary_Id);
			formData.append('QC_Status',fields['QC_Status']); 
			formData.append('QC_Remark',fields['QC_Remark']); 
			  
		this.api.IsLoading();
		this.api.HttpPostType('sr/ORC/SubmitQC',formData).then((result) => {
		this.api.HideLoading();
			 
			if(result['Status'] == true){
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
				
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg(err.message);
	    });
		 
	}
	
  }
  
  GetDetails(){
	  

	//this.api.IsLoading();
	this.api.Call('sr/ORC/ViewDetailsById?Id='+this.Id+'&User_Id='+this.api.GetUserId()).then((result) => {
	//this.api.HideLoading();
	
		if(result['Status'] == true){
			//this.CloseModel();
			
			this.row = result['Data'];
			this.User_Rights = result['User_Rights'];
			this.RM_Id = this.row.POS_RM_Id;
			 
			this.Base_Url = result['Base_Url'];
		 
			this.Documents = result['Documents'];
			
			//alert(this.row.Is_Edit);
			if(result['AdminLogin'] == 1){
				this.IsDisabled = false;
			}else{
				if((this.row.Status == 0)){
					this.IsDisabled = false;
					//alert(this.IsDisabled);
				}else{
					this.IsDisabled = true;
				}
			}

			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
   
  ViewDocument(name){
	  let url;
	  if(this.row.Docs_Status == '2'){ //POS Docs url 
		 url = name;  
	  }else{
		 url = this.Base_Url+name; 
	  }
	  
	  //alert(url); 
	  window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
  
  
  
  UploadDocs(event,Type) 
	{
		this.selectedFiles = event.target.files[0];
		
		if(event.target.files && event.target.files[0])
		{
		
			console.log(this.selectedFiles);
			console.log(this.selectedFiles.name); 
			var str = this.selectedFiles.name;
			var ar =  str.split(".");
			console.log(ar); 
			var ext;
			for(var i=0; i<ar.length; i++) ext=ar[i].toLowerCase();
			console.log(ext); 
			 
			if(ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'pdf'){
				console.log('Extenstion is vaild !');
				var file_size = event.target.files[0]['size'];
				const Total_Size = Math.round((file_size / 1024)); 
				
				console.log(Total_Size+ ' kb'); 
				 
				if(Total_Size >= 1024*3) { // allow only 3 mb
					this.api.ErrorMsg('File size is greater than 3 mb');

					
					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }

				}else{
					
					/*
					if(Type == 'PanCard'){this.PanCard = this.selectedFiles; }
					if(Type == 'AadharCardFront'){this.AadharCardFront = this.selectedFiles; }
					if(Type == 'AadharCardBack'){this.AadharCardBack = this.selectedFiles; }
					if(Type == 'Qualification'){this.Qualification = this.selectedFiles; }
					if(Type == 'Cheque'){this.Cheque = this.selectedFiles; }
					if(Type == 'Photo'){this.Photo = this.selectedFiles; }
					if(Type == 'Signature'){this.Signature = this.selectedFiles; }
					*/

					this.Upload(Type);
				}
			}else{
				console.log('Extenstion is not vaild !');
				 
				this.api.ErrorMsg('Please choose vaild file ! Example :- PNG,JPEG,JPG,PDF');

					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }
	 
			}
		
		}
	}
	
  async Upload(Type){
	  const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to upload document?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm){
	
			const formData = new FormData(); 
			
			formData.append('User_Id',this.api.GetUserId());
			formData.append('Agent_User_Id',this.Id);
			formData.append('Document_Type',Type); 
			formData.append('Document',this.selectedFiles); 
			
			/*
			if(Type == 'PanCard'){ formData.append('Document',this.selectedFiles); }
			if(Type == 'AadharCardFront'){ formData.append('Document',this.AadharCardFront); }
			if(Type == 'AadharCardBack'){  formData.append('Document',this.AadharCardBack); }
			if(Type == 'Qualification'){ formData.append('Document',this.Qualification); }
			if(Type == 'Cheque'){ formData.append('Document',this.Cheque); }
			if(Type == 'Photo'){ formData.append('Document',this.Photo); }
			if(Type == 'Signature'){ formData.append('Document',this.Signature); }
			*/
		    
			this.api.IsLoading();
			this.api.HttpPostType('sr/RMAgentReport/Agent_Document_Update',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){ 
					this.api.ToastMessage(result['Message']); 
				    this.Documents = result['Documents'];
				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			}); 
		}
  }

}
