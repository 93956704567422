import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup,FormControl,FormArray, Validators } from  '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { Router,ActivatedRoute } from  '@angular/router';

@Component({
  selector: 'app-payout-agent-group-request-view',
  templateUrl: './payout-agent-group-request-view.component.html',
  styleUrls: ['./payout-agent-group-request-view.component.css']
})
export class PayoutAgentGroupRequestViewComponent implements OnInit {


  
Id:any;

PayInForm:FormGroup;
isSubmitted  = false;

//disabled = false;
//ShowFilter = false;
//limitSelection = false;

dropdownSettings:any={};
LOB_dropdownSettings:any={};
cities:any = [];
selectedItems: any = [];


User_Rights:any = [];
rowAr:any = [];

Brokers_Ar:any = [];
RM_Ar:any = [];
Agents_Ar:any = [];
Ins_Companies_Ar:any = [];
States_Ar:any = [];
RTO_Ar:any = [];
LOB_Ar:any = [];

Source_Ar:any = [];
CPA_Ar:any = [];


Fuel_Type_Ar:any = [];
Body_Type_Ar:any = [];
File_Type_Ar:any = [];

Products_Ar:any = [];
SubProducts_Ar:any = [];

Segment_Ar:any = [];
Classes_Ar:any = [];
Sub_Classes_Ar:any = [];
 

ItemSelection:any=[];	
ItemSelectionProducts:any=[];	
ItemSelectionSubProducts:any=[];	
ItemSelectionSegment:any=[];	
ItemSelectionClass:any=[];	



ItemSelectionNMSegment:any=[];
ItemSelectionNMClass:any=[];
ItemSelectionNMProducts:any=[];	
 
	 

Agents_Placeholder:string = 'Select Agents (0)';

isChecked_Motor: any = false;
isChecked_Health: any = false;
isChecked_NonMotor: any = false;

ButtonType:string='Save';
SubmitType:string='Approve';

BaseUrl:any; // BaseUrl

AssginType:string=''; // Admin,HOD
Effective_Date_Error:string='';
MinEffective_Date:any;
Effective_Date_Flag:any=0;

AgentGroupIndex:any=0;
 
AgentGroupItemSelection:any=[];

  constructor(public api : ApiService,
	public dialogRef: MatDialogRef<PayoutAgentGroupRequestViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	private router: Router,private activatedRoute: ActivatedRoute,
  private fb: FormBuilder) { }

  ngOnInit() {
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'Id',
			textField: 'Name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};
		this.LOB_dropdownSettings = {
			singleSelection: false,
			enableCheckAll: false,
			idField: 'Id',
			textField: 'Name',
		};
		
		
	this.Id = this.data.Id;
	//this.Id = this.activatedRoute.snapshot.paramMap.get('Id');
	console.log(this.Id);
	
	this.GetRow(); 
	
	this.PayInForm = this.fb.group({
		//city: [this.selectedItems]
		Broker_Ids: ['',[Validators.required]],
		//RM_Ids: ['',[Validators.required]],
		//Agent_Ids: ['',[Validators.required]],
		Ins_Compaines_Ids: ['',[Validators.required]],
		States_Ids: [''],
		RTO_Ids: [''],
		Manufacturer_Year: [],
		
		Source: [''],
		CPA: [''],
		Fuel_Type: [''],
		Body_Type: [''], 
		File_Type: [''],
		  
		Product_Ids: ['',[Validators.required]],
		SubProduct_Ids: ['',[Validators.required]],
		Segment_Ids: ['',[Validators.required]],
		Class_Ids: ['',[Validators.required]],
		Sub_Class_Ids: [''],
		
		//PayInOD: [''],
		//PayOutOD: [''],
		//PayInTP: [''],
		//PayOutTP: [''],
		
		//PayInNet: [''],
		//PayOutNet: [''],
		
		//PayInReward: [''],
		//PayOutReward: [''],
		//PayInScheme: [''],
		//PayOutScheme: [''],
		
		Effective_Date: ['',[Validators.required]],
		Remark: ['',[Validators.required]],
		 
		AgentGroup: this.fb.array([]) ,
		
	});
	
	
	
	
	//this.onChangeEffective_Date();
	  
	  
  }
  
  get FC() { return this.PayInForm.controls; }
  
  onSubmitType(Type){
	this.SubmitType = Type;  
	console.log(this.SubmitType);
  }
  
  AgentGroup() : FormArray {
    return this.PayInForm.get("AgentGroup") as FormArray
  }
  
  removeNewAgentGroup(i:number) {
    this.AgentGroup().removeAt(i);
  }
   
  NewAgentGroup(): FormGroup {
    return this.fb.group({
      AgentGroup_Ids: [this.Agents_Ar], 
      PayOutOD: {value: this.rowAr.PayOutOD, disabled: (this.rowAr.PayOutOD==0)?true:false},
      PayOutTP: {value: this.rowAr.PayOutTP, disabled: (this.rowAr.PayOutTP==0)?true:false},
      PayOutNet: {value: this.rowAr.PayOutNet, disabled: (this.rowAr.PayOutNet==0)?true:false},
      PayOutReward: {value: this.rowAr.PayOutReward, disabled: (this.rowAr.PayOutReward==0)?true:false},
      PayOutScheme: {value: this.rowAr.PayOutScheme, disabled: (this.rowAr.PayOutScheme==0)?true:false},
    })
  }
  
  addAgentGroup(index) {
	  
		this.AgentGroupIndex++;
		console.log(this.AgentGroupIndex);
		
		console.log(this.PayInForm.get("AgentGroup").value);
		
		if(this.PayInForm.get("AgentGroup").value.length>0){
			
			this.AgentGroup().push(this.NewAgentGroup());

			
			/*
			const formData = new FormData();
		 
			formData.append('PayIn_Id',this.Id);
			formData.append('User_Id',this.api.GetUserId());
			formData.append('AgentGroup',JSON.stringify(this.PayInForm.get("AgentGroup").value));
			 
			
			this.api.IsLoading();
			this.api.HttpPostType('pay/PayoutRMAuthority/AddAgentGroup',formData).then((result) => {
			this.api.HideLoading();
			
				 
				if(result['Status'] == true){
				 
				}else{
					 this.api.ErrorMsg(result['Message']);
				}
				
				
				
			}, (err) => { 
			   this.api.HideLoading();
			   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
			}); 
			*/
			
		}else{
			this.AgentGroup().push(this.NewAgentGroup());
		}
  }
   
  
  
  CloseModel(): void { 
    this.dialogRef.close({ 
      Status: 'Model Close'
    });
  }
  
  
  Parse(j){return JSON.parse(j);}
  
  
  ViewDocument(name){
	  let url = this.BaseUrl+'/'+name;
	  //alert(url);
	  window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
  
  GetRow(){ 
	   this.api.IsLoading();
		this.api.Call('pay/PayoutRMAuthority/PayoutRequestViewById?Id='+this.Id+'&User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();
		
			if(result['Status'] == true){
				var row = result['Data'];
				this.User_Rights = result['User_Rights'];
				this.rowAr = row;
				this.BaseUrl = result['BaseUrl'];
				console.log(row);
				//console.log(JSON.parse(row.Product_Ids));
				
			 
				
				this.Brokers_Ar = this.Parse(row.Broker_Ids);
				this.Ins_Companies_Ar = this.Parse(row.Ins_Compaines_Ids);
				this.Agents_Ar = this.Parse(row.Agent_Ids);
				this.States_Ar = this.Parse(row.States_Ids);
				this.RTO_Ar = this.Parse(row.RTO_Ids);
				
				this.Source_Ar = this.Parse(row.Source_Type);
				this.CPA_Ar = this.Parse(row.CPA);
				
				this.Fuel_Type_Ar = this.Parse(row.Fuel_Type); 
				this.File_Type_Ar = this.Parse(row.File_Type_Ids);
				this.Body_Type_Ar = this.Parse(row.Body_Type);
				
				this.Products_Ar = this.Parse(row.Product_Ids);
				this.SubProducts_Ar = this.Parse(row.SubProduct_Ids);
				this.Segment_Ar = this.Parse(row.Segment_Ids);
				this.Classes_Ar = this.Parse(row.Class_Ids);
				this.Sub_Classes_Ar = this.Parse(row.Sub_Class_Ids);
				
				var LOB = this.Parse(row.LOB_Ids);
				console.log(LOB);
				
				this.addAgentGroup(0);
				
				for(var i=0; i<LOB.length; i++){
					if(LOB[i]=='Motor'){
						this.isChecked_Motor = true;
					}
					if(LOB[i]=='Health'){
						this.isChecked_Health = true;
					}
					if(LOB[i]=='Non Motor'){
						this.isChecked_NonMotor = true;
					}
				}
				
				
				const f = this.PayInForm;
				
				f.get('Broker_Ids').setValue(this.Parse(row.Broker_Ids));
				f.get('Ins_Compaines_Ids').setValue(this.Parse(row.Ins_Compaines_Ids));
				//f.get('Agent_Ids').setValue(this.Parse(row.Agent_Ids));
				f.get('States_Ids').setValue(this.Parse(row.States_Ids));
				f.get('RTO_Ids').setValue(this.Parse(row.RTO_Ids)); 
				
				f.get('Source').setValue(this.Parse(row.Source_Type));
				f.get('CPA').setValue(this.Parse(row.CPA));
				
				f.get('Fuel_Type').setValue(this.Parse(row.Fuel_Type));
				f.get('File_Type').setValue(this.Parse(row.File_Type_Ids));
				f.get('Body_Type').setValue(this.Parse(row.Body_Type));
				
				f.get('Product_Ids').setValue(this.Parse(row.Product_Ids));
				f.get('SubProduct_Ids').setValue(this.Parse(row.SubProduct_Ids));
				f.get('Segment_Ids').setValue(this.Parse(row.Segment_Ids));
				f.get('Class_Ids').setValue(this.Parse(row.Class_Ids));
				f.get('Sub_Class_Ids').setValue(this.Parse(row.Sub_Class_Ids));
				
				
				//f.get('PayInOD').setValue(row.PayInOD);
				//f.get('PayOutOD').setValue(row.PayOutOD);
				//f.get('PayInTP').setValue(row.PayInTP);
				//f.get('PayOutTP').setValue(row.PayOutTP);
				
				//f.get('PayInNet').setValue(row.PayInNet);
				//f.get('PayOutNet').setValue(row.PayOutNet);
				
				//f.get('PayInReward').setValue(row.PayInReward);
				//f.get('PayOutReward').setValue(row.PayOutReward);
				//f.get('PayInScheme').setValue(row.PayInScheme);
				//f.get('PayOutScheme').setValue(row.PayOutScheme);
				
				if(row.PayOutOD==0){
					//f.get('PayOutOD').disable();
					//f.get('PayOutOD').setValue('');
				}else{
					//f.get('PayOutOD').setValidators([Validators.required]);
				}
				
				if(row.PayOutTP==0){
					//f.get('PayOutTP').disable();
					//f.get('PayOutTP').setValue('');
				}else{
					//f.get('PayOutTP').setValidators([Validators.required]);
				}
				
				if(row.PayOutNet==0){
					//f.get('PayOutNet').disable();
					//f.get('PayOutNet').setValue('');
				}else{
					//f.get('PayOutNet').setValidators([Validators.required]);
				}
				
				if(row.PayOutReward==0){
					//f.get('PayOutReward').disable();
					//f.get('PayOutReward').setValue('');
				}else{
					//f.get('PayOutReward').setValidators([Validators.required]);
				}
				
				if(row.PayOutScheme==0){
					//f.get('PayOutScheme').disable();
					//f.get('PayOutScheme').setValue('');
				}else{
					//f.get('PayOutScheme').setValidators([Validators.required]);
				}
				
				f.get('Effective_Date').setValue(row.Effective_Date);
				f.get('Remark').setValue(row.Remark);
				
				this.MinEffective_Date = new Date(row.Effective_Date_YMD);
				 
			}else{
				alert(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  alert(err.message);
	    });
    }
	
	
	 
  onItemSelect(item: any,Type:any) {
	console.log('Type : '+ Type);
	console.log('onItemSelect', item);
	
	if(Type == 'AgentGroup'){
		
	}
	
	
	
	 
  }
  
  onItemDeSelect(item: any,Type:any){
		
		console.log('Type : '+ Type);
		console.log('onDeSelect', item);
	}
onItemSelectAgentGroup(item: any,Type:any,index:any){ 
		this.AgentGroupIndex = index;
		console.log('Index : '+ index);
		console.log('Type : '+ Type);
		console.log('onItemSelect', item);
		
		//this.AgentGroupItemSelection = new Array(index);
		
		this.AgentGroupItemSelection.push({Id: item.Id,Name : item.Name});
		console.log(this.AgentGroupItemSelection);
	}
	
   
	
	onSelectAll(items: any,Type:any) {
		console.log('Type : '+ Type);
		console.log('onSelectAll', items);
		if(Type == 'RM'){
			//this.GetAgents();
		}
		if(Type == 'Product'){
			//this.SubProducts('SelectAll');
		}
		if(Type == 'SubProduct'){
			//this.Segments('SelectAll');
		}
		if(Type == 'Segment'){
			//this.Classes('SelectAll');
		}
		if(Type == 'Class'){
			//this.SubClasses('SelectAll');
		}
		if(Type == 'NM_Class'){
			//this.ItemSelectionNMSegment = [];
			//this.NM_Class('SelectAll');
			
		}
		if(Type == 'NM_Product'){
			//this.ItemSelectionNMClass = [];
			//this.NM_Product('SelectAll');
			
		}
		if(Type == 'NM_Sub_Product'){
			//this.ItemSelectionNMProducts = [];
			//this.NM_Sub_Product('SelectAll');
			
		}
		
		
		
		
	  
		
	}
	onDeSelectAll(items: any,Type:any) {
		console.log('Type : '+ Type);
		console.log('onDeSelectAll');
		if(Type == 'RM'){
			//this.Agents_Ar = [];
			//this.Agents_Placeholder = 'Select Agents (0)';
		}
		if(Type == 'Product'){
			//this.SubProducts('DeSelectAll');
		}
		if(Type == 'SubProduct'){
			//this.Segments('DeSelectAll');
		}
		if(Type == 'Segment'){
			//this.Segments('DeSelectAll');
		}
		if(Type == 'Class'){
			//this.SubClasses('DeSelectAll');
		}
		if(Type == 'NM_Class'){
			//this.ItemSelectionNMSegment = [];
			//this.NM_Class('DeSelectAll');
			
		}
		if(Type == 'NM_Product'){
			//this.ItemSelectionNMClass = [];
			//this.NM_Product('DeSelectAll');
			
		}
		if(Type == 'NM_Sub_Product'){
			//this.ItemSelectionNMProducts = [];
			//this.NM_Sub_Product('DeSelectAll');
			
		}
		 
	}
	
	
	
	onChangeInput(Type){ 
		
		 
		
		
	}
	
	//(bsValueChange)="onChangeED($event)"
	/*
	onChangeED(value: Date){
		//console.log(this.rowAr.Effective_Date_YMD);
		//console.log(value);
		
	  let dateOne = new Date(this.rowAr.Effective_Date_YMD); //Year, Month, Date    
	  let dateTwo = new Date(value); //Year, Month, Date
	  
	  var dateOne1 = new Date(dateOne.toString().replace('05:30:00','00:00:00'));
	  
	  
	 
	console.log(dateOne1);
	console.log(dateTwo);
	
	console.log(dateOne1.getTime() +'||'+ dateTwo.getTime());
	
	const Effective_Date_Control = this.PayInForm.get('Effective_Date');
	
	 if (dateTwo.getTime() >= dateOne1.getTime()) {  
		this.Effective_Date_Error = '';
		//alert("Date One is greater than Date Two.");    
	}else {    
		//alert("Date Two is greater than Date One.");  
		this.Effective_Date_Error = 'Effective Date Can not Less than to be Current Effective Date !';	
		Effective_Date_Control.setValue(null);	
		Effective_Date_Control.updateValueAndValidity();
	} 
	
	
	 
				
	}
	*/
	
	 
	onChangeEffective_Date(){
		const Effective_Date_Control = this.PayInForm.get('Effective_Date');
		
	   this.PayInForm.get('Effective_Date').valueChanges
		.subscribe(v => {
			  
			
			if(v!=null && this.Effective_Date_Flag == 1){
				
				 
				console.log(v);
					
				  let dateOne = new Date(this.rowAr.Effective_Date_YMD); //Year, Month, Date    
				  let dateTwo = new Date(v); //Year, Month, Date
				  
				  var dateOne1 = new Date(dateOne.toString().replace('05:30:00','00:00:00'));
				  
				  
				 
				console.log(dateOne1);
				console.log(dateTwo);
				
				console.log(dateOne1.getTime() +'||'+ dateTwo.getTime());
				
				const Effective_Date_Control = this.PayInForm.get('Effective_Date');
				
				 if (dateTwo.getTime() >= dateOne1.getTime()) {  
					this.Effective_Date_Error = '';
					//alert("Date One is greater than Date Two.");    
				}else {    
					//alert("Date Two is greater than Date One.");  
					this.Effective_Date_Error = 'Effective Date Can not Less than to be Current Effective Date !';	
					Effective_Date_Control.setValue(null);	
					Effective_Date_Control.updateValueAndValidity();
				} 
				 
				
			}else{
				this.Effective_Date_Flag = 0;
			}
			
		});
		
	}
	 
	
	
	AddPayIn(){
	  
    console.log(this.PayInForm.value);
	   
		
    this.isSubmitted = true;
    if(this.PayInForm.invalid){
      return;
    }else{
		var fields = this.PayInForm.value; 
		
		 
		
		const formData = new FormData();
		
		var LOB_Ids_Ar = [];
	
		if(this.isChecked_Motor==true){
			LOB_Ids_Ar.push('Motor'); 
		}
		if(this.isChecked_Health==true){
			LOB_Ids_Ar.push('Health');
		}
		if(this.isChecked_NonMotor==true){
			LOB_Ids_Ar.push('Non Motor');
		}
		console.log(LOB_Ids_Ar);
		//console.log(LOB_Ids_Ar.join());
   
		formData.append('PayIn_Id',this.Id);
		formData.append('User_Id',this.api.GetUserId());
		formData.append('User_Role',this.api.GetUserData('User_Role'));
		formData.append('Broker_Ids',JSON.stringify(fields['Broker_Ids']));
		//formData.append('RM_Ids',JSON.stringify(fields['RM_Ids']));
		//formData.append('Agent_Ids',JSON.stringify(fields['Agent_Ids']));
		formData.append('Ins_Compaines_Ids',JSON.stringify(fields['Ins_Compaines_Ids']));
		formData.append('States_Ids',JSON.stringify(fields['States_Ids']));
		formData.append('RTO_Ids',JSON.stringify(fields['RTO_Ids']));
		
		formData.append('LOB_Ids',JSON.stringify(LOB_Ids_Ar));
		
		formData.append('Product_Ids',JSON.stringify(fields['Product_Ids']));
		formData.append('SubProduct_Ids',JSON.stringify(fields['SubProduct_Ids']));
		formData.append('Segment_Ids',JSON.stringify(fields['Segment_Ids']));
		formData.append('Class_Ids',JSON.stringify(fields['Class_Ids']));
		formData.append('Sub_Class_Ids',JSON.stringify(fields['Sub_Class_Ids']));
		formData.append('AgentGroup_Ar',JSON.stringify(fields['AgentGroup']));
		 
		 
		
		formData.append('PayInOD',this.rowAr.PayInOD);
		formData.append('PayInTP',this.rowAr.PayInTP);
		formData.append('PayInNet',this.rowAr.PayInNet);
		formData.append('PayInReward',this.rowAr.PayInReward);
		formData.append('PayInScheme',this.rowAr.PayInScheme);
		  
		
		formData.append('Effective_Date',fields['Effective_Date']);
		formData.append('Remark',fields['Remark']);
		formData.append('SubmitType',this.SubmitType);
		 
		
		this.api.IsLoading();
		this.api.HttpPostType('pay/PayoutRMAuthority/UpdatePayoutRequest',formData).then((result) => {
		this.api.HideLoading();
		
			 
			if(result['Status'] == true){
				 
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
				
				//this.router.navigate(['/rm/payout/Payout-RM-Authority']);
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		   this.api.HideLoading();
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
		
		
	}
	
	
   }
	
	
}
