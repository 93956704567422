import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router,ActivatedRoute } from  '@angular/router';
import { ApiService } from '../../providers/api.service';
import { ConfirmedValidator } from '../../providers/confirmed.validator';

@Component({
  selector: 'app-change-password-login',
  templateUrl: './change-password-login.component.html',
  styleUrls: ['./change-password-login.component.css']
})
export class ChangePasswordLoginComponent implements OnInit {

 loginForm: FormGroup;
  isSubmitted  =  false;
User_Id:any;
 
	
	 constructor(public api : ApiService,
			private router: Router, 
			private activatedRoute: ActivatedRoute, 
			private formBuilder: FormBuilder,
			 
	) { 
	
  
	this.loginForm  =  this.formBuilder.group({   
		Password: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(14)]], 
		Confirm_Password: ['', [Validators.required]], 
		},{
        validator: ConfirmedValidator('Password', 'Confirm_Password')
      });
	
	}
	 ngOnInit() {
		this.User_Id = this.activatedRoute.snapshot.paramMap.get('User_Id');
	 }
	 
	get formControls() { return this.loginForm.controls; }

	ChangePassword(){ 
	   
	 
    this.isSubmitted = true;
    if(this.loginForm.invalid){
      return;
    }else{
		
		var fields = this.loginForm.value; 
		
		const formData = new FormData();
		 
		formData.append('User_Id',this.User_Id);
 
		formData.append('Password',fields['Password']);
		formData.append('Confirm_Password',fields['Confirm_Password']);
		
		this.api.IsLoading();
		this.api.HttpPostType('User/ChangePasswordFirstTimeLogin',formData).then((result) => {
		this.api.HideLoading();
			
			if(result['Status'] == true){
				
				localStorage.setItem('User', JSON.stringify(result['UserData']));
				this.api.changeMessage({IsLogged :"TRUE"});
				//window.location.reload();
				
				this.loadScript();
				
				if( this.api.GetUserData('Logged_In') == 'TRUE'){
				if(this.api.GetUserData('UserType') == 1 && this.api.GetUserData('Is_POS') == 0){ // SuperAdmin
					this.router.navigateByUrl('/dashboard');
				}else if(this.api.GetUserData('UserType') == 0 && this.api.GetUserData('Is_POS') == 0){ // Employee //Agent
					this.router.navigateByUrl('/sr/dashboard');  
				}else if(this.api.GetUserData('UserType') == 0 && this.api.GetUserData('Is_POS') == 1){ //Agent
					this.router.navigateByUrl('/sr/dashboard');  
				}
			  }
			  
				 
			}else{
				this.api.ErrorMsg(result['Message']);
			}
				
			
		}, (err) => { 
		  // Error log
		  //console.log(err.message);
		  this.api.HideLoading();
		  //alert(err.message);
		  this.api.ErrorMsg('Network Error :- ' + err.message);
	    });
	}
    
     
  }
  
  public loadScript() {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
            isFound = true;
        }
    }

    if (!isFound) {
        var dynamicScripts = ["assets/plugins/global/plugins.bundle.js",
						"assets/plugins/custom/prismjs/prismjs.bundle.js",
						"assets/js/scripts.bundle.js",
						"assets/dt/jquery/dist/jquery.js",
						"assets/dt/datatables.net/js/jquery.dataTables.js"
					];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('body')[0].appendChild(node);
        }

    } 
}

  
  
  
   
}
