import { Component, OnInit } from '@angular/core';
import { Router } from  '@angular/router';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
Menus=[];
AdRm:string;
selectedFiles: File;

PhotoUrl:string = '';
Email:string = '';

currentRoute:string='0';

  constructor(public api : ApiService,
			private router: Router) { 
			
	if(this.api.GetUserData('User_Id_Dec') == 1){
		this.currentRoute = '0';
	}else{
		this.router.events.subscribe(value => {
		//console.log('current route: ', router.url.toString());
		if(router.url.toString() == '/sr/finance-sr' 
			|| router.url.toString() == '/sr/finance-sr-reports'
			 ){
			this.currentRoute = '1';
		}else{
			this.currentRoute = '0';
		} 
	 }); 
	}
	
	 console.log(this.currentRoute);
	  
	}

  ngOnInit(
		) {
	this.AdRm = 'Inactive';
	this.GetMenus();
	this.PhotoUrl = this.api.GetUserData('Photo');
	this.Email = this.api.GetUserData('Email');
	console.log('Welcome : '+ this.api.GetUserData('Name') );
  }
  
  GetMenus(){
		
		////const formData = new FormData();
		
		//formData.append('Type',this.api.GetUserData('Type'));
		//formData.append('User_Id',this.api.GetUserData('User_Id'));
		var Type = this.api.GetUserData('Type');
		var User_Id = this.api.GetUserData('User_Id');
		
		this.api.IsLoading(); 
		this.api.Call('Dashboard/Menu?Type='+Type+'&User_Id='+User_Id).then((result) => {
		this.api.HideLoading();	
			if(result['status'] == true){
				this.Menus = result['Data'];
				localStorage.setItem('IsCutCopyPaste',result['CutCopyPaste']);//
				//console.log(this.Menus);
			}else{
				this.api.ErrorMsg(result['message']); 
			}
			
		}, (err) => { 
		  // Error log
		  //console.log(err.message);
		  //alert('Data Loading Failed... Please try again and reload current page !');
		  this.api.HideLoading();
		   swal("Error!", "Data Loading Failed... Please try again and reload current page !", "warning");
		   //localStorage.removeItem('User');
		  
	    });  
  }
  MainMenu(index){
	 //console.log(this.Menus[index]);
	  //console.log(this.Menus[index]['IsShow']);
	  this.Menus[index]['IsShow'] = 1;
	  //console.log(this.Menus); 
  }
   
  OpenPage(index,page){
	//menu-item-hover 
	  //console.log(this.Menus[index]);
	  //console.log(this.Menus[index]['IsShow']);
	  this.Menus[index]['IsShow'] = 0;
	  //console.log(this.Menus);
	  
	//this.api.IsLoading();
	//setTimeout(() => {
      /** spinner ends after 5 seconds */
      //this.api.HideLoading();
    //}, 1000);	
	
	this.router.navigate([page]); 
  }
  
  Logout(){
	//alert(environment.apiUrl); 
	 
	//window.location.reload();
	this.api.IsLoading(); 
	setTimeout(() => {
      /* spinner ends after 5 seconds */ 
	   if(this.api.GetUserData('Is_UAT') == 1){
	    localStorage.removeItem('User');
		window.location.reload(); 
	  }
	  localStorage.removeItem('User');
	  this.api.changeMessage({IsLogged :"FALSE"});
      this.api.HideLoading();
	   
	  this.router.navigate(['/login']);
    }, 1000);	
	 
  }
  
  
  
  ///////////////////////////////////////
  //Update Profile Photo
  
  UploadProfileAvatar(event) 
	{
		this.selectedFiles = event.target.files[0];
		
		if(event.target.files && event.target.files[0])
		{
		
			console.log(this.selectedFiles);
			console.log(this.selectedFiles.name); 
			var str = this.selectedFiles.name;
			var ar =  str.split(".");
			console.log(ar); 
			var ext;
			for(var i=0; i<ar.length; i++) ext=ar[i].toLowerCase();
			//console.log(ext); 
			 
			if(ext == 'png' || ext == 'jpeg' || ext == 'jpg'){
				console.log('Extenstion is vaild !');
				var file_size = event.target.files[0]['size'];
				const Total_Size = Math.round((file_size / 1024)); 
				
				console.log(Total_Size+ ' kb'); 
				 
				if(Total_Size >= 1024*2) { // allow only 2 mb
					this.api.ErrorMsg('File size is greater than 2 mb');

				}else{
					 
					this.Upload();
				}
			}else{
				console.log('Extenstion is not vaild !');
				 
				this.api.ErrorMsg('Please choose vaild file ! Example :- PNG,JPEG,JPG');

					
			}
		
		}
	}
	
  async Upload(){
	 /* 
	  const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to upload profile picture?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm){
		*/
	
			const formData = new FormData(); 
			
			formData.append('User_Id',this.api.GetUserId()); 
			formData.append('Photo',this.selectedFiles); 
			 
		    
			this.api.IsLoading();
			this.api.HttpPostType('profile/UserProfile/UploadProfilePhoto',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){ 
					this.api.ToastMessage(result['Message']); 
				    //this.Documents = result['Documents'];
					this.PhotoUrl = result['PhotoUrl'];
					var User = JSON.parse( localStorage.getItem('User') );
					//console.log(User);
					User['Photo'] = result['PhotoUrl'];
					//console.log(User);
					localStorage.setItem("User", JSON.stringify(User));  //put the object back

				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			}); 
	//}
  }


}
