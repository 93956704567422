import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { FinanceSrDisbursementComponent } from '../../modals/finance-sr-disbursement/finance-sr-disbursement.component';
import swal from 'sweetalert';

@Component({
  selector: 'app-finance-sr-view',
  templateUrl: './finance-sr-view.component.html',
  styleUrls: ['./finance-sr-view.component.css']
})
export class FinanceSrViewComponent implements OnInit {



Id:any;
Agent_Id:any;
BaseUrl:any;
row:any=[];
Documents:any=[];

selectedFiles: File;
AadharCardFront:File;
AadharCardBack:File;
Photo:File;
Signature:File;
Pan_Card:File;
Salary_Slip:File;
Bank_Statement:File;
ITR:File;
Others:File;

Salutation_Type:any;
 
  constructor(
	public dialogRef: MatDialogRef<FinanceSrViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
	public dialog: MatDialog 
  ) { }

  ngOnInit() {
	this.Id = this.data.Id;
	this.GetSRData();   
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
  GetSRData(){
	//this.api.IsLoading();
	this.api.Call('finance/FinanceSR/ViewSRById?Id='+this.Id).then((result) => {
	//this.api.HideLoading();
	
		if(result['Status'] == true){
			//this.CloseModel();
			
			this.row = result['Data'];
			this.Documents = result['Documents'];
			this.BaseUrl = result['BaseUrl'];

			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  
  ViewDocument(name){
	  let url = this.BaseUrl+'/'+this.row.Id+'/'+name;
	  //alert(url);
	  window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
   
  
  viewDisburse(row): void{
		console.log(row); 
		const dialogRef = this.dialog.open(FinanceSrDisbursementComponent, {
		  width: '95%',
		  height:'90%',
		  disableClose : true,
		  data: { SR_No : this.row.SR_No, rowDetails : row}
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log(result);
		});
		
	}

	promptfn(m,a){
		var msg = prompt(m, a); 
		  if (msg == null) {
		  return '';
		}else if (msg == "") {
		  return this.promptfn(m,a);
		}else if (isNaN(parseInt(msg))) {
		  return this.promptfn("Please Enter valid value",a);
		}else{
		  return msg;
		}	
	  }

	EditSantionAmount(id:any,columnValue:any,ColumnType:any){
		//alert(id+'/'+columnValue+'/'+ColumnType);
		var amt =  this.promptfn("Please Enter "+ColumnType,columnValue);
      	console.log(amt);	 
      
       if(amt != ''){
		const formData = new FormData(); 
			
		formData.append('User_Id',this.api.GetUserId());
		formData.append('SR_Id',this.Id);
		formData.append('id',id); 
		formData.append('columnOldValue',columnValue); 
		formData.append('columnValue',amt); 
		formData.append('ColumnType',ColumnType); 
		 
		this.api.IsLoading();
		this.api.HttpPostType('finance/FinanceSR/UpdateSanctionColumnAndDisburseAmount',formData).then((result) => {
		this.api.HideLoading(); 
			if(result['Status'] == true){ 
				this.api.ToastMessage(result['Message']); 
				this.GetSRData();  
			}else{
				//alert(result['Message']);
				this.api.ErrorMsg(result['Message']); 
			}
		}, (err) => { 
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg(err.message);
		});
	   }

	}
	
	
	UploadDocs(event,Type) 
	{
		this.selectedFiles = event.target.files[0];
		
		if(event.target.files && event.target.files[0])
		{
		
			console.log(this.selectedFiles);
			console.log(this.selectedFiles.name); 
			var str = this.selectedFiles.name;
			var ar =  str.split(".");
			console.log(ar); 
			var ext;
			for(var i=0; i<ar.length; i++) ext=ar[i].toLowerCase();
			console.log(ext); 
			 
			if(ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'pdf'){
				console.log('Extenstion is vaild !');
				var file_size = event.target.files[0]['size'];
				const Total_Size = Math.round((file_size / 1024)); 
				
				console.log(Total_Size+ ' kb'); 
				 
				if(Total_Size >= 1024*3) { // allow only 3 mb
					this.api.ErrorMsg('File size is greater than 3 mb');
 
				}else{
					
					 
					this.Upload(Type);
				}
			}else{
				console.log('Extenstion is not vaild !');
				 
				this.api.ErrorMsg('Please choose vaild file ! Example :- PNG,JPEG,JPG,PDF');

					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }
	 
			}
		
		}
	}
	
  async Upload(Type){
	  const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to upload document?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm){
	
			const formData = new FormData(); 
			
			formData.append('User_Id',this.api.GetUserId());
			formData.append('SR_Id',this.Id);
			formData.append('Document_Type',Type); 
			formData.append('Document',this.selectedFiles); 
			 
		    
			this.api.IsLoading();
			this.api.HttpPostType('finance/FinanceSR/Document_Update',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){ 
					this.api.ToastMessage(result['Message']); 
				    this.Documents = result['Documents'];
				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			}); 
		}
  }
  

}
