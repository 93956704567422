import { Component, OnInit } from '@angular/core';
import { Router } from  '@angular/router';
import { PaymentlinkComponent } from '../../modals/paymentlink/paymentlink.component';
import { GemseventComponent } from '../../modals/gemsevent/gemsevent.component';
import { ApiService } from '../../providers/api.service';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
currentRoute:string='0';

  constructor(private router: Router,public api : ApiService,
 public dialog: MatDialog) { 
	
	if(this.api.GetUserData('User_Id_Dec') == 1){
		this.currentRoute = '0';
	}else{
		this.router.events.subscribe(value => {
		//console.log('current route: ', router.url.toString());
		if(router.url.toString() == '/sr/finance-sr' 
			|| router.url.toString() == '/sr/finance-sr-reports'
			 ){
			this.currentRoute = '1';
		}else{
			this.currentRoute = '0';
		} 
	 }); 
	} 
	 console.log(this.currentRoute);
  
  }

  ngOnInit() {
  }
  
  PaymentLinkModel(): void {
	  
		const dialogRef = this.dialog.open(PaymentlinkComponent, {
		  width: '35%',
		  height:'35%',
		  data: {Id : 0},
		  
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log(result);
		});
  }
  
  GemsEventModel(): void {
	  
		const dialogRef = this.dialog.open(GemseventComponent, {
		  width: '70%',
		  height:'70%',
		  data: {Id : 0}
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log(result);
		});
  }
  
  

}
