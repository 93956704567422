import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brokrage-request-view',
  templateUrl: './brokrage-request-view.component.html',
  styleUrls: ['./brokrage-request-view.component.css']
})
export class BrokrageRequestViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
