import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { FormBuilder, FormGroup,FormControl,FormArray, Validators } from  '@angular/forms';
import { Router } from  '@angular/router';
 
@Component({
  selector: 'app-finance-open-leads-action',
  templateUrl: './finance-open-leads-action.component.html',
  styleUrls: ['./finance-open-leads-action.component.css']
})
export class FinanceOpenLeadsActionComponent implements OnInit {

AddForm:FormGroup;
isSubmitted  = false;
Lead_Id:any=0;

DataAr:any = [];
Details_data:any = [];
BaseUrl:any = '';

maxDate:any = new Date();
minDate:any = new Date();

 constructor(
	public dialogRef: MatDialogRef<FinanceOpenLeadsActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
	private fb: FormBuilder,
	private router: Router,
  ) {
	
	 const currentYear = new Date().getFullYear();
	 this.minDate.setDate(this.maxDate.getDate());  
     this.maxDate.setDate(new Date(currentYear - 1, 0, 1));
	//this.minDate.setDate(this.minDate.setFullYear(2001));
    //this.maxDate.setDate(this.maxDate.getDate()-this.maxDate.getDate());	
  
	this.AddForm = this.fb.group({
		Lead_Status: [''], 
		Lead_Date: [''], 
		Lead_Time: [''], 
		Remark: [''], 
	}); 
	
 }

  ngOnInit() {
	  this.Lead_Id = this.data.Id;
	  this.GetLeadFollowUps();
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
   get FC() { return this.AddForm.controls; }
   
   GetLeadFollowUps(){
	
	this.api.IsLoading();
	this.api.Call('finance/FinanceLead/ViewLeadFollowUps?Lead_Id='+this.Lead_Id).then((result) => {
	this.api.HideLoading();
	
		//if(result['Status'] == true){
			 
			this.DataAr = result['Data'];
			this.Details_data = result['Details_data'];
			this.BaseUrl = result['BaseUrl'];
			 
			//alert(this.Business_Type);
			 
			//this.api.ToastMessage(result['Message']); 
		//}else{
			//this.api.ErrorMsg(result['Message']);
		//}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  
   
   Add_FollowUp(){
	
    console.log(this.AddForm.value);
	 
    this.isSubmitted = true;
    if(this.AddForm.invalid){
      return;
    }else{
		
		var fields = this.AddForm.value; 
		const formData = new FormData(); 
		 
		formData.append('User_Id',this.api.GetUserId());
		formData.append('Lead_Id',this.Lead_Id); 
		 
		formData.append('Lead_Status',fields['Lead_Status']);
		formData.append('Lead_Date',fields['Lead_Date']);
		formData.append('Lead_Time',fields['Lead_Time']);
		formData.append('Remark',fields['Remark']);
		  
		this.api.IsLoading();
		this.api.HttpPostType('finance/FinanceRequestSR/Add_FollowUp',formData).then((result) => {
		this.api.HideLoading();
			
			 
			if(result['Status'] == true){
				this.AddForm.reset();
				this.GetLeadFollowUps();
				
				var SR_Id = result['SR_Id'];
				if(SR_Id != 0){
					this.CloseModel();  
					this.router.navigate(['/sr/finance-sr-edit/'+SR_Id]);
				}else{
					this.api.ToastMessage(result['Message']);
				}
				
				 
			}else{
				this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    });
		 
	}

  }
  
  ViewDocument(name){
	 var url = this.BaseUrl+name;
	 window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
  

}
