import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';
@Component({
  selector: 'app-partner-earning-view',
  templateUrl: './partner-earning-view.component.html',
  styleUrls: ['./partner-earning-view.component.css']
})
export class PartnerEarningViewComponent implements OnInit {

Agent_Id:any;
DataAr:any=[];

isSelected: any;
isAllSelected:any;
masterSelected:any;
checkUncheckAll:any;
 
  constructor(
	public dialogRef: MatDialogRef<PartnerEarningViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
  ) { }

  ngOnInit() {
	this.Agent_Id = this.data.Id;
	this.Get();
	 
  }
  
  CloseModel(): void { 
    this.dialogRef.close({ 
      Status: 'Model Close'
    });
  }
   
  Get(){
	this.api.IsLoading(); 
	this.api.Call('brokerage/PartnerEarning/ViewAgentEarnings?Agent_Id='+this.Agent_Id+'&User_Id='+this.api.GetUserId()).then((result) => {
	this.api.HideLoading();
	  
		if(result['Status'] == true){
			 
			this.DataAr = result['Data']; 
			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});  
  } 

  ExportPostingReport(id:any){

  }

}


