import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';


@Component({
  selector: 'app-cross-selling-remark',
  templateUrl: './cross-selling-remark.component.html',
  styleUrls: ['./cross-selling-remark.component.css']
})
export class CrossSellingRemarkComponent implements OnInit {

 
Id:any;
Agent_Id:any;
Agent_Name:any;
Agent_Code:any;
ReportType:any;
Is_Remark_Window:any;
 
DataAr:any=[];

AddForm: FormGroup;
isSubmitted  = false;
 
 
  constructor(
	public dialogRef: MatDialogRef<CrossSellingRemarkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,	private formBuilder: FormBuilder
  ) { 
	 this.AddForm  =  this.formBuilder.group({
		Remark: [''],  
		Is_FollowUpStatus: ['FollowUp'],
		Is_Cross_Sell_Type: ['', [Validators.required]],  
		FollowUp_Required: ['', [Validators.required]],  
		FollowUp_Remark: [''],  
		FollowUpDate: [''],  
		FollowUp_Time: [''],  
		//FollowUpDate: [''],  
	 });
  
  } 

  ngOnInit() {
	this.Id = this.data.Id;
	this.Agent_Name = this.data.Agent_Name;
	this.Agent_Code = this.data.Agent_Code;
	this.ReportType = this.data.ReportType;
	this.Get();   
  }
  	get formControls() { return this.AddForm.controls; }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  } 
  
  
  Is_FollowUpStatus(e){
	   
	   var type = e.target.value;
	  
	   if(type == 'FollowUp'){
		  this.AddForm.get('Is_Cross_Sell_Type').setValidators([Validators.required]);
		  this.AddForm.get('FollowUpDate').setValidators([Validators.required]);
		  this.AddForm.get('FollowUp_Time').setValidators([Validators.required]);
	   }else{
		  this.AddForm.get('Is_Cross_Sell_Type').setValidators(null);
		  this.AddForm.get('FollowUpDate').setValidators(null);
		  this.AddForm.get('FollowUp_Time').setValidators(null);
	   }
   
		this.AddForm.get('Is_Cross_Sell_Type').updateValueAndValidity();
		this.AddForm.get('FollowUpDate').updateValueAndValidity();
		this.AddForm.get('FollowUp_Time').updateValueAndValidity();
  
  
  }
  
  Get(){
	//this.api.IsLoading();
	this.api.Call('reports/CrossSelling/RemarkListByAgentId?ReportType='+this.ReportType+'&Agent_Id='+this.Id+'&User_Id='+this.api.GetUserId()).then((result) => {
	//this.api.HideLoading();
	
		if(result['Status'] == true){
			this.DataAr = result['DataAr'];
			this.Is_Remark_Window = result['Is_Remark_Window']; 
			//this.api.ToastMessage(result['Message']); 
		}else{
			//this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  
  
  AddRemark(){
		
		this.isSubmitted = true;
		if(this.AddForm.invalid){
		  return;
		}else{
			
			 
			const formData = new FormData(); 
			 
			formData.append('User_Id',this.api.GetUserId());
			formData.append('Agent_Id',this.Id); 
			formData.append('Is_FollowUpStatus',this.AddForm.value['Is_FollowUpStatus']); 
			formData.append('ReportType',this.ReportType); 
			formData.append('Remark',this.AddForm.value['Remark']);
			formData.append('Is_Cross_Sell_Type',this.AddForm.value['Is_Cross_Sell_Type']);
			   
			formData.append('FollowUp_Required',this.AddForm.value['FollowUp_Required']);
			formData.append('FollowUp_Remark',this.AddForm.value['FollowUp_Remark']);
			
			formData.append('FollowUpDate',this.AddForm.value['FollowUpDate']);
			formData.append('FollowUp_Time',this.AddForm.value['FollowUp_Time']);
			 
			this.api.IsLoading();
			this.api.HttpPostType('reports/CrossSelling/AddRemark',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){ 
					this.api.ToastMessage(result['Message']); 
					this.Get();
					this.AddForm.reset();
					
					this.AddForm.get('Is_FollowUpStatus').setValue('FollowUp');
					this.AddForm.get('Is_FollowUpStatus').updateValueAndValidity();
					
					this.isSubmitted = false;
				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			});
				 
		

  
		}
    }

}
