import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';

import { FormBuilder, FormGroup,FormControl,FormArray, Validators } from  '@angular/forms';

@Component({
  selector: 'app-finance-sr-disbursement',
  templateUrl: './finance-sr-disbursement.component.html',
  styleUrls: ['./finance-sr-disbursement.component.css']
})
export class FinanceSrDisbursementComponent implements OnInit {

rowDetails:any;
SR_No:any;
Agent_Id:any;
BaseUrl:any;
row:any=[];
DataAr:any=[];

sr_row:any=[];

AddForm:FormGroup;
AddBrokrage:FormGroup;
isSubmitted  = false;
isSubmitted_b  = false;

Months:any = [];

Business_Type:string='';
Telecaller_Id:string='';

Disbursed_Id:any='';
Disbursed_No:any='';

PayIn_Out_Status:any='';

ReadOnlyStatus:boolean=true;
	Brokrage_Id: any=0;
	User_Rights: any;

  constructor(
	public dialogRef: MatDialogRef<FinanceSrDisbursementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
	private fb: FormBuilder
  ) {

	this.AddForm = this.fb.group({
		Loan_Account_No: ['',[Validators.required]],
		Disbursed_Amount: ['',[Validators.required]],
		Disbursed_Month: ['',[Validators.required]],

		PayIn: ['',[Validators.required]],
		PayIn_Amount: ['',[Validators.required]],
		PayIn_Other_Reward: ['',[Validators.required]],
		Deduction_Amount: ['',[Validators.required]],
		Total_PayIn: ['',[Validators.required]],

		Agent_PayOut: ['',[Validators.required]],
		Agent_PayOut_Amount: ['',[Validators.required]],

		Reward_For_Telecaller: [''],
		Reward_For_Telecaller_Amount: [''],

		Revenue_Core_Company: [''],

		Revenue_Xsale_Sharing: [''],
		Revenue_Xsale_Sharing_Amount: [''],

		IncentiveCaptiveSale: [''],
		IncentiveCaptiveSale_Amount: [''],

		//IncentiveCrossSale: [''],
		//IncentiveCrossSale_Amount: [''],

		Net_Revenue_Core_Company: [''],
		Net_Revenue_Sharing: [''],
	});

	this.AddBrokrage = this.fb.group({
		PayIn: ['0'],
		PayIn_Amount: ['0'],
		PayIn_Other_Reward: ['0'],
		Deduction_Amount: ['0'],
		Total_PayIn: ['0'],

		Agent_PayOut: ['0'],
		Agent_PayOut_Amount: ['0'],

		Reward_For_Telecaller: ['0'],
		Reward_For_Telecaller_Amount: ['0'],

		Revenue_Core_Company: ['0'],

		Revenue_Xsale_Sharing: ['0'],
		Revenue_Xsale_Sharing_Amount: ['0'],

		IncentiveCaptiveSale: ['0'],
		IncentiveCaptiveSale_Amount: ['0'],

		//IncentiveCrossSale: [''],
		//IncentiveCrossSale_Amount: [''],

		Net_Revenue_Core_Company: ['0'],
		Net_Revenue_Sharing: ['0'],
	});

	this.Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


  }

  ngOnInit() {
	this.SR_No = this.data.SR_No;
	this.rowDetails = this.data.rowDetails;
	console.log(this.rowDetails);
	this.GetSR();
	this.GetDisbursements();
  }

  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }

  get FC() { return this.AddForm.controls; }
  get FC_b() { return this.AddBrokrage.controls; }

  GetSR(){


		this.api.IsLoading();
		this.api.Call('finance/FinanceSR/ViewSRById?Id='+this.rowDetails.SR_Id).then((result) => {
		this.api.HideLoading();
			 console.log(result['Data']);
			if(result['Status'] == true){

				this.sr_row = result['Data'];

				this.Business_Type = result['Data']['Business_Type'];
				this.Telecaller_Id = result['Data']['Telecaller_Id'];
				//this.api.ToastMessage(result['Message']);

				if(this.Telecaller_Id == '0'){
					this.AddForm.get('Reward_For_Telecaller').disable();
					this.AddForm.get('Reward_For_Telecaller_Amount').disable();
				 }else{
					 this.AddForm.get('Reward_For_Telecaller').enable();
					 this.AddForm.get('Reward_For_Telecaller_Amount').enable();
				 }

			}else{
				this.api.ErrorMsg(result['Message']);
			}

		}, (err) => {
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    });
  }

  Add_Disburse(){

    console.log(this.AddForm.value);

    this.isSubmitted = true;
    if(this.AddForm.invalid){
      return;
    }else{

		var fields = this.AddForm.value;
		const formData = new FormData();

		formData.append('User_Id',this.api.GetUserId());
		formData.append('SR_No',this.SR_No);
		formData.append('SR_Id',this.rowDetails.SR_Id);
		formData.append('Login_No',this.rowDetails.Login_No);
		formData.append('Sanction_No',this.rowDetails.Sanction_No);
		formData.append('Sanction_Month',this.rowDetails.Sanction_Month);
		formData.append('Sanction_Amount',this.rowDetails.Sanction_Amount);
		formData.append('Sanction_Year',this.rowDetails.Sanction_Year);

		formData.append('Loan_Account_No',fields['Loan_Account_No']);
		formData.append('Disbursed_Amount',fields['Disbursed_Amount']);
		formData.append('Disbursed_Month',fields['Disbursed_Month']);

		formData.append('Business_Type',this.Business_Type);
		formData.append('Telecaller_Id',this.Telecaller_Id);

		formData.append('PayIn',fields['PayIn']);
		formData.append('PayIn_Amount',fields['PayIn_Amount']);
		formData.append('PayIn_Other_Reward',fields['PayIn_Other_Reward']);
		formData.append('Deduction_Amount',fields['Deduction_Amount']);
		formData.append('Total_PayIn',fields['Total_PayIn']);

		formData.append('Agent_PayOut',fields['Agent_PayOut']);
		formData.append('Agent_PayOut_Amount',fields['Agent_PayOut_Amount']);
		formData.append('Reward_For_Telecaller',fields['Reward_For_Telecaller']);
		formData.append('Reward_For_Telecaller_Amount',fields['Reward_For_Telecaller_Amount']);

		formData.append('Revenue_Core_Company',fields['Revenue_Core_Company']);
		formData.append('Revenue_Xsale_Sharing',fields['Revenue_Xsale_Sharing']);
		formData.append('Revenue_Xsale_Sharing_Amount',fields['Revenue_Xsale_Sharing_Amount']);

		formData.append('IncentiveCaptiveSale',fields['IncentiveCaptiveSale']);
		formData.append('IncentiveCaptiveSale_Amount',fields['IncentiveCaptiveSale_Amount']);

		formData.append('Net_Revenue_Core_Company',fields['Net_Revenue_Core_Company']);

		this.api.IsLoading();
		this.api.HttpPostType('finance/FinanceSR/Add_Disburse',formData).then((result) => {
		this.api.HideLoading();


			if(result['Status'] == true){
				this.AddForm.reset();
				this.DataAr = result['Data'];
				this.Business_Type = result['SR_Data']['Business_Type'];
				this.Telecaller_Id = result['SR_Data']['Telecaller_Id'];

				this.api.ToastMessage(result['Message']);

			}else{
				this.api.ErrorMsg(result['Message']);
			}

		}, (err) => {
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    });

	}


  }



  GetDisbursements(){
	//this.api.IsLoading();
	this.api.Call('finance/FinanceSR/ViewDisbursements?User_Id='+this.api.GetUserId()+'&SR_Id='+this.rowDetails.SR_Id+'&Sanction_No='+this.rowDetails.Sanction_No).then((result) => {
	//this.api.HideLoading();

		//if(result['Status'] == true){

			this.DataAr = result['Data'];

			this.User_Rights = result['SR_User_Rights'];

			this.Business_Type = result['SR_Data']['Business_Type'];
			this.Telecaller_Id = result['SR_Data']['Telecaller_Id'];

			const Reward_For_Telecaller_Control = this.AddBrokrage.get('Reward_For_Telecaller');
			const Reward_For_Telecaller_Amount_Control = this.AddBrokrage.get('Reward_For_Telecaller_Amount');

			const Revenue_Xsale_Sharing_Control = this.AddBrokrage.get('Revenue_Xsale_Sharing');
			const Revenue_Xsale_Sharing_Amount_Control = this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount');
			const IncentiveCaptiveSale_Control = this.AddBrokrage.get('IncentiveCaptiveSale');
			const IncentiveCaptiveSale_Amount_Control = this.AddBrokrage.get('IncentiveCaptiveSale_Amount');


			if(this.Business_Type == 'Cross-Sale'){
				IncentiveCaptiveSale_Control.disable();
				IncentiveCaptiveSale_Amount_Control.disable();
			}else{
				Revenue_Xsale_Sharing_Control.disable();
				Revenue_Xsale_Sharing_Amount_Control.disable();
			}

			if(this.Telecaller_Id == '0'){
				Reward_For_Telecaller_Control.disable();
				Reward_For_Telecaller_Amount_Control.disable();
			}else{
				Revenue_Xsale_Sharing_Control.enable();
				Revenue_Xsale_Sharing_Amount_Control.enable();
			}
			//alert(this.Business_Type);

			//this.api.ToastMessage(result['Message']);
		//}else{
			//this.api.ErrorMsg(result['Message']);
		//}

	}, (err) => {
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }

  ShowBrokrageSection(Disbursed_Id,Disbursed_No,Type){
	  this.Disbursed_Id = Disbursed_Id;
	  this.Disbursed_No = Disbursed_No;
	  this.PayIn_Out_Status = Type;
	  //alert(this.Disbursed_No);
	  //console.log(this.DataAr.length);
	  //console.log(this.DataAr);
	//if(Type == 'Brokrage' || Type == 'Reconciliation'){
	  for(var i=0; i<this.DataAr.length; i++){

		if(this.DataAr[i]['Row_Status']=='Even'){
			var styleStatus='';
			if(this.DataAr[i]['Disbursed_No']==this.Disbursed_No){
				styleStatus='block';
			}else{
				styleStatus='none';
			}
			document.getElementById("BrokrageSection_"+this.DataAr[i]['Disbursed_No']).style.display = styleStatus;
		}
	  }
	//}
	this.ReadOnlyStatus=true;
	 this.AddBrokrage.reset();
	 if(Type == 'Reconciliation' || Type == 'ViewPayIn' || Type == 'ViewDisbursePayIn' ){


		const formData = new FormData();

		formData.append('User_Id',this.api.GetUserId());
		formData.append('SR_No',this.SR_No);
		formData.append('SR_Id',this.rowDetails.SR_Id);
		formData.append('Disbursed_Id',Disbursed_Id);
		formData.append('Disbursed_No',Disbursed_No);
		formData.append('Type',Type);


		this.api.IsLoading();
		this.api.HttpPostType('finance/FinanceSR/ViewBrokrageDetails',formData).then((result) => {
		this.api.HideLoading();


			if(result['Status'] == true){
				//this.DataAr = result['Data'];
				var row = result['Data'];

				this.User_Rights = result['SR_User_Rights'];

				 this.AddBrokrage.get('PayIn').setValue(parseFloat(row.PayIn));
				 this.AddBrokrage.get('PayIn_Amount').setValue(parseFloat(row.PayIn_Amount));
				 this.AddBrokrage.get('PayIn_Other_Reward').setValue(parseFloat(row.PayIn_Other_Reward));
				 this.AddBrokrage.get('Deduction_Amount').setValue(parseFloat(row.Deduction_Amount));
				 this.AddBrokrage.get('Total_PayIn').setValue(parseFloat(row.Total_PayIn));
				 this.AddBrokrage.get('Agent_PayOut').setValue(parseFloat(row.Agent_PayOut));
				 this.AddBrokrage.get('Agent_PayOut_Amount').setValue(parseFloat(row.Agent_PayOut_Amount));
				 this.AddBrokrage.get('Reward_For_Telecaller').setValue(parseFloat(row.Reward_For_Telecaller));
				 this.AddBrokrage.get('Reward_For_Telecaller_Amount').setValue(parseFloat(row.Reward_For_Telecaller_Amount));
				 this.AddBrokrage.get('Revenue_Core_Company').setValue(parseFloat(row.Revenue_Core_Company));
				 this.AddBrokrage.get('Revenue_Xsale_Sharing').setValue(parseFloat(row.Revenue_Xsale_Sharing));
				 this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount').setValue(parseFloat(row.Revenue_Xsale_Sharing_Amount));
				 this.AddBrokrage.get('IncentiveCaptiveSale').setValue(parseFloat(row.IncentiveCaptiveSale));
				 this.AddBrokrage.get('IncentiveCaptiveSale_Amount').setValue(parseFloat(row.IncentiveCaptiveSale_Amount));
				 this.AddBrokrage.get('Net_Revenue_Core_Company').setValue(parseFloat(row.Net_Revenue_Core_Company));


				 this.ReadOnlyStatus=false;


				 if(Type == 'ViewPayIn'){
					//alert('sdsd'+Type);
					this.Brokrage_Id=row.Id;	
					 this.ReadOnlyStatus=true;
					/*
					 this.AddBrokrage.get('PayIn').disable();
					 this.AddBrokrage.get('PayIn_Amount').disable();
					 this.AddBrokrage.get('PayIn_Other_Reward').disable();
					 this.AddBrokrage.get('Deduction_Amount').disable();
					 this.AddBrokrage.get('Total_PayIn').disable();
					 this.AddBrokrage.get('Agent_PayOut').disable();
					 this.AddBrokrage.get('Agent_PayOut_Amount').disable();
					 this.AddBrokrage.get('Reward_For_Telecaller').disable();
					 this.AddBrokrage.get('Reward_For_Telecaller_Amount').disable();
					 this.AddBrokrage.get('Revenue_Core_Company').disable();
					 this.AddBrokrage.get('Revenue_Xsale_Sharing').disable();
					 this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount').disable();
					 this.AddBrokrage.get('IncentiveCaptiveSale').disable();
					 this.AddBrokrage.get('IncentiveCaptiveSale_Amount').disable();
					 this.AddBrokrage.get('Net_Revenue_Core_Company').disable();
					 */

				 }else{

					 this.AddBrokrage.get('PayIn').enable();
					 this.AddBrokrage.get('PayIn_Amount').enable();
					 this.AddBrokrage.get('PayIn_Other_Reward').enable();
					 this.AddBrokrage.get('Deduction_Amount').enable();
					 this.AddBrokrage.get('Total_PayIn').enable();
					 this.AddBrokrage.get('Agent_PayOut').enable();
					 this.AddBrokrage.get('Agent_PayOut_Amount').enable();

					 if(this.Telecaller_Id == '0'){
						this.AddBrokrage.get('Reward_For_Telecaller').disable();
						this.AddBrokrage.get('Reward_For_Telecaller_Amount').disable();

					 }else{
						 this.AddBrokrage.get('Reward_For_Telecaller').enable();
						 this.AddBrokrage.get('Reward_For_Telecaller_Amount').enable();

					 }

					 this.AddBrokrage.get('Revenue_Core_Company').enable();

					 if(this.Business_Type == 'Cross-Sale'){
						this.AddBrokrage.get('Revenue_Xsale_Sharing').enable();
						this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount').enable();
						this.AddBrokrage.get('IncentiveCaptiveSale').disable();
						this.AddBrokrage.get('IncentiveCaptiveSale_Amount').disable();
					 }else{
						this.AddBrokrage.get('Revenue_Xsale_Sharing').disable();
						this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount').disable();
						this.AddBrokrage.get('IncentiveCaptiveSale').enable();
						this.AddBrokrage.get('IncentiveCaptiveSale_Amount').enable();
					 }

					 this.AddBrokrage.get('Net_Revenue_Core_Company').enable();

					 this.ReadOnlyStatus=true;

				 }

				//this.api.ToastMessage(result['Message']);

			}else{
				this.api.ErrorMsg(result['Message']);
			}

		}, (err) => {
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    });
	 }else{
		 this.AddBrokrage.get('PayIn').enable();
		 this.AddBrokrage.get('PayIn_Amount').enable();
		 this.AddBrokrage.get('PayIn_Other_Reward').enable();
		 this.AddBrokrage.get('Deduction_Amount').enable();
		 this.AddBrokrage.get('Total_PayIn').enable();
		 this.AddBrokrage.get('Agent_PayOut').enable();
		 this.AddBrokrage.get('Agent_PayOut_Amount').enable();

		 if(this.Telecaller_Id == '0'){
			this.AddBrokrage.get('Reward_For_Telecaller').disable();
			this.AddBrokrage.get('Reward_For_Telecaller_Amount').disable();
		 }else{
			 this.AddBrokrage.get('Reward_For_Telecaller').enable();
			 this.AddBrokrage.get('Reward_For_Telecaller_Amount').enable();
		 }

		 this.AddBrokrage.get('Revenue_Core_Company').enable();

		 if(this.Business_Type == 'Cross-Sale'){
			this.AddBrokrage.get('Revenue_Xsale_Sharing').enable();
			this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount').enable();
			this.AddBrokrage.get('IncentiveCaptiveSale').disable();
			this.AddBrokrage.get('IncentiveCaptiveSale_Amount').disable();
		 }else{
			this.AddBrokrage.get('Revenue_Xsale_Sharing').disable();
			this.AddBrokrage.get('Revenue_Xsale_Sharing_Amount').disable();
			this.AddBrokrage.get('IncentiveCaptiveSale').enable();
			this.AddBrokrage.get('IncentiveCaptiveSale_Amount').enable();
		 }

		 this.AddBrokrage.get('Net_Revenue_Core_Company').enable();

		 this.ReadOnlyStatus=true;

	 }

	 //alert(Type);

	 if(this.sr_row.File_Status == 'Verified'){
		//this.ReadOnlyStatus=false;
	 }
	 //alert(this.sr_row.File_Status,this.ReadOnlyStatus);

  }



  CalculatePayIn_old(e,Type,flag,Disbursed_Amount){

	   var f = this.AddBrokrage;
	   //var Disbursed_Amount = f.value['Disbursed_Amount'];
	   var inputValue;
	   if(flag == 1){
		 inputValue = e.target.value;
	   }else{
		 inputValue = e;
		 //console.log(Disbursed_Amount);
	   }

	    console.log(Disbursed_Amount);

	   console.log(inputValue);

	   //if(Type == 'PayIn'){
		   f.get('PayIn_Amount').setValue((Disbursed_Amount/100*inputValue));
		   this.Update_Total_PayIn(f);
	   //}else if(Type == 'PayIn_Other_Reward'){
		 //this.Update_Total_PayIn(f);
	   //}else if(Type == 'Deduction_Amount'){
		 //this.Update_Total_PayIn(f);
	   //}else if(Type == 'Agent_PayOut'){
		 f.get('Agent_PayOut_Amount').setValue((Disbursed_Amount/100*inputValue));
		 //this.Update_Total_Revenue_Core_Company(f);
	   //}else if(Type == 'Reward_For_Telecaller'){
		 f.get('Reward_For_Telecaller_Amount').setValue((Disbursed_Amount/100*inputValue));
		 this.Update_Total_Revenue_Core_Company(f);
	   //}else if(Type == 'Revenue_Xsale_Sharing'){
		 f.get('Revenue_Xsale_Sharing_Amount').setValue((f.value['Revenue_Core_Company']/100*inputValue));
		 //this.Update_Total_Net_Revenue_Core_Company(f);
	   //}else if(Type == 'IncentiveCaptiveSale'){
		 //f.get('IncentiveCaptiveSale_Amount').setValue((Disbursed_Amount/100*inputValue));
		 //this.Update_Total_Net_Revenue_Core_Company(f);
	   //}else if(Type == 'IncentiveCrossSale'){
		 //f.get('IncentiveCrossSale_Amount').setValue((Disbursed_Amount/100*inputValue));
		 this.Update_Total_Net_Revenue_Core_Company(f);
	   //}else if(Type == 'Net_Revenue_Sharing'){
		 //this.Update_Final_Revenue(f);
	   //}

   }

   CalculatePayIn(e,Type,flag,Disbursed_Amount,FormType){
	   var f;
	   if(FormType == 'Brokrage'){
			f = this.AddBrokrage;
	   }else{
			f = this.AddForm;
	   }

	  console.log(f);
	  console.log(FormType);
	   //var Disbursed_Amount = f.value['Disbursed_Amount'];
	   var inputValue;
	   if(flag == 1){
		 inputValue = e.target.value;
	   }else{
		 inputValue = e;
		 //console.log(Disbursed_Amount);
	   }

	    console.log(Disbursed_Amount);

	   console.log(inputValue);

	   if(Type == 'PayIn'){
		   f.get('PayIn_Amount').setValue((Disbursed_Amount/100*inputValue));

		   //f.get('Revenue_Xsale_Sharing_Amount').setValue((f.value['Revenue_Core_Company']/100*f.value['Revenue_Xsale_Sharing']));

		   this.Update_Total_PayIn(f);
	   }else if(Type == 'PayIn_Other_Reward'){
		 this.Update_Total_PayIn(f);
	   }else if(Type == 'Deduction_Amount'){
		 this.Update_Total_PayIn(f);
	   }else if(Type == 'Agent_PayOut'){
		 f.get('Agent_PayOut_Amount').setValue((Disbursed_Amount/100*inputValue));
		 this.Update_Total_Revenue_Core_Company(f);
	   }else if(Type == 'Reward_For_Telecaller'){
		 f.get('Reward_For_Telecaller_Amount').setValue((Disbursed_Amount/100*inputValue));
		 this.Update_Total_Revenue_Core_Company(f);
	   }else if(Type == 'Revenue_Xsale_Sharing'){

		 f.get('Revenue_Xsale_Sharing_Amount').setValue((f.value['Revenue_Core_Company']/100*inputValue));
		 this.Update_Total_Net_Revenue_Core_Company(f);
	   }else if(Type == 'IncentiveCaptiveSale'){
		 f.get('IncentiveCaptiveSale_Amount').setValue((Disbursed_Amount/100*inputValue));
		 this.Update_Total_Net_Revenue_Core_Company(f);
	   }else if(Type == 'IncentiveCrossSale'){
		 f.get('IncentiveCrossSale_Amount').setValue((Disbursed_Amount/100*inputValue));
		 this.Update_Total_Net_Revenue_Core_Company(f);
	   }else if(Type == 'Net_Revenue_Sharing'){
		 //this.Update_Final_Revenue(f);
	   }

   }

   Update_Total_PayIn(f){
	   var Total_PayIn = ((parseInt(f.value['PayIn_Amount'])+parseInt(f.value['PayIn_Other_Reward']))-(parseInt(f.value['Deduction_Amount'])));
	  console.log('Deduction_Amount '+ typeof f.value['PayIn_Other_Reward']);
	  console.log('Deduction_Amount '+ typeof f.value['Deduction_Amount']);
	  console.log('Total_PayIn '+ Total_PayIn);

	  f.get('Total_PayIn').setValue(Total_PayIn);
	  this.Update_Total_Revenue_Core_Company(f);
	  this.Update_Total_Net_Revenue_Core_Company(f);
   }

   Update_Total_Revenue_Core_Company(f){
	   var RCC = 0;
	   if(this.Telecaller_Id == '0'){
			RCC = ((f.value['Total_PayIn'])-(f.value['Agent_PayOut_Amount']));
	   }else{
			RCC = ((f.value['Total_PayIn'])-(f.value['Agent_PayOut_Amount']+f.value['Reward_For_Telecaller_Amount']));
	   }
	  console.log(RCC);
	  f.get('Revenue_Core_Company').setValue(RCC);
   }

   Update_Total_Net_Revenue_Core_Company(f){
	 //alert('df');
	  if(this.Business_Type == 'Cross-Sale'){
		f.get('Net_Revenue_Core_Company').setValue((f.value['Revenue_Core_Company']-f.value['Revenue_Xsale_Sharing_Amount']));
		 //console.log('1' + this.Business_Type);
	  }else{
		//f.get('Net_Revenue_Core_Company').setValue((f.value['IncentiveCaptiveSale_Amount']-f.value['Revenue_Core_Company']));
		f.get('Net_Revenue_Core_Company').setValue((f.value['Revenue_Core_Company']-f.value['IncentiveCaptiveSale_Amount']));
		//console.log('2' + this.Business_Type);
	  }

	  //f.get('Net_Revenue_Core_Company').setValue(((f.value['Revenue_Xsale_Sharing_Amount']+f.value['IncentiveCaptiveSale_Amount']+f.value['IncentiveCrossSale_Amount'])-(f.value['Revenue_Core_Company'])));
	  //this.Update_Final_Revenue(f);
   }
   Update_Final_Revenue(f){
	  //f.get('Final_Revenue').setValue(((f.value['Net_Revenue_Core_Company']-f.value['Net_Revenue_Sharing'])));
   }


   Add_Brokrage(){

    console.log(this.AddBrokrage.value);

    this.isSubmitted_b = true;
    if(this.AddBrokrage.invalid){
      return;
    }else{

		var fields = this.AddBrokrage.value;
		const formData = new FormData();

		formData.append('User_Id',this.api.GetUserId());
		formData.append('SR_No',this.SR_No);
		formData.append('SR_Id',this.rowDetails.SR_Id);
		formData.append('Login_No',this.rowDetails.Login_No);
		formData.append('Sanction_No',this.rowDetails.Sanction_No);
		formData.append('Brokrage_Id',this.Brokrage_Id); 

		formData.append('PayIn_Out_Status',this.PayIn_Out_Status);

		formData.append('Disbursed_Id',this.Disbursed_Id);
		formData.append('Disbursed_No',this.Disbursed_No);

		formData.append('Business_Type',this.Business_Type);
		formData.append('Telecaller_Id',this.Telecaller_Id);

		formData.append('PayIn',fields['PayIn']);
		formData.append('PayIn_Amount',fields['PayIn_Amount']);
		formData.append('PayIn_Other_Reward',fields['PayIn_Other_Reward']);
		formData.append('Deduction_Amount',fields['Deduction_Amount']);
		formData.append('Total_PayIn',fields['Total_PayIn']);

		formData.append('Agent_PayOut',fields['Agent_PayOut']);
		formData.append('Agent_PayOut_Amount',fields['Agent_PayOut_Amount']);
		formData.append('Reward_For_Telecaller',fields['Reward_For_Telecaller']);
		formData.append('Reward_For_Telecaller_Amount',fields['Reward_For_Telecaller_Amount']);

		formData.append('Revenue_Core_Company',fields['Revenue_Core_Company']);
		formData.append('Revenue_Xsale_Sharing',fields['Revenue_Xsale_Sharing']);
		formData.append('Revenue_Xsale_Sharing_Amount',fields['Revenue_Xsale_Sharing_Amount']);

		formData.append('IncentiveCaptiveSale',fields['IncentiveCaptiveSale']);
		formData.append('IncentiveCaptiveSale_Amount',fields['IncentiveCaptiveSale_Amount']);

		formData.append('Net_Revenue_Core_Company',fields['Net_Revenue_Core_Company']);


		this.api.IsLoading();
		this.api.HttpPostType('finance/FinanceSR/AddBrokrage',formData).then((result) => {
		this.api.HideLoading();


			if(result['Status'] == true){
				//this.DataAr = result['Data'];
				this.AddBrokrage.reset();
				this.GetDisbursements();
				this.api.ToastMessage(result['Message']);

			}else{
				this.api.ErrorMsg(result['Message']);
			}

		}, (err) => {
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    });

	}


  }


  promptfn(m,a){
	var msg = prompt(m, a); 
	  if (msg == null) {
	  return '';
	}else if (msg == "") {
	  return this.promptfn(m,a);
	}else if (isNaN(parseInt(msg))) {
	  return this.promptfn("Please Enter valid value",a);
	}else{
	  return msg;
	}	
  }

EditSantionAmount(id:any,columnValue:any,ColumnType:any){
	//alert(id+'/'+columnValue+'/'+ColumnType);
	var amt =  this.promptfn("Please Enter "+ColumnType,columnValue);
	  console.log(amt);	 
  
   if(amt != ''){
	const formData = new FormData(); 
		
	formData.append('User_Id',this.api.GetUserId());
	formData.append('SR_Id',this.rowDetails.SR_Id);
	formData.append('id',id); 
	formData.append('columnOldValue',columnValue); 
	formData.append('columnValue',amt); 
	formData.append('ColumnType',ColumnType); 
	 
	this.api.IsLoading();
	this.api.HttpPostType('finance/FinanceSR/UpdateSanctionColumnAndDisburseAmount',formData).then((result) => {
	this.api.HideLoading(); 
		if(result['Status'] == true){ 
			this.api.ToastMessage(result['Message']); 
			this.GetDisbursements();
		}else{
			//alert(result['Message']);
			this.api.ErrorMsg(result['Message']); 
		}
	}, (err) => { 
	  this.api.HideLoading();
	  //console.log(err.message);
	  this.api.ErrorMsg(err.message);
	});
   }

}



}
