import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { Router } from  '@angular/router';

@Component({
  selector: 'app-sr-view',
  templateUrl: './sr-view.component.html',
  styleUrls: ['./sr-view.component.css']
})
export class SrViewComponent implements OnInit {


SRPolicyUpdateUW_OPS_Form: FormGroup;
isSubmitted  = false;

Id:any;
row:any=[];
PostingRemarksAr:any=[];

Payout_Details:any=[];
PayoutMaster:any=[];

User_Rights:any = [];
Remarks:string;

OperationsEmp_Ar:any;
AccountsEmp_Ar:any;

Operations_User_Id:any=0;
Accounts_User_Id:any=0;

Agent_Id:any;
Base_Url:any;
Documents:any;
IsDisabled:any=false;
selectedFiles: File;

UseFor_IT_SQL:any;

showDetails_0:any=1;
showDetails_1:any=0;
showDetails_2:any=0;
showDetails_3:any=0;
showDetails_4:any=0;
showDetails_5:any=0;
showDetails_6:any=0;
 
  constructor(
	public dialogRef: MatDialogRef<SrViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
	private router: Router, 
	private formBuilder: FormBuilder
  ) { 
	
	this.SRPolicyUpdateUW_OPS_Form  =  this.formBuilder.group({
			Policy_No: ['', [Validators.required]],
			Final_Premium: ['', [Validators.required]],
			Policy_Attachment: ['', [Validators.required]],
	});
  
  }

  ngOnInit() {
	this.Id = this.data.Id;
	this.GetSR();   
	//this.Get_Operation_Employee();   
	//this.Get_Accounts_Employee();   
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
   
  
   get FC() { return this.SRPolicyUpdateUW_OPS_Form.controls; }
  
   
  GetSR(){
	
	const formData = new FormData();
	 	
		formData.append('Id',this.Id);
		 
		formData.append('User_Id',this.api.GetUserId());
		formData.append('User_Code',this.api.GetUserData('Emp_Id'));	
	  
	this.api.IsLoading(); 
	this.api.HttpForSR('post','ViewSR',formData).then((result) => {
	//this.api.HttpGetType('reports/BussinessReport/ViewById?Type=Normal&Id='+this.Id+'&User_Id='+this.api.GetUserData('Id')+'&User_Code='+this.api.GetUserData('Emp_Id')+'&User_Type='+this.api.GetUserType() ).then((result) => {
	this.api.HideLoading();
	
		if(result['Status'] == true){
			//this.CloseModel();
			
			this.row = result['Data'];
			/*
			this.PostingRemarksAr = result['PostingRemarks'];
			this.User_Rights = result['SR_User_Rights'];
			this.Payout_Details = result['Payout_Details'];
			this.PayoutMaster = result['PayoutMaster'];
			  
			this.Documents = result['Documents'];
			 
			
			this.Base_Url = result['Base_Url']+this.row.Id+'/';
			*/
			 
			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.Toast('Warning',result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.Toast('Warning',err.message);
	});
  }
  
 
  ViewDocument(name){ 
	 var url = this.row['Docs_Base_Url']+name;
	 window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
 
  
  UserForIT(){
	  this.UseFor_IT_SQL = this.row.UseFor_IT;
	  //alert(this.row.UseFor_IT);
	  
	    var textToBeCopied = this.UseFor_IT_SQL;  
		let textarea = null;
		
		textarea = document.createElement("textarea");
		textarea.style.height = "0px";
		textarea.style.left = "-100px";
		textarea.style.opacity = "0";
		textarea.style.position = "fixed";
		textarea.style.top = "-100px";
		textarea.style.width = "0px";
		document.body.appendChild(textarea);
		// Set and select the value (creating an active Selection range).
		textarea.value = textToBeCopied;
		textarea.select();
		// Ask the browser to copy the current selection to the clipboard.
		let successful = document.execCommand("copy");
		if (successful) {
		  // do something
		  this.Totast();
		} else {
		  // handle the error
		}
		if (textarea && textarea.parentNode) {
		  textarea.parentNode.removeChild(textarea);
		} 
  }
  
  Totast(){
	 //this.snackbar_msg = msg;
	 var x = document.getElementById("snackbar2"); 
		x.className = "show";
		setTimeout(() => { x.className = x.className.replace("show", ""); }, 3000); 
  }
  
    ShowDetails(index,Status){ 
  
	 if(index == 0 && Status == 1){ this.showDetails_0 = 0;}
	 if(index == 0 && Status == 0){ this.showDetails_0 = 1;}
	 
	 if(index == 1 && Status == 1){ this.showDetails_1 = 0;}
	 if(index == 1 && Status == 0){ this.showDetails_1 = 1;}
	 
	 if(index == 2 && Status == 1){ this.showDetails_2 = 0;}
	 if(index == 2 && Status == 0){ this.showDetails_2 = 1;}
	 
	 if(index == 3 && Status == 1){ this.showDetails_3 = 0;}
	 if(index == 3 && Status == 0){ this.showDetails_3 = 1;}
	 
	 if(index == 4 && Status == 1){ this.showDetails_4 = 0;}
	 if(index == 4 && Status == 0){ this.showDetails_4 = 1;}
	 
	 if(index == 5 && Status == 1){ this.showDetails_5 = 0;}
	 if(index == 5 && Status == 0){ this.showDetails_5 = 1;}
	 
	 if(index == 6 && Status == 1){ this.showDetails_6 = 0;}
	 if(index == 6 && Status == 0){ this.showDetails_6 = 1;}

  }
 
  

}