import { Component,HostListener } from '@angular/core';
import { Router,NavigationEnd } from  '@angular/router';
import { ApiService } from './providers/api.service';
//import { SocketioService } from './providers/socketio.service';
import { SessionExpiredComponent } from './modals/session-expired/session-expired.component';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'BMS';
  IsLogged:string='FALSE';
  
  currentRoute: string;
  
  interval:any;
  
 
 
  
  constructor(
		public api : ApiService,
		//public socket : SocketioService,
		private router: Router, 
		public dialog: MatDialog
 	 
    ){
	
	

	this.api.currentMessage.subscribe((message) => { 
				console.log(message);
				this.IsLogged = message['IsLogged'];
			}, (err) => { 
	});
		
	 if( this.api.GetUserData('Logged_In') == 'TRUE'){
	  this.IsLogged = 'TRUE';
	 }else{
		this.IsLogged = 'FALSE'; 
		if( this.api.GetUserData('Logged_In') == 'FALSE'){
		 this.router.navigateByUrl('/login');
	  }  
	 }
	 console.log(this.IsLogged);
	 
	 
	  
	}
	
	@HostListener('document:click', ['$event'])
	  handleClick(event: Event) {
		//console.log(event);
		clearInterval(this.interval);
		//alert();
		if( this.api.GetUserData('Logged_In') == 'TRUE' && this.api.GetUserData('SessionExpired_State') == 'FALSE'){	
		  this.StartSession();
	    }
	 }
  
	ngOnInit() {
	  if( this.api.GetUserData('Logged_In') == 'TRUE' && this.api.GetUserData('SessionExpired_State') == 'FALSE'){	
		  this.StartSession();
	  }
	  if( this.api.GetUserData('Logged_In') == 'TRUE' && this.api.GetUserData('SessionExpired_State') == 'TRUE'){	
		  this.CheckSessionExpired();
	  }

	 //this.socket.setupSocketConnection();
	  
 
    }
	
	StartSession(){
		 if( this.api.GetUserData('Logged_In') == 'TRUE'){
			var SessionExpired = this.api.GetUserData('SessionExpired'); 
			//console.log("SessionExpired Time :- " + SessionExpired);
			var countdown = SessionExpired * 60 * 1000; // 30 minutes 
			this.interval = setInterval(() => {
			countdown -= 1000;
			var min = Math.floor(countdown / (60 * 1000));
			var sec = Math.floor((countdown - (min * 60 * 1000)) / 1000); 

			  if(countdown <= 0) {
				 //alert("30 min!");
				 clearInterval(this.interval);
				 if( this.api.GetUserData('Logged_In') == 'TRUE'){
					 this.CheckSessionExpired();
					 //doSomething();
				 }
			  } else {
				 //console.log(min + " : " + sec);
			  }

			}, 1000); //1000ms. = 1sec.
		 }
	}
	 
	CheckSessionExpired(): void {
	  
		const dialogRef = this.dialog.open(SessionExpiredComponent, {
		  width: '30%',
		  height:'62%',
		  disableClose : true,
		  data: {Id : 0}
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log(result);
		  
		  if( this.api.GetUserData('Logged_In') == 'TRUE' && this.api.GetUserData('SessionExpired_State') == 'FALSE'){	
		    //this.StartSession();
	      }
		
		});
	  
    }
	
	
	
	 
	 
  
	
	 
 
}
