import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-accounts-sr-approve-reject',
  templateUrl: './accounts-sr-approve-reject.component.html',
  styleUrls: ['./accounts-sr-approve-reject.component.css']
})
export class AccountsSrApproveRejectComponent implements OnInit {

Ids_Ar:any;
Remark:any;
 
 
  constructor(
	public dialogRef: MatDialogRef<AccountsSrApproveRejectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
  ) { }

  ngOnInit() {
	this.Ids_Ar = JSON.parse(this.data.Json_Str);
	console.log(this.Ids_Ar); 
	  
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
 
  
  AddRemarks(){
	  console.log(this.Ids_Ar); 
	
	const formData = new FormData();
	
	formData.append('User_Id',this.api.GetUserId());
	
	for (var i = 0; i < this.Ids_Ar.length; i++) {
		formData.append('SR_Data[]',JSON.stringify({Id:this.Ids_Ar[i]['Id'],Remark:this.Ids_Ar[i]['Remark']}));
	}
			
	this.api.IsLoading();
	this.api.HttpPostType('sr/Accounts/RejectSR_Multiple',formData).then((result) => {
	this.api.HideLoading();
	
		if(result['Status'] == true){
			this.CloseModel();
			 
			this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }

}
