import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';


class ColumnsObj {
  Id: string;
  isSelected: any;
  Status: any;
  Action: string;
  RequestDate: string;
  Agent: string;
  Pending: string;
  Approved: string;
  Rejected: string;
  TotalFiles: string;
  Group_Id: string;
}
 
 
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalPremium: any[];
  SQL_Where: any;
}

@Component({
  selector: 'app-mf-requests',
  templateUrl: './mf-requests.component.html',
  styleUrls: ['./mf-requests.component.css']
})
export class MfRequestsComponent implements OnInit {

  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective; 
  
 dtOptions: DataTables.Settings = {};
 dataAr: ColumnsObj[];
 
  
 
 TotalNetPremium:number=0.00;
 TotalSR:number=0;
 SQL_Where_STR:any;

 Id:any;
  

  constructor(private http: HttpClient,public dialogRef: MatDialogRef<MfRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,) { }

  ngOnInit() {
    this.Id = this.data.Agent_Id;

    this.Get();
  }


  Reload(){
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var pageinfo = dtInstance.page.info().page;
      dtInstance.page(pageinfo).draw(false);
    });
    }
    ResetDT(){
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search('').column(0).search('').draw();
    });
    }
    
    
    Get(){
    const that = this;
     
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        processing: true,
      
        ajax: (dataTablesParameters: any, callback) => {
          that.http
             .post<DataTablesResponse>(environment.apiUrl+'/brokerage/PayoutRequest/MF_Fund_GridData?ViewType=Multiple&Agent_Id='+this.Id+'&User_Id='+this.api.GetUserId(),
         dataTablesParameters, this.api.getHeader() 
            ).subscribe(resp => {
              that.dataAr = resp.data; 
         
              
             
              that.SQL_Where_STR = resp.SQL_Where; 
         //alert(resp.SQL_Where);
        if(that.dataAr.length>0){
          //that.Is_Export = 1;
        }
        
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                //TotalNetPremiumAr: resp.totalPremium,
                data: []
              });
            });
        },
      
      /*columns: [{ data: 'Id' }, //orderable : false
          { data: 'Action' },
          { data: 'RequestDate' },
          { data: 'Status' }, 
          { data: 'Agent' }, 
          { data: 'Pending' },
          { data: 'Approved' },
          { data: 'Rejected' },
          { data: 'TotalFiles' },
          { data: 'Group_Id' },
         
        ],
      */
      columnDefs: [ {
          targets: [0], // column index (start from 0)
          orderable: false, // set orderable false for selected columns
         }]
         
      }; 
    }


    promptfn(m,a){
      var msg = prompt(m, a); 
        if (msg == null) {
        return '';
      }else if (msg == "") {
        return this.promptfn("Please Enter Release Amount",a);
      }else if (isNaN(parseInt(msg))) {
        return this.promptfn("Please Enter valid Release Amount",a);
      }else{
        return msg;
      }	
    }
  
    Release(Id,Partially_Amount){ 
      var amt =  this.promptfn("Please Enter Release Amount",Partially_Amount);
      console.log(amt);	 
      
       if(amt != ''){
  
        if(Partially_Amount<amt){ 
          alert('Release amount should be less than or equal to Total Fund amount!')
          return;
        }
  
  
       
         const formData = new FormData();
         formData.append('User_Id',this.api.GetUserId());
         formData.append('Id',Id); 
         formData.append('Release_Amt',amt);
          
        this.api.HttpPostType('brokerage/PayoutRequest/MF_Fund_Release_Amount',formData).then((result) => {
          if(result['Status'] == true){
            this.api.ToastMessage(result['Message']);	
            this.Reload();			
          }else{
             this.api.ErrorMsg(result['Message']);
          }
          
        }, (err) => { 
           this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
        });    
     
       }
       
      }
     
      ReleaseDetails(row_Id){
        var baseurl = 'https://api.policyonweb.com/API/v1/brokerage/PayoutRequest/ViewReleaseDetails';
        var url = baseurl+'?ViewType=Single&User_Id='+this.api.GetUserId()+'&Id='+row_Id; 
        window.open(url, "", "fullscreen=no");  
      }
    

}
