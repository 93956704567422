import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ApiService } from './providers/api.service';
import { SocketioService } from './providers/socketio.service';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule,NgxSpinnerService } from "ngx-spinner";

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';  
//import {SelectModule} from 'ng2-select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { EditorModule } from '@tinymce/tinymce-angular';
import { ChartsModule,ThemeService } from 'ng2-charts';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
//import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { CKEditorModule } from "ckeditor4-angular";

import { ScrollingModule } from '@angular/cdk/scrolling';

import { HeaderComponent } from './include/header/header.component';
import { FooterComponent } from './include/footer/footer.component';
       
import { PosactionComponent } from './modals/posaction/posaction.component';
import { AddProspectCallComponent} from './modals/daily-tracking-circle-followup/add-prospect-call/add-prospect-call.component';
import { AgentSrActionComponent } from './modals/agent-sr-action/agent-sr-action.component';
import { AgentSrDocumentsComponent } from './modals/agent-sr-documents/agent-sr-documents.component';
import { EmployeeRightsComponent } from './modals/employee-rights/employee-rights.component';
import { SalesSrActionComponent } from './modals/sales-sr-action/sales-sr-action.component';
import { AccountsSrApproveRejectComponent } from './modals/accounts-sr-approve-reject/accounts-sr-approve-reject.component';
 
import { AgentDocumentsViewComponent } from './modals/agent-documents-view/agent-documents-view.component';
import { AgentDetailsViewComponent } from './modals/agent-details-view/agent-details-view.component';
 
import { FinanceSrViewComponent } from './modals/finance-sr-view/finance-sr-view.component';
import { ChangePasswordLoginComponent } from './modals/change-password-login/change-password-login.component';

import { AdminSRCancelComponent } from './modals/admin-srcancel/admin-srcancel.component';
import { AdminAgentsQCComponent } from './modals/admin-agents-qc/admin-agents-qc.component';
import { AdminEmployeeTransferComponent } from './modals/admin-employee-transfer/admin-employee-transfer.component';
import { AdminAgentsFleetCreationComponent } from './modals/admin-agents-fleet-creation/admin-agents-fleet-creation.component';
import { SrCancelByUWComponent } from './modals/sr-cancel-by-uw/sr-cancel-by-uw.component';
import { PayoutRmAuthorityViewComponent } from './modals/payout-rm-authority-view/payout-rm-authority-view.component';
import { PayInAdminViewComponent } from './modals/pay-in-admin-view/pay-in-admin-view.component';
import { CrossSellingRemarkComponent } from './modals/cross-selling-remark/cross-selling-remark.component';
import { PayoutAgentGroupRequestViewComponent } from './modals/payout-agent-group-request-view/payout-agent-group-request-view.component';
import { AgentOrcComponent } from './modals/agent-orc/agent-orc.component';
import { AgentOrcDetailsViewComponent } from './modals/agent-orc-details-view/agent-orc-details-view.component';

import { SrEditByRightComponent } from './modals/sr-edit-by-right/sr-edit-by-right.component';
import { DownloadingViewComponent } from './modals/downloading-view/downloading-view.component';
import { SessionExpiredComponent } from './modals/session-expired/session-expired.component';
import { PaymentlinkComponent } from './modals/paymentlink/paymentlink.component';
import { FinanceSrDisbursementComponent } from './modals/finance-sr-disbursement/finance-sr-disbursement.component'; 
import { SrPostingViewGroupWiseComponent } from './modals/sr-posting-view-group-wise/sr-posting-view-group-wise.component';


import { BrokrageRequestViewComponent } from './modals/brokrage-request-view/brokrage-request-view.component';
import { BrokrageRequestLoaderComponent } from './modals/brokrage-request-loader/brokrage-request-loader.component'; 
import { AgentOrcBanksDetailsComponent } from './modals/agent-orc-banks-details/agent-orc-banks-details.component'; 
import { GemsDetailsViewComponent } from './modals/gems-details-view/gems-details-view.component'; 
import { PayinRmaDetailsComponent } from './modals/payin-rma-details/payin-rma-details.component'; 
import { MergeCodeComponent } from './modals/merge-code/merge-code.component';
import { GemseventComponent } from './modals/gemsevent/gemsevent.component';
import { PosCategorizationComponent } from './modals/pos-categorization/pos-categorization.component';
import { EditSrComponent } from './modals/edit-sr/edit-sr.component';
import { SearchPipe } from '../search.pipe';
import { GemsDetailsViewRemarkComponent } from './modals/gems-details-view-remark/gems-details-view-remark.component';
import { AgentPrimeRequestComponent } from './modals/agent-prime-request/agent-prime-request.component';
import { SrViewComponent } from './modals/sr-view/sr-view.component';
import { AgentStatusActionComponent } from './modals/agent-status-action/agent-status-action.component'; 
import { FinanceOpenLeadsActionComponent } from './modals/finance-open-leads-action/finance-open-leads-action.component';

import { ExtraPayoutDetailsComponent } from './modals/extra-payout-details/extra-payout-details.component';
import { MandateLetterFormComponent } from './modals/mandate-letter-form/mandate-letter-form.component';
import { EditPayoutRmComponent } from './modals/edit-payout-rm/edit-payout-rm.component'; 
import { DailyTrackingCircleFollowupComponent } from './modals/daily-tracking-circle-followup/daily-tracking-circle-followup.component';
import { FollowUpTrackComponent } from './modals/daily-tracking-circle-followup/follow-up-track/follow-up-track.component';
import { ManageRightsComponent } from './modals/manage-rights/manage-rights.component';
import { PartnerEarningViewComponent } from './modals/partner-earning-view/partner-earning-view.component';
import { PayinformmodelComponent } from './modals/payinformmodel/payinformmodel.component';
import { CreateMisReportNameComponent } from './modals/create-mis-report-name/create-mis-report-name.component';
import { V2PayInRequestUpdateComponent } from './modals/v2-pay-in-request-update/v2-pay-in-request-update.component';
import { V2AgentSearchComponent } from './modals/v2-agent-search/v2-agent-search.component'; 
import { MandateLetterQcComponent } from './modals/mandate-letter-qc/mandate-letter-qc.component';
import { UpdatationMisReportNameComponent } from './modals/updatation-mis-report-name/updatation-mis-report-name.component'; 
 
 
import { ViewgemsdetailspopupComponent } from './modals/gems-reports/viewgemsdetailspopup/viewgemsdetailspopup.component';
import { GemsTransactionComponent } from './modals/gems-reports/gems-transaction/gems-transaction.component';
import { MfRequestsComponent } from './modals/mf-requests/mf-requests.component';
import { AddFinancePayinComponent } from './modals/finance/add-finance-payin/add-finance-payin.component';
import { GenerateInvoiceComponent } from './modals/generate-invoice/generate-invoice.component';
import { AddPayorComponent } from './modals/add-payor/add-payor.component';
import { V2PayInDataviewComponent } from './modals/v2-pay-in-dataview/v2-pay-in-dataview.component';
 
import { AgreementActiveInactiveComponent } from './modals/agreement-active-inactive/agreement-active-inactive.component';
import { PoPriorityLogsComponent } from './modals/po-priority-logs/po-priority-logs.component';
import { EditsrpayoutComponent } from './modals/editsrpayout/editsrpayout.component';
import { AddSrRecoveryReportComponent } from './modals/add-sr-recovery-report/add-sr-recovery-report.component';
import { UpdateUtrNoComponent } from './modals/update-utr-no/update-utr-no.component';

import { SrStatusActionComponent } from './modals/life-sr/sr-status-action/sr-status-action.component';
import { SrCancelActionComponent } from './modals/life-sr/sr-cancel-action/sr-cancel-action.component';
import { FranchiseRightsComponent } from './modals/franchise-rights/franchise-rights.component';
 

 //AddSrRecoveryReportComponent,UpdateUtrNoComponent,EditsrpayoutComponent,SrCreationComponent

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PosactionComponent,
    AddProspectCallComponent,
    AgentSrActionComponent,
    AgentSrDocumentsComponent,
    EmployeeRightsComponent,
    SalesSrActionComponent,
    AccountsSrApproveRejectComponent,
    AgentDocumentsViewComponent,
    AgentDetailsViewComponent, 
    FinanceSrViewComponent,
    ChangePasswordLoginComponent,
    AdminSRCancelComponent,
    AdminAgentsQCComponent, 
    AdminEmployeeTransferComponent,
    AdminAgentsFleetCreationComponent,
    SrCancelByUWComponent,
    PayoutRmAuthorityViewComponent,
    PayInAdminViewComponent,
    CrossSellingRemarkComponent,
    PayoutAgentGroupRequestViewComponent,
    AgentOrcComponent,
    AgentOrcDetailsViewComponent,
    SrEditByRightComponent,
    DownloadingViewComponent,
    SessionExpiredComponent,

    PaymentlinkComponent,

    FinanceSrDisbursementComponent,

    SrPostingViewGroupWiseComponent,

    BrokrageRequestViewComponent,
    BrokrageRequestLoaderComponent,

    AgentOrcBanksDetailsComponent,
    GemsDetailsViewComponent,
    PayinRmaDetailsComponent,
    MergeCodeComponent,
    GemseventComponent,
    PosCategorizationComponent,
    EditSrComponent,
    SearchPipe,
    GemsDetailsViewRemarkComponent,
    AgentPrimeRequestComponent,
    SrViewComponent,
    AgentStatusActionComponent, 
    FinanceOpenLeadsActionComponent,
    ExtraPayoutDetailsComponent,MandateLetterFormComponent, EditPayoutRmComponent,
    DailyTrackingCircleFollowupComponent,FollowUpTrackComponent, 
    ManageRightsComponent,
    PartnerEarningViewComponent,
    PayinformmodelComponent,
    CreateMisReportNameComponent,
    V2PayInRequestUpdateComponent,
    V2AgentSearchComponent,
    MandateLetterQcComponent,
    UpdatationMisReportNameComponent,
    ViewgemsdetailspopupComponent,
    GemsTransactionComponent,
    MfRequestsComponent,
    AddFinancePayinComponent,
    GenerateInvoiceComponent,
    AddPayorComponent,
    V2PayInDataviewComponent,
    AgreementActiveInactiveComponent,
    PoPriorityLogsComponent,
    EditsrpayoutComponent,
    AddSrRecoveryReportComponent,
    UpdateUtrNoComponent, 
    SrStatusActionComponent,SrCancelActionComponent, FranchiseRightsComponent   
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    //EditorModule,
    ChartsModule,
    AppRoutingModule,
    HttpClientModule,
    DataTablesModule,
    NgxSpinnerModule,
    //SelectModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    CKEditorModule,
	MatAutocompleteModule,
	BrowserAnimationsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ApiService,SocketioService,NgxSpinnerService,ThemeService],
  entryComponents: [

    PosactionComponent,
    AddProspectCallComponent,
    AgentSrActionComponent,
    AgentSrDocumentsComponent,
    EmployeeRightsComponent,
    SalesSrActionComponent,
    AccountsSrApproveRejectComponent,
    AgentDocumentsViewComponent,
    AgentDetailsViewComponent, 
    FinanceSrViewComponent,
    ChangePasswordLoginComponent,
    AdminSRCancelComponent,
    AdminAgentsQCComponent, 
    AdminEmployeeTransferComponent,
    AdminAgentsFleetCreationComponent,
    SrCancelByUWComponent,
    PayoutRmAuthorityViewComponent,
    PayInAdminViewComponent,
    CrossSellingRemarkComponent,
    PayoutAgentGroupRequestViewComponent,
    AgentOrcComponent,
    AgentOrcDetailsViewComponent,
    SrEditByRightComponent,
    DownloadingViewComponent,
    SessionExpiredComponent,

    PaymentlinkComponent,

    FinanceSrDisbursementComponent,

    SrPostingViewGroupWiseComponent,

    BrokrageRequestViewComponent,
    BrokrageRequestLoaderComponent,

    AgentOrcBanksDetailsComponent,
    GemsDetailsViewComponent,
    PayinRmaDetailsComponent,
    MergeCodeComponent,
    GemseventComponent,
    PosCategorizationComponent,
    EditSrComponent,
    GemsDetailsViewRemarkComponent,
    AgentPrimeRequestComponent,
    SrViewComponent,
    AgentStatusActionComponent, 
    FinanceOpenLeadsActionComponent,
    ExtraPayoutDetailsComponent,MandateLetterFormComponent, EditPayoutRmComponent,
    DailyTrackingCircleFollowupComponent,FollowUpTrackComponent, 
    ManageRightsComponent,
    PartnerEarningViewComponent,
    PayinformmodelComponent,
    CreateMisReportNameComponent,
    V2PayInRequestUpdateComponent,
    V2AgentSearchComponent,
    MandateLetterQcComponent,
    UpdatationMisReportNameComponent,
    ViewgemsdetailspopupComponent,
    GemsTransactionComponent,
    MfRequestsComponent,
    AddFinancePayinComponent,
    GenerateInvoiceComponent,
    AddPayorComponent,
    V2PayInDataviewComponent,
    AgreementActiveInactiveComponent,
    PoPriorityLogsComponent,
    EditsrpayoutComponent,
    AddSrRecoveryReportComponent,
    UpdateUtrNoComponent,
    SrStatusActionComponent,SrCancelActionComponent,
    FranchiseRightsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
