import { Component, OnInit } from '@angular/core';
import { FormControl,FormBuilder, FormGroup,FormArray, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-gemsevent',
  templateUrl: './gemsevent.component.html',
  styleUrls: ['./gemsevent.component.css']
})
export class GemseventComponent implements OnInit {

  AddForm: FormGroup;
  isSubmitted = false;

  dropdownSettings: any = {};
  
  UsersAr:any = [];
  
  constructor(
	public api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { 
	this.AddForm = this.formBuilder.group({
      selectInvitedType: [""],
      UserIds: [""],
	  Users_Ar : this.formBuilder.array([]),
    });
	
	this.dropdownSettings = {
      singleSelection: false,
      idField: "Id",
      textField: "Name",
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      //disabled: false
    };
	
  }

  ngOnInit() {
	this.addBrother();  
	this.addBrother();  
	this.addBrother();  
  }
  
  LI_Family_Health_Status_Brother() : FormArray {
    return this.AddForm.get("Users_Ar") as FormArray
  } 
  newBrother(): FormGroup {
    return this.formBuilder.group({
      Code: '',
      Name: '',
      Mobile: '',
      Date_Str: '',
      Time_Str: '',
      Location: '',
    }) 
  }
  addBrother() {
    this.LI_Family_Health_Status_Brother().push(this.newBrother());
  }
   
  removeBrother(i:number) {
    this.LI_Family_Health_Status_Brother().removeAt(i);
  }
  
  
   GetUserData(e){
	 var UserType = e.target.value;
	 
	this.api.IsLoading();
	this.api.Call('other/GemsEvent?UserType='+UserType+'&User_Id='+this.api.GetUserId()).then((result) => {
	this.api.HideLoading();
	
		if(result['Status'] == true){
			this.UsersAr = result['Data'];
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
	
  }
  
    onItemSelect(item: any){
		
		var fields = this.AddForm.value; 
		const formData = new FormData();
		
		formData.append('User_Id',this.api.GetUserId());
		formData.append('selectInvitedType',fields['selectInvitedType']);
		formData.append('UserIds',JSON.stringify(fields['UserIds']));
		
		//this.api.IsLoading();
		this.api.HttpPostType('other/GemsEvent/ListView',formData).then((result) => {
		//this.api.HideLoading();
		
			if(result['Status'] == true){
				//this.UsersAr = result['Data']; 
				this.AddForm.patchValue(result['Data']); 
			}else{
				this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		 this.api.ErrorMsg(err.message);
		});
	}
  
  

}
