import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-sr-cancel-by-uw',
  templateUrl: './sr-cancel-by-uw.component.html',
  styleUrls: ['./sr-cancel-by-uw.component.css']
})
export class SrCancelByUWComponent implements OnInit {


Id:any;
SR_No:any;
CancelRemark:any;
 

  constructor(
	public dialogRef: MatDialogRef<SrCancelByUWComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
  ) { }

  ngOnInit() {
	this.Id = this.data.Id;
	this.SR_No = this.data.SR_No;
	 
	 
  }
  
  CloseModel(): void { 
    this.dialogRef.close({ 
      Status: 'Model Close'
    });
  }
   
  
  VerifyAndUpdate(){ 
	  
	const formData = new FormData(); 
			
	formData.append('User_Id',this.api.GetUserId());
	formData.append('SR_Id',this.Id); 
	formData.append('SR_No',this.SR_No); 
 
	formData.append('CancelRemark',this.CancelRemark); 
	  
	
	
	this.api.IsLoading(); 
	this.api.HttpPostType('reports/AdminSrReport/CancelSRByUW',formData).then((result) => {
	this.api.HideLoading();
	  
		if(result['Status'] == true){
			
			
			this.CloseModel();
			  
			this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
	
  }
  

}
