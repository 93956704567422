import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup,FormControl,FormArray, Validators } from  '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { Router,ActivatedRoute } from  '@angular/router';

@Component({
  selector: 'app-pay-in-admin-view',
  templateUrl: './pay-in-admin-view.component.html',
  styleUrls: ['./pay-in-admin-view.component.css']
})
export class PayInAdminViewComponent implements OnInit {

   
Id:any;

PayInForm:FormGroup;
isSubmitted  = false;

//disabled = false;
//ShowFilter = false;
//limitSelection = false;

dropdownSettings:any={};
LOB_dropdownSettings:any={};
ins_Company_dropdownSettings:any={};
cities:any = [];
selectedItems: any = [];


User_Rights:any = [];
rowAr:any = [];

Brokers_Ar:any = [];
Fleets_Ar:any = [];
RM_Ar:any = [];
Agents_Ar:any = [];
Ins_Companies_Ar:any = [];
Zones_Ar:any = [];
States_Ar:any = [];
RTO_Ar:any = [];
LOB_Ar:any = [];

HEV_Ar:any=[];
Hev_Status: any = false;

Source_Ar:any = [];
CPA_Ar:any = [];


Fuel_Type_Ar:any = [];
Body_Type_Ar:any = [];
File_Type_Ar:any = [];

Products_Ar:any = [];
SubProducts_Ar:any = [];

Segment_Ar:any = [];
Classes_Ar:any = [];
Sub_Classes_Ar:any = [];
 

ItemSelection:any=[];	
ItemSelectionState:any=[];
ItemSelectionProducts:any=[];	
ItemSelectionSubProducts:any=[];	
ItemSelectionSegment:any=[];	
ItemSelectionClass:any=[];	

ItemSelectionNMSegment:any=[];
ItemSelectionNMClass:any=[];
ItemSelectionNMProducts:any=[];	
	 

Agents_Placeholder:string = 'Select Agents (0)';
isCheckedAllAgents: any = true;

isChecked_Motor: any = false;
isChecked_Health: any = false;
isChecked_NonMotor: any = false;

ButtonType:string='Save';
AssginType:string=''; // Admin,HOD
Effective_Date_Error:string='';

EnableTwoWheelerBodyType:any=0;

selected:any;

  constructor(public api : ApiService,
	public dialogRef: MatDialogRef<PayInAdminViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	private router: Router,private activatedRoute: ActivatedRoute,
  private fb: FormBuilder) { }

  ngOnInit() {
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'Id',
			textField: 'Name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			allowSearchFilter: true
		};
		this.LOB_dropdownSettings = {
			singleSelection: false,
			enableCheckAll: false,
			idField: 'Id',
			textField: 'Name',
		};
		this.ins_Company_dropdownSettings = {
			allowSearchFilter: true,
			singleSelection: true,
			enableCheckAll: false,
			idField: 'Id',
			textField: 'Name',
		};
		
		
	this.Id = this.data.Id;
	//this.Id = this.activatedRoute.snapshot.paramMap.get('Id');
	
	//this.CPA_Ar = [{Id:'Yes',Name:'Yes'},{Id:'No',Name:'No'}];
	//this.Source_Ar = [{Id:'Online',Name:'Online'},{Id:'Offline',Name:'Offline'}];
	//this.Fuel_Type_Ar = [{Id:'Petrol',Name:'Petrol'},{Id:'Diesel',Name:'Diesel'}];
	this.Body_Type_Ar = [{Id:'BIKE',Name:'BIKE'},{Id:'SCOOTER',Name:'SCOOTER'},{Id:'E SCOOTER',Name:'E SCOOTER'}];
	
  this.HEV_Ar = [
			{Id:'None',Name:'None'},
			{Id:'AUDI',Name:'AUDI'},
			{Id:'BENTLEY',Name:'BENTLEY'},
			{Id:'BMW',Name:'BMW'},
			{Id:'CADILLAC',Name:'CADILLAC'},
			{Id:'HUMMER',Name:'HUMMER'},
			{Id:'JAGUAR',Name:'JAGUAR'},
			{Id:'LEXUS',Name:'LEXUS'},
			{Id:'MAYBACH',Name:'MAYBACH'},
			{Id:'MERCEDES',Name:'MERCEDES'},
			{Id:'PORSCHE',Name:'PORSCHE'},
			{Id:'ROLLS ROYCE',Name:'ROLLS ROYCE'},
			{Id:'ROVER',Name:'ROVER'},
		];
		
	console.log(this.Id);
	//this.ComponentsData();
	this.GetRow(); 
	
	this.PayInForm = this.fb.group({
		//city: [this.selectedItems]
		Broker_Ids: ['',[Validators.required]],
		//RM_Ids: ['',[Validators.required]],
		AgentType: ['',[Validators.required]],
		Agent_Ids: ['',[Validators.required]],
		Ins_Compaines_Ids: ['',[Validators.required]],
		Zone_Ids: ['',[Validators.required]],
		States_Ids: ['',[Validators.required]],
		RTO_Ids: [''],
		Manufacturer_Year: [],
		
		Source: [''],
		CPA: [''],
		NCB_Status: [''], 
		VehicleAgeFrom: [''], 
		VehicleAgeTo: [''],
		HEV: [''], 		
		
		Fuel_Type: [''],
		File_Type: ['',[Validators.required]],
	  
		Product_Ids: ['',[Validators.required]],
		SubProduct_Ids: ['',[Validators.required]],
		Segment_Ids: ['',[Validators.required]],
		Class_Ids: ['',[Validators.required]],
		Sub_Class_Ids: ['',[Validators.required]],
		Body_Type_Ids: [''],
		
		PayInOD: [''],
		PayOutOD: [''],
		
		PayInTP: [''],
		PayOutTP: [''],
		
		PayInNet: [''],
		PayOutNet: [''], 
		
		PayInReward: [''],
		PayOutReward: [''],
		
		PayInScheme: [''],
		PayOutScheme: [''], 
		
		Effective_Date: ['',[Validators.required]],
		PayIn_Remark: ['',[Validators.required]],
		Extra_Remark: ['',[Validators.required]],
		
	});
	
	
	//this.onChangeEffective_Date();
	  
	  
  }
  
  get FC() { return this.PayInForm.controls; }
  
  CloseModel(): void { 
    this.dialogRef.close({ 
      Status: 'Model Close'
    });
  }
  
  
  ComponentsData(){
	   
		this.api.IsLoading();
		this.api.Call('pay/PayIn/ComponentsData?User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();
		
			if(result['Status'] == true){
				
				this.User_Rights = result['Data']['SR_User_Rights'];
				this.Brokers_Ar = result['Data']['Brokers'];
				this.Fleets_Ar = result['Data']['Fleets'];
				this.RM_Ar = result['Data']['RM_Ar'];
				this.Agents_Ar = result['Data']['Agents_Ar']; 
				
				this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';
								
				this.Ins_Companies_Ar = result['Data']['Ins_Compaines']; 
				this.Zones_Ar = result['Data']['Zones'];
				this.States_Ar = result['Data']['States'];	
				
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
   }
  
  Parse(j){return JSON.parse(j);}
  GetRow(){
	   this.api.IsLoading();
		this.api.Call('pay/PayoutRMAuthority/ViewAdmin?Id='+this.Id+'&User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();
		
			if(result['Status'] == true){
				var row = result['Data'];
				var Components = result['Components'];
				var p = result['pathValue'];
				this.rowAr = row;
				//console.log(row);
				
				this.Brokers_Ar = (Components.Brokers);
				this.Ins_Companies_Ar = (Components.Ins_Compaines);
				this.Agents_Ar = (Components.Agent_Ids);
				this.Fleets_Ar = (Components.Fleets);
				this.Zones_Ar = (Components.Zones);
				this.States_Ar = (Components.States);
				this.RTO_Ar = (Components.RTO_Ar);  
				
				this.Source_Ar = (Components.Source_Ar);
				this.CPA_Ar = (Components.CPA_Ar);
				
				this.Fuel_Type_Ar = (Components.Fuel_Type_Ar);
				this.File_Type_Ar = (Components.File_Type_Ar);
				this.Body_Type_Ar = (Components.Body_Type_Ar);
				   
				this.Products_Ar = (Components.Products_Ar);
				this.SubProducts_Ar = (Components.SubProducts_Ar);
				 
				this.Segment_Ar = (Components.Segment_Ar); 
				this.Classes_Ar = (Components.Classes_Ar);
				this.Sub_Classes_Ar = (Components.Sub_Classes_Ar);
				
				var LOB = this.Parse(row.LOB_Ids); 
				console.log(LOB);
				
				for(var i=0; i<LOB.length; i++){
					if(LOB[i]=='Motor'){
						this.isChecked_Motor = true;
					}
					if(LOB[i]=='Health'){
						this.isChecked_Health = true;
					}
					if(LOB[i]=='Non Motor'){
						this.isChecked_NonMotor = true;
					}
				} 
				
				this.EnableTwoWheelerBodyType = Components.EnableTwoWheelerBodyType;
				
				this.PayInForm.patchValue(result['pathValue']); 
				 
				 
			}else{
				alert(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  alert(err.message);
	    });
    }
	

onChangeAgentType(e) { 
	
	var AgentType = e.target.value;
	 console.log(AgentType);
	 
	 this.Agents_Ar = [];
 
	var Agent_IdsControl =  this.PayInForm.get('Agent_Ids');
	Agent_IdsControl.setValue(null);
	
	if(AgentType == 'All-Agents' || AgentType == 'Non-Fleet-All-Agents'){
		Agent_IdsControl.setValidators(null);
	}else{
		Agent_IdsControl.setValidators([Validators.required]);
	}
	
	Agent_IdsControl.updateValueAndValidity();
	  
  }  
   
  searchAgents(e){
	  //console.log(e.target.value);
		//this.api.IsLoading();
		this.api.Call('pay/PayIn/SearchAgents?q='+e.target.value).then((result) => {
		//this.api.HideLoading();
			if(result['Status'] == true){
				this.Agents_Ar =  result['Data'];
				this.isCheckedAllAgents = false;
			}else{
				//alert(result['Message']);
			}
		}, (err) => { 
		  // Error log
		  //this.api.HideLoading();
		  //console.log(err.message);
		  alert(err.message);
		});  
  }	
	 
	 
	 
  
onItemSelect(item: any,Type:any) {
console.log('Type : '+ Type);
console.log('onItemSelect', item);
 
if(Type == 'RM'){
	this.ItemSelection.push(item.Id); 
	console.log(this.ItemSelection);
	//this.GetAgents('OneByOneSelect');
	
}

if(Type == 'Zone'){
	this.GetStates('OneByOneSelect');
}

if(Type == 'State'){
	this.ItemSelectionState.push(item.Id); 
	console.log(this.ItemSelectionState);
	this.GetRTO('OneByOneSelect');
	
}

if(Type == 'Product'){
	
	this.ItemSelectionProducts.push(item.Id); 
	console.log(this.ItemSelectionProducts);
	console.log(item.Id);
	
	const Body_Type_Id_Control = this.PayInForm.get('Body_Type_Ids');
	
	if( (item.Id=='Long Term Two Wheeler Liability Only Policy' ||
		item.Id=='Long Term Two Wheeler Package Policy' ||
		item.Id=='Two Wheeler Own Damage Only Policy' ||
		item.Id=='Two Wheeler Taxi Liability Only Policy' ||
		item.Id=='Two Wheeler Taxi Package Policy' ||
		item.Id=='Two Wheeler Vehicle Liability Only Policy' ||
		item.Id=='Two Wheeler Vehicle Package Policy' )
	){
		this.EnableTwoWheelerBodyType=1;
		
		//Body_Type_Id_Control.setValidators([Validators.required]);
		
	}else{ 
		this.EnableTwoWheelerBodyType=0;
		//Body_Type_Id_Control.setValidators(null);
	}
	
	
	if( (item.Id=='Long Term Private Car Liability Only Policy' ||
			item.Id=='Long Term Private Car Package Policy' ||
			item.Id=='Private Car Liability Only Policy' ||
			item.Id=='Private Car Own Damage Only Policy' ||
			item.Id=='Private Car Package Policy'
			)
		){
			this.Hev_Status = true;
		}else{
			this.Hev_Status = false;
		}
	
	
	
	this.PayInForm.get('SubProduct_Ids').setValue(null);
	this.PayInForm.get('Segment_Ids').setValue(null);
	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Sub_Class_Ids').setValue(null);

	this.SubProducts_Ar = [];
	this.Segment_Ar = [];
	this.Classes_Ar = [];
	this.Sub_Classes_Ar = [];
	
	this.SubProducts('OneByOneSelect');

		
}
if(Type == 'SubProduct'){
	this.ItemSelectionSubProducts.push(item.Id); 
	console.log(this.ItemSelectionSubProducts);
	
	this.PayInForm.get('Segment_Ids').setValue(null);
	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Sub_Class_Ids').setValue(null);

	this.Segment_Ar = [];
	this.Classes_Ar = [];
	this.Sub_Classes_Ar = [];
	
	this.Segments('OneByOneSelect');	
}
if(Type == 'Segment'){
	this.ItemSelectionSegment.push(item.Id); 
	console.log(this.ItemSelectionSegment);

	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Sub_Class_Ids').setValue(null);

	this.Classes_Ar = [];
	this.Sub_Classes_Ar = [];
	 
	
	this.Classes('OneByOneSelect');	
}
if(Type == 'Class'){
	this.ItemSelectionClass.push(item.Id); 
	console.log(this.ItemSelectionClass);

	this.PayInForm.get('Sub_Class_Ids').setValue(null);

	this.Sub_Classes_Ar = [];
	
	this.SubClasses('OneByOneSelect');	
}

if(Type == 'NM_Class'){
	this.ItemSelectionNMSegment.push(item.Id); 
	console.log(this.ItemSelectionNMSegment);

	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Product_Ids').setValue(null);
	this.PayInForm.get('SubProduct_Ids').setValue(null);

	this.Classes_Ar = [];
	this.Products_Ar = [];
	this.SubProducts_Ar = [];
	 
	this.NM_Class('OneByOneSelect');	
}
if(Type == 'NM_Product'){
	this.ItemSelectionNMClass.push(item.Id); 
	console.log(this.ItemSelectionNMClass);
	
	 
	this.PayInForm.get('Product_Ids').setValue(null);
	this.PayInForm.get('SubProduct_Ids').setValue(null);

	this.Products_Ar = [];
	this.SubProducts_Ar = [];
	 
	
	this.NM_Product('OneByOneSelect');	
}
if(Type == 'NM_Sub_Product'){
	this.ItemSelectionNMProducts.push(item.Id); 
	console.log(this.ItemSelectionNMProducts);
	
	 
	this.PayInForm.get('SubProduct_Ids').setValue(null);

	this.SubProducts_Ar = [];
	
	this.NM_Sub_Product('OneByOneSelect');	
}


 
 

}

onItemDeSelect(item: any,Type:any){
	console.log('Type : '+ Type);
	console.log('onDeSelect', item);
	
if(Type == 'RM'){
	var index = this.ItemSelection.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelection.splice(index, 1); 
	}
	//this.ItemSelection.push(item.Id); 
	console.log(this.ItemSelection);
	this.GetAgents('OneByOneDeSelect');

}

if(Type == 'Zone'){
	this.GetStates('OneByOneDeSelect');
}

if(Type == 'State'){
	var index = this.ItemSelectionState.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionState.splice(index, 1); 
	}
	//this.ItemSelectionState.push(item.Id); 
	console.log(this.ItemSelectionState);
	this.GetRTO('OneByOneDeSelect');
	
}

if(Type == 'Product'){
	var index = this.ItemSelectionProducts.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionProducts.splice(index, 1); 
	}
	//this.ItemSelection.push(item.Id); 
	console.log(this.ItemSelectionProducts);
	
	this.PayInForm.get('SubProduct_Ids').setValue(null);
	this.PayInForm.get('Segment_Ids').setValue(null);
	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Sub_Class_Ids').setValue(null);

	this.SubProducts_Ar = [];
	this.Segment_Ar = [];
	this.Classes_Ar = [];
	this.Sub_Classes_Ar = [];
	
	this.SubProducts('OneByOneDeSelect');

}
if(Type == 'SubProduct'){
	var index = this.ItemSelectionSubProducts.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionSubProducts.splice(index, 1); 
	}
	//this.ItemSelectionSubProducts.push(item.Id); 
	console.log(this.ItemSelectionSubProducts);
	
	 
	this.PayInForm.get('Segment_Ids').setValue(null);
	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Sub_Class_Ids').setValue(null);

	this.Segment_Ar = [];
	this.Classes_Ar = [];
	this.Sub_Classes_Ar = [];
	
	this.Segments('OneByOneDeSelect');

}
if(Type == 'Segment'){
	var index = this.ItemSelectionSegment.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionSegment.splice(index, 1); 
	}
	//this.ItemSelectionSegment.push(item.Id); 
	console.log(this.ItemSelectionSegment);
	
	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Sub_Class_Ids').setValue(null);
	 
	this.Classes_Ar = [];
	this.Sub_Classes_Ar = [];
	
	this.Classes('OneByOneDeSelect');

}
if(Type == 'Class'){
	var index = this.ItemSelectionClass.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionClass.splice(index, 1); 
	}
	//this.ItemSelectionClass.push(item.Id); 
	console.log(this.ItemSelectionClass);

	this.PayInForm.get('Sub_Class_Ids').setValue(null);
 
	this.Sub_Classes_Ar = [];
	
	this.SubClasses('OneByOneDeSelect');

}
if(Type == 'NM_Class'){
	var index = this.ItemSelectionNMSegment.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionNMSegment.splice(index, 1); 
	}
	//this.ItemSelectionNMSegment.push(item.Id); 
	console.log(this.ItemSelectionNMSegment);
	
	this.PayInForm.get('Class_Ids').setValue(null);
	this.PayInForm.get('Product_Ids').setValue(null);
	this.PayInForm.get('SubProduct_Ids').setValue(null);

	this.Classes_Ar = [];
	this.Products_Ar = [];
	this.SubProducts_Ar = [];
	
	this.NM_Class('OneByOneDeSelect');

}
 
if(Type == 'NM_Product'){
	var index = this.ItemSelectionNMClass.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionNMClass.splice(index, 1); 
	}
	//this.ItemSelectionNMClass.push(item.Id); 
	console.log(this.ItemSelectionNMClass);

	this.PayInForm.get('Product_Ids').setValue(null);
	this.PayInForm.get('SubProduct_Ids').setValue(null);

	this.Products_Ar = [];
	this.SubProducts_Ar = [];
	
	this.NM_Product('OneByOneDeSelect');

}

if(Type == 'NM_Sub_Product'){
	var index = this.ItemSelectionNMProducts.indexOf(item.Id);
	if (index > -1) {
	   this.ItemSelectionNMProducts.splice(index, 1); 
	}
	//this.ItemSelectionNMProducts.push(item.Id); 
	console.log(this.ItemSelectionNMProducts);
	
	this.PayInForm.get('SubProduct_Ids').setValue(null);
	this.SubProducts_Ar = [];
	
	this.NM_Sub_Product('OneByOneDeSelect');

}


 
  

}

 

onSelectAll(items: any,Type:any) {
	console.log('Type : '+ Type);
	console.log('onSelectAll', items);
	
	var Ar=[];
	for(var i=0; i<items.length; i++){Ar.push(items[i]['Id']);}
	var AllItems = Ar; //Ar.join();
	console.log(AllItems);
		
	if(Type == 'RM'){
		
		this.ItemSelection = AllItems;
		this.GetAgents('SelectAll');
	}
	
	if(Type == 'Zone'){
		this.GetStates('SelectAll');
	}
	
	if(Type == 'State'){
		this.GetRTO('SelectAll');
	}
	
	if(Type == 'Product'){
		this.SubProducts('SelectAll');
	}
	if(Type == 'SubProduct'){
		this.ItemSelectionSubProducts = AllItems;
		this.Segments('SelectAll');
	}
	if(Type == 'Segment'){
		
		this.ItemSelectionSegment = AllItems;
		this.Classes('SelectAll');
	}
	if(Type == 'Class'){
		this.ItemSelectionClass = AllItems;
		this.SubClasses('SelectAll');
	}
	if(Type == 'NM_Class'){
		this.ItemSelectionNMSegment = AllItems;
		this.NM_Class('SelectAll');
		
	}
	if(Type == 'NM_Product'){
		this.ItemSelectionNMClass = AllItems;
		this.NM_Product('SelectAll');
		
	}
	if(Type == 'NM_Sub_Product'){
		this.ItemSelectionNMProducts = AllItems;
		this.NM_Sub_Product('SelectAll');
		
	}
	
	
	
	
  
	
}
onDeSelectAll(items: any,Type:any) {
	console.log('Type : '+ Type);
	console.log('onDeSelectAll');
	if(Type == 'RM'){
		this.Agents_Ar = [];
		this.Agents_Placeholder = 'Select Agents (0)';
		this.GetAgents('DeSelectAll');
	}
	
	if(Type == 'Zone'){
		this.GetStates('DeSelectAll');
	}
	
	if(Type == 'State'){
		this.GetRTO('DeSelectAll');
	}
	if(Type == 'Product'){
		
		this.PayInForm.get('SubProduct_Ids').setValue(null);
		this.PayInForm.get('Segment_Ids').setValue(null);
		this.PayInForm.get('Class_Ids').setValue(null);
		this.PayInForm.get('Sub_Class_Ids').setValue(null);

		this.SubProducts_Ar = [];
		this.Segment_Ar = [];
		this.Classes_Ar = [];
		this.Sub_Classes_Ar = [];
		
		this.ItemSelectionProducts = []; 
		this.SubProducts('DeSelectAll');
	}
	if(Type == 'SubProduct'){
		
		this.PayInForm.get('Segment_Ids').setValue(null);
		this.PayInForm.get('Class_Ids').setValue(null);
		this.PayInForm.get('Sub_Class_Ids').setValue(null);

		this.Segment_Ar = [];
		this.Classes_Ar = [];
		this.Sub_Classes_Ar = [];
		
		this.ItemSelectionSubProducts = [];
	
	
		this.Segments('DeSelectAll');
	}
	if(Type == 'Segment'){
		 
		this.PayInForm.get('Class_Ids').setValue(null);
		this.PayInForm.get('Sub_Class_Ids').setValue(null);

		 
		this.Classes_Ar = [];
		this.Sub_Classes_Ar = [];
		
		this.ItemSelectionSegment = [];
	
		this.Classes('DeSelectAll');
	}
	if(Type == 'Class'){

		this.PayInForm.get('Sub_Class_Ids').setValue(null);

		this.Sub_Classes_Ar = [];
		
		this.ItemSelectionClass = [];
	
		this.SubClasses('DeSelectAll');
	}
	if(Type == 'NM_Class'){
		//this.ItemSelectionNMSegment = [];
		this.PayInForm.get('Class_Ids').setValue(null);
		this.PayInForm.get('Product_Ids').setValue(null);
		this.PayInForm.get('SubProduct_Ids').setValue(null);

		this.Classes_Ar = [];
		this.Products_Ar = [];
		this.SubProducts_Ar = [];
		
		this.ItemSelectionNMSegment = [];
	
		this.NM_Class('DeSelectAll');
		
	}
	if(Type == 'NM_Product'){
		//this.ItemSelectionNMClass = [];
		
		 
		this.PayInForm.get('Product_Ids').setValue(null);
		this.PayInForm.get('SubProduct_Ids').setValue(null);

		 
		this.Products_Ar = [];
		this.SubProducts_Ar = [];
		
		this.ItemSelectionNMClass = [];
	
		this.NM_Product('DeSelectAll');
		
	}
	if(Type == 'NM_Sub_Product'){
		//this.ItemSelectionNMProducts = [];
		 
		this.PayInForm.get('SubProduct_Ids').setValue(null);

		this.SubProducts_Ar = [];
		
		this.ItemSelectionNMProducts = [];
	 
		this.NM_Sub_Product('DeSelectAll');
		
	}
	 
}


 GetAgents(Type){ 
   const formData = new FormData();
   formData.append('Type',Type);
	formData.append('RM_Ids',this.ItemSelection.join());
	this.api.HttpPostType('pay/PayIn/GetAgents',formData).then((result) => {
		if(result['Status'] == true){
			
			this.Agents_Ar = result['Data'];
			this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';
			  
		}else{
			 this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	}); 
}

GetStates(Type){
	const formData = new FormData();
	formData.append('Type',Type);
	formData.append('Zone_Ids',JSON.stringify(this.PayInForm.value['Zone_Ids']));
	this.api.HttpPostType('pay/PayIn/GetStatesBy_Zone',formData).then((result) => {
		if(result['Status'] == true){
			this.States_Ar = result['Data'];
			//this.PayInForm.get('States_Ids').setValue(this.Parse(this.rowAr.States_Ids));
		}else{
			this.States_Ar = [];
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	});  
}
	

GetRTO(Type){
	const formData = new FormData();
	formData.append('Type',Type);
	//formData.append('States_Ids',this.ItemSelectionState.join()); 
	formData.append('States_Ids',JSON.stringify(this.PayInForm.value['States_Ids']));
	this.api.HttpPostType('pay/PayoutRMAuthority/RTO_N',formData).then((result) => {
		if(result['Status'] == true){
			this.RTO_Ar = result['Data'];
			//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';
			//this.PayInForm.get('RTO_Ids').setValue(this.Parse(this.rowAr.RTO_Ids));
		}else{
			this.RTO_Ar = [];
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	});  
}

SubProducts(Type){
	const formData = new FormData();
	formData.append('Type',Type);
	formData.append('Product_Ids',JSON.stringify(this.PayInForm.value['Product_Ids']));
	this.api.HttpPostType('pay/PayoutRMAuthority/SubProducts_N',formData).then((result) => {
		
		if(result['Status'] == true){
			this.SubProducts_Ar = result['Data'];
			//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')'; 

			//this.PayInForm.get('SubProduct_Ids').setValue(this.Parse(this.rowAr.SubProduct_Ids));
			
		}else{
			this.SubProducts_Ar = [];
			 this.api.ErrorMsg(result['Message']);
		}
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	}); 
}

Segments(Type){
	const formData = new FormData();
	formData.append('Type',Type);
	formData.append('Product_Ids',JSON.stringify(this.PayInForm.value['Product_Ids']));
	formData.append('SubProduct_Ids',JSON.stringify(this.PayInForm.value['SubProduct_Ids']));
	this.api.HttpPostType('pay/PayoutRMAuthority/Segments_N',formData).then((result) => {
		 
		if(result['Status'] == true){
			this.Segment_Ar = result['Data'];
			//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';
			//this.PayInForm.get('Segment_Ids').setValue(this.Parse(this.rowAr.Segment_Ids));		
		}else{
			this.Segment_Ar = [];
			 this.api.ErrorMsg(result['Message']);
		}
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	}); 
}

Classes(Type){
	const formData = new FormData();
	formData.append('Type',Type);
	formData.append('Product_Ids',JSON.stringify(this.PayInForm.value['Product_Ids']));
	formData.append('SubProduct_Ids',JSON.stringify(this.PayInForm.value['SubProduct_Ids']));
	formData.append('Segment_Ids',JSON.stringify(this.PayInForm.value['Segment_Ids']));
	this.api.HttpPostType('pay/PayoutRMAuthority/Classes_N',formData).then((result) => {
		//
		if(result['Status'] == true){
			this.Classes_Ar = result['Data'];
			//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';  
			//this.PayInForm.get('Class_Ids').setValue(this.Parse(this.rowAr.Class_Ids));		
		}else{
			this.Classes_Ar = [];
			 this.api.ErrorMsg(result['Message']);
		}
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	}); 
}
SubClasses(Type){
	const formData = new FormData();
	formData.append('Type',Type);
	formData.append('Product_Ids',JSON.stringify(this.PayInForm.value['Product_Ids']));
	formData.append('SubProduct_Ids',JSON.stringify(this.PayInForm.value['SubProduct_Ids']));
	formData.append('Segment_Ids',JSON.stringify(this.PayInForm.value['Segment_Ids']));
	formData.append('Class_Ids',JSON.stringify(this.PayInForm.value['Class_Ids']));
	this.api.HttpPostType('pay/PayoutRMAuthority/SubClasses_N',formData).then((result) => {
		//
		if(result['Status'] == true){
			this.Sub_Classes_Ar = result['Data']; 
			//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';
			//this.PayInForm.get('Sub_Class_Ids').setValue(this.Parse(this.rowAr.Sub_Class_Ids));			
		}else{
			this.Sub_Classes_Ar = [];
			 this.api.ErrorMsg(result['Message']);
		}
	}, (err) => { 
	   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	}); 
}

////////////////////
NM_Segements(){
	const formData = new FormData();
	     
		formData.append('LOB',this.rowAr.LOB_Ids[0]);
		  
		this.api.HttpPostType('pay/PayoutRMAuthority/NM_Segements_N',formData).then((result) => {
			this.Segment_Ar = [];
			if(result['Status'] == true){
				this.Segment_Ar = result['Data'];
				//this.PayInForm.get('Segment_Ids').setValue(this.Parse(this.rowAr.Segment_Ids));
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
} 

NM_Class(Type){
		const formData = new FormData();
		formData.append('Type',Type);
		formData.append('LOB',this.rowAr.LOB_Ids[0]);
		formData.append('Segment_Ids',JSON.stringify(this.PayInForm.value['Segment_Ids']));
		
		this.api.HttpPostType('pay/PayoutRMAuthority/NM_Class_N',formData).then((result) => {
			this.Classes_Ar = [];
			if(result['Status'] == true){
				this.Classes_Ar = result['Data'];
				//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';  
				//this.PayInForm.get('Class_Ids').setValue(this.Parse(this.rowAr.Class_Ids));
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
}

NM_Product(Type){
		const formData = new FormData();
		formData.append('Type',Type);
		formData.append('LOB',this.rowAr.LOB_Ids[0]);
		formData.append('Segment_Ids',JSON.stringify(this.PayInForm.value['Segment_Ids']));
		formData.append('Class_Ids',JSON.stringify(this.PayInForm.value['Class_Ids']));
		
		this.api.HttpPostType('pay/PayoutRMAuthority/NM_Product_N',formData).then((result) => {
			this.Products_Ar = [];
			if(result['Status'] == true){
				this.Products_Ar = result['Data'];
				//this.PayInForm.get('Product_Ids').setValue(this.Parse(this.rowAr.Product_Ids));
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
}  

NM_Sub_Product(Type){
		const formData = new FormData();
		formData.append('Type',Type);
		formData.append('LOB',this.rowAr.LOB_Ids[0]);
		formData.append('Segment_Ids',JSON.stringify(this.PayInForm.value['Segment_Ids']));
		formData.append('Class_Ids',JSON.stringify(this.PayInForm.value['Class_Ids']));
		formData.append('Product_Ids',JSON.stringify(this.PayInForm.value['Product_Ids']));
		this.api.HttpPostType('pay/PayoutRMAuthority/NM_Sub_Product_N',formData).then((result) => {
			
			if(result['Status'] == true){
				this.SubProducts_Ar = result['Data'];
				//this.PayInForm.get('SubProduct_Ids').setValue(this.Parse(this.rowAr.SubProduct_Ids));
			}else{
				this.SubProducts_Ar = []; 
				 this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
}  



	
	
	onChangeInput(Type){
		
		var fields = this.PayInForm.value; 
		 
		if(this.api.GetUserData('User_Role') == 'Hod'){
			console.log(this.api.GetUserData('User_Role'));
			
			if(this.rowAr.PayInOD<fields['PayOutOD']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayInTP<fields['PayOutTP']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayInNet<fields['PayOutNet']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayInReward<fields['PayOutReward']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayInScheme<fields['PayOutScheme']){
				this.ButtonType = 'Request';
			}else{
				this.ButtonType = 'Save';
			}
			
		}else{ // RM
		
			if(this.rowAr.PayOutOD<fields['PayOutOD']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayOutTP<fields['PayOutTP']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayOutNet<fields['PayOutNet']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayOutReward<fields['PayOutReward']){
				this.ButtonType = 'Request';
			}else if(this.rowAr.PayOutScheme<fields['PayOutScheme']){
				this.ButtonType = 'Request';
			}else{
				this.ButtonType = 'Save';
			}
			//////
			
			if(this.ButtonType == 'Request'){
				
				//Example
				// Admin Payin = 20
				// Payout-RM-Authority = 18 
				//Payout-RM-Authority enter 18 and less then approved
				//Payout-RM-Authority Enter between 18 to 20 then assign Hod
				//Payout-RM-Authority Enter 20 above then assign Admin
				 
				if(this.rowAr.PayInOD<fields['PayOutOD']){
					this.AssginType = 'Admin';
				}else if(this.rowAr.PayInTP<fields['PayOutTP']){
					this.AssginType = 'Admin';
				}else if(this.rowAr.PayInNet<fields['PayOutNet']){
					this.AssginType = 'Admin';
				}else if(this.rowAr.PayInReward<fields['PayOutReward']){
					this.AssginType = 'Admin';
				}else if(this.rowAr.PayInScheme<fields['PayOutScheme']){
					this.AssginType = 'Admin';
				}else{
					this.AssginType = 'Hod';
				}
 
			}else{
				this.AssginType = '';
			}
				console.log(this.AssginType);
			
			
		}
		
		
	}
	
	onChangeEffective_Date(){
		const Effective_Date_Control = this.PayInForm.get('Effective_Date');
		
	   this.PayInForm.get('Effective_Date').valueChanges
		.subscribe(v => {
			  
			
			if(v!=null){
				
				
				
			   var dateOne = new Date(this.rowAr.Effective_Date_YMD); //Year, Month, Date    
			   var dateTwo = new Date(v); //Year, Month, Date  
				console.log(dateTwo);
			   
			   if (dateOne > dateTwo) {  
					this.Effective_Date_Error = 'Effective Date Can not Less than to be Current Effective Date !';
					Effective_Date_Control.setValue(null);
					//alert("Date One is greater than Date Two.");    
				}else {    
					//alert("Date Two is greater than Date One.");  
					this.Effective_Date_Error = '';		
				}  
				
				/*
				
				var old = new Date(this.rowAr.Effective_Date_YMD);
				var newD = new Date(v);
				
				console.log(old);
				console.log(newD);
			 
				if (newD.getTime() == old.getTime()){
					console.log("equal"); 
					this.Effective_Date_Error = '';
				}else if (newD.getDate() > old.getDate()){ 
					console.log("greater than");
					this.Effective_Date_Error = '';
				}else{
					console.log("lesser then");
					this.Effective_Date_Error = 'Effective Date Can not Less than to be Current Effective Date !';
					Effective_Date_Control.setValue(null);
				} 
				*/
				
			}
			
		});
		
	}
	
	

PayInNet(e){
	var PayInNet = e.target.value;
	const PayOutNet_Control = this.PayInForm.get('PayOutNet');
	console.log(PayInNet);
	console.log(PayInNet*20/100);
	PayOutNet_Control.setValue(PayInNet-(PayInNet*20/100));
}

PayInOD(e){
	var PayInOD = e.target.value;
	const PayOutOD_Control = this.PayInForm.get('PayOutOD');
	console.log(PayInOD);
	console.log(PayInOD*20/100);
	PayOutOD_Control.setValue(PayInOD-(PayInOD*20/100));
}

PayInTP(e){
	var PayInTP = e.target.value;
	const PayOutTP_Control = this.PayInForm.get('PayOutTP');
	console.log(PayInTP);
	console.log(PayInTP*20/100);
	PayOutTP_Control.setValue(PayInTP-(PayInTP*20/100));
}

	
PayInReward(e){
	var PayInReward = e.target.value;
	const PayInRewardNet_Control = this.PayInForm.get('PayOutReward');
	console.log(PayInReward);
	console.log(PayInReward*20/100);
	PayInRewardNet_Control.setValue(PayInReward-(PayInReward*20/100));
}

PayInScheme(e){
	var PayInScheme = e.target.value;
	const PayOutSchemeNet_Control = this.PayInForm.get('PayOutScheme');
	console.log(PayInScheme);
	console.log(PayInScheme*20/100);
	PayOutSchemeNet_Control.setValue(PayInScheme-(PayInScheme*20/100));
}
	
	
	AddPayIn(){
	   
    console.log(this.PayInForm.value);
	 
		
    this.isSubmitted = true;
    if(this.PayInForm.invalid){
      return;
    }else{
		var fields = this.PayInForm.value; 
		
		 
		
		const formData = new FormData();
		
		var LOB_Ids_Ar = [];
	
		if(this.isChecked_Motor==true){
			LOB_Ids_Ar.push('Motor'); 
		}
		if(this.isChecked_Health==true){
			LOB_Ids_Ar.push('Health');
		}
		if(this.isChecked_NonMotor==true){
			LOB_Ids_Ar.push('Non Motor');
		}
		console.log(LOB_Ids_Ar);
		//console.log(LOB_Ids_Ar.join());
   
		formData.append('PayIn_Id',this.Id);
		formData.append('User_Id',this.api.GetUserId());
		
		formData.append('AgentType',this.rowAr.AgentType);
		formData.append('SlabType',this.rowAr.SlabType);
		formData.append('Premium_Slab_Applicable_Type',this.rowAr.Premium_Slab_Applicable_Type);
		formData.append('PayIn_Applicable_Type',this.rowAr.PayIn_Applicable_Type);
		formData.append('PayIn_PremiumSlabFrom',this.rowAr.PayIn_PremiumSlabFrom);
		formData.append('PayIn_PremiumSlabUpTo',this.rowAr.PayIn_PremiumSlabUpTo);
		
		formData.append('Broker_Ids',JSON.stringify(fields['Broker_Ids']));
		//formData.append('RM_Ids',JSON.stringify(fields['RM_Ids']));
		formData.append('Agent_Ids',JSON.stringify(fields['Agent_Ids']));
		formData.append('Ins_Compaines_Ids',JSON.stringify(fields['Ins_Compaines_Ids']));
		
		formData.append('Zone_Ids',JSON.stringify(fields['Zone_Ids']));
		formData.append('States_Ids',JSON.stringify(fields['States_Ids']));
		formData.append('RTO_Ids',JSON.stringify(fields['RTO_Ids']));
		
		formData.append('LOB_Ids',JSON.stringify(LOB_Ids_Ar));
 
		formData.append('Manufacturer_Year',fields['Manufacturer_Year']); 
		
		formData.append('Fuel_Type_Ids',JSON.stringify(fields['Fuel_Type']));
		formData.append('File_Type_Ids',JSON.stringify(fields['File_Type']));
		formData.append('Body_Type_Ids',JSON.stringify(fields['Body_Type_Ids']));
		
		formData.append('Product_Ids',JSON.stringify(fields['Product_Ids']));
		formData.append('SubProduct_Ids',JSON.stringify(fields['SubProduct_Ids']));
		formData.append('Segment_Ids',JSON.stringify(fields['Segment_Ids']));
		formData.append('Class_Ids',JSON.stringify(fields['Class_Ids']));
		formData.append('Sub_Class_Ids',JSON.stringify(fields['Sub_Class_Ids']));
		
		formData.append('Source_Type',JSON.stringify(fields['Source']));
		formData.append('CPA',JSON.stringify(fields['CPA']));
		
		formData.append('NCB_Status',JSON.stringify(fields['NCB_Status']));
		formData.append('VehicleAgeFrom',fields['VehicleAgeFrom']);
		formData.append('VehicleAgeTo',fields['VehicleAgeTo']);
		
		formData.append('HEV',JSON.stringify(fields['HEV'])); 
		
		formData.append('PayInOD',fields['PayInOD']);
		formData.append('PayOutOD',fields['PayOutOD']);
		formData.append('PayInTP',fields['PayInTP']);
		formData.append('PayOutTP',fields['PayOutTP']);
		
		formData.append('PayInNet',fields['PayInNet']);
		formData.append('PayOutNet',fields['PayOutNet']);
		
		formData.append('PayInReward',fields['PayInReward']);
		formData.append('PayOutReward',fields['PayOutReward']);
		formData.append('PayInScheme',fields['PayInScheme']);
		formData.append('PayOutScheme',fields['PayOutScheme']);
		
		formData.append('PayInGroup',JSON.stringify(fields['PayInGroup']));
		 
		formData.append('PayIn_Remark',fields['PayIn_Remark']);
		formData.append('Extra_Remark',fields['Extra_Remark']);
		formData.append('Effective_Date',fields['Effective_Date']);
		 
		
		this.api.IsLoading();
		this.api.HttpPostType('pay/PayIn/AddNewPayIn',formData).then((result) => {
		this.api.HideLoading();
		
			 
			if(result['Status'] == true){
				 
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
				
				//this.router.navigate(['/rm/payout/Payout-RM-Authority']);
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		   this.api.HideLoading();
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
		
		
	}
	
	
   }
	

}
