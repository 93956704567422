import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: "login",
		loadChildren: () =>
			import("./modules/login/login.module").then((m) => m.LoginModule),
	},
	{
		path: "dashboard",
		loadChildren: () =>
			import("./modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
	},
	{
		path: "business-login",
		loadChildren: () =>
			import("./modules/business-login/business-login.module").then((m) => m.BusinessLoginModule),
	},
	{
		path: "finance-lms",
		loadChildren: () =>
			import("./modules/finance-lms/finance-lms.module").then((m) => m.FinanceLmsModule),
	},
	{
		path: "brokerage",
		loadChildren: () =>
			import("./modules/brokerage/brokerage.module").then((m) => m.BrokerageModule),
	},
	{
		path: "reports",
		loadChildren: () =>
			import("./modules/reports/reports.module").then((m) => m.ReportsModule),
	},
	{
		path: "pay-in",
		loadChildren: () =>
			import("./modules/pay-in/pay-in.module").then((m) => m.PayInModule),
	},
	{
		path: "pay-out",
		loadChildren: () =>
			import("./modules/pay-out/pay-out.module").then((m) => m.PayOutModule),
	},
	{ 
		path: "cross-selling",
		loadChildren: () =>
			import("./modules/cross-selling/cross-selling.module").then((m) => m.CrossSellingModule),
	},
	{ 
		path: "partner",
		loadChildren: () =>
			import("./modules/partner/partner.module").then((m) => m.PartnerModule),
	},
	{
		path: "partner-login",
		loadChildren: () =>
			import("./modules/partner-login/partner-login.module").then((m) => m.PartnerLoginModule),
	},  
	
	{ 
		path: "bulk",
		loadChildren: () =>
			import("./modules/bulk-upload/bulk-upload.module").then((m) => m.BulkUploadModule),
	},
	{ 
		path: "other",
		loadChildren: () =>
			import("./modules/other/other.module").then((m) => m.OtherModule),
	},
	{
		path: "franchise",
		loadChildren: () =>
			import("./modules/franchise/franchise.module").then((m) => m.FranchiseModule),
	},
	{
		path : "reconciliation",
		loadChildren:() => import("./modules/reconciliation/reconciliation.module").then((m) => m.ReconciliationModule)
	},
	{
		path : "handling-charge",
		loadChildren:() => import("./modules/handling-charge/handling-charge.module").then((m) => m.HandlingChargeModule)
	},
	{
		path : "pdf-extract",
		loadChildren:() => import("./modules/pdf-extract/pdf-extract.module").then((m) => m.PdfExtractModule)
	}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
