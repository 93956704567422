import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-agent-sr-documents',
  templateUrl: './agent-sr-documents.component.html',
  styleUrls: ['./agent-sr-documents.component.css']
})
export class AgentSrDocumentsComponent implements OnInit {

Id:any;
Agent_Id:any;
Base_Url:any;
row:any=[];
Documents:any;
IsDisabled:any=false;
selectedFiles: File;

  constructor(
	public dialogRef: MatDialogRef<AgentSrDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
  ) { }

  ngOnInit() {
	this.Id = this.data.Id;
	this.GetDocuments();   
  }
  
  CloseModel(): void {
    this.dialogRef.close({ 
      Status: 'Model Close'
    });
  }
  
  GetDocuments(){
	//this.api.IsLoading();
	this.api.Call('sr/EditSR/ViewDocumentsById?Id='+this.Id+'&User_Id='+this.api.GetUserId()).then((result) => {
	//this.api.HideLoading();
	 
		if(result['Status'] == true){
			//this.CloseModel();
			
			this.row = result['Data'];
			this.Documents = result['Documents'];
			
			if(result['IsDisabled']==='true' && this.row.SR_Status == 'Pending'){
				this.IsDisabled = false; 
			}else{
				this.IsDisabled = true;
			} 
			//alert(this.IsDisabled);
			
			this.Base_Url = result['Base_Url']+this.row.Id+'/';

			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  
  ViewDocument(name){
	  let url;
	  if(this.row.Source == 'Web'){
		 url = name;
	  }else{ 
		url = this.Base_Url+name;
	  }
	  console.log(this.row.Source);
	  console.log(url);
	  window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
  
  
  UploadDocs(event,Type) 
	{
		this.selectedFiles = event.target.files[0];
		
		if(event.target.files && event.target.files[0])
		{
		
			console.log(this.selectedFiles);
			console.log(this.selectedFiles.name); 
			var str = this.selectedFiles.name;
			var ar =  str.split(".");
			console.log(ar); 
			var ext;
			for(var i=0; i<ar.length; i++) ext=ar[i].toLowerCase();
			console.log(ext); 
			 
			if(ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'pdf'){
				console.log('Extenstion is vaild !');
				var file_size = event.target.files[0]['size'];
				const Total_Size = Math.round((file_size / 1024)); 
				
				console.log(Total_Size+ ' kb'); 
				 
				if(Total_Size >= 1024*2) { // allow only 2 mb
					this.api.ErrorMsg('File size is greater than 2 mb');

					
					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }

				}else{
					 
					this.Upload(Type);
				}
			}else{
				console.log('Extenstion is not vaild !');
				 
				this.api.ErrorMsg('Please choose vaild file ! Example :- PNG,JPEG,JPG,PDF');

					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }
	 
			}
		
		}
	}
	
  async Upload(Type){
	  const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to upload document?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm){
	
			const formData = new FormData(); 
			
			formData.append('User_Id',this.api.GetUserId());
			formData.append('SR_Id',this.row.Id); 
			formData.append('Document_Type',Type); 
			formData.append('Document',this.selectedFiles); 
			 
		    
			this.api.IsLoading();
			this.api.HttpPostType('sr/EditSR/SR_Document_Update',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){ 
					this.api.ToastMessage(result['Message']); 
				    this.Documents = result['Documents'];
				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			}); 
		}
  }




}
