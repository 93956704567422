import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';
import { Router } from  '@angular/router';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css']
})
export class SessionExpiredComponent implements OnInit {

LoginForm: FormGroup;
isSubmitted  = false;

InputType:string = 'text';

   constructor(
	public dialogRef: MatDialogRef<SessionExpiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	private formBuilder: FormBuilder,
	public api : ApiService,
	private router: Router
  ) { 
	this.LoginForm  =  this.formBuilder.group({
		Password: ['', [Validators.required]]
	});
  }

  ngOnInit() {
	let sesiondata = {
					"Logged_In":"TRUE",
					"SessionExpired_State":"TRUE",
					"Type":this.api.GetUserData('Type'),
					"Emp_Id":this.api.GetUserData('Emp_Id'),
					"Photo":this.api.GetUserData('Photo'),
					"User_Id":this.api.GetUserId(),
					"Email":"",
					"Company_Id":0,
					//"JwtToken" : 'NA',
					}; 
	
	localStorage.setItem('User', JSON.stringify(sesiondata));
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'SessionModelClose'
    });
  }
  
  get FC() { return this.LoginForm.controls; }
	
  Login(){
	 //
	 this.isSubmitted = true;
		if(this.LoginForm.invalid){
		  return;
		}else{
  
			const formData = new FormData(); 
			
			formData.append('Password',this.LoginForm.value['Password']);
			formData.append('User_Id',this.api.GetUserId());
			
			
			this.api.IsLoading();
			this.api.HttpPostType('User/StayLogin',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){
					localStorage.setItem('User', JSON.stringify(result['UserData']));
				    this.api.changeMessage({IsLogged :"TRUE"});
					this.CloseModel(); 
				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			});  
 	 
		}
		
  }
  
   Logout(){
		this.api.IsLoading(); 
		setTimeout(() => {
		  /* spinner ends after 5 seconds */ 
		  localStorage.removeItem('User');
		  this.api.changeMessage({IsLogged :"FALSE"});
		  this.CloseModel();
		  this.api.HideLoading();
		  this.router.navigate(['/login']);
		}, 1000);
	 
   }

	
	
}
