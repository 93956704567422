import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { Router } from  '@angular/router';
import swal from 'sweetalert';

@Component({
  selector: 'app-sales-sr-action',
  templateUrl: './sales-sr-action.component.html',
  styleUrls: ['./sales-sr-action.component.css']
})
export class SalesSrActionComponent implements OnInit {

SRPolicyUpdateUW_OPS_Form: FormGroup;
isSubmitted  = false;

Id:any;
row:any=[];

Payout_Details:any=[];
PayoutMaster:any=[];

User_Rights:any = [];
Remarks:string;

OperationsEmp_Ar:any;
AccountsEmp_Ar:any;

Operations_User_Id:any=0;
Accounts_User_Id:any=0;

Agent_Id:any;
Base_Url:any;
//row:any=[];
Documents:any;
IsDisabled:any=false;
selectedFiles: File;

UseFor_IT_SQL:any;

Salutation_Type:any;


 
  constructor(
	public dialogRef: MatDialogRef<SalesSrActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	public api : ApiService,
	private router: Router, 
	private formBuilder: FormBuilder
  ) { 
	
	this.SRPolicyUpdateUW_OPS_Form  =  this.formBuilder.group({
			Policy_No: ['', [Validators.required]],
			Final_Premium: ['', [Validators.required]],
			Policy_Attachment: ['', [Validators.required]],
	});
  
  }

  ngOnInit() {
	this.Id = this.data.Id;
	this.GetSR();   
	this.Get_Operation_Employee();   
	this.Get_Accounts_Employee();   
  }
  
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }
  
  
  
   get FC() { return this.SRPolicyUpdateUW_OPS_Form.controls; }
  
  
  
  GetSR(){
	//this.api.IsLoading();
	this.api.Call('sr/EditSR/ViewById?Type=Normal&Id='+this.Id+'&User_Id='+this.api.GetUserId() ).then((result) => {
	//this.api.HideLoading();
	
		if(result['Status'] == true){
			//this.CloseModel();
			
			this.row = result['Data'];
			this.User_Rights = result['SR_User_Rights'];
			this.Payout_Details = result['Payout_Details'];
			this.PayoutMaster = result['PayoutMaster'];
			  
			this.Documents = result['Documents'];
			
			if(result['IsDisabled']==='true' && this.row.SR_Status == 'Pending'){
				this.IsDisabled = false; 
			}else{
				this.IsDisabled = true;
			} 
			//alert(this.IsDisabled);
			
			this.Base_Url = result['Base_Url']+this.row.Id+'/';
			
			//alert(this.User_Rights.Underwriter_Motor);
			
			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
		
	}, (err) => { 
	  // Error log
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  
  ViewDocument(name){
	  let url;
	  if(this.row.Source == 'Web'){
		 url = name;
	  }else{ 
		url = this.Base_Url+name;
	  }
	  console.log(this.row.Source);
	  console.log(url);
	  window.open(url, "", "left=100,top=50,width=800%,height=600");
  }
  
  UserForIT(){
	  this.UseFor_IT_SQL = this.row.UseFor_IT;
	  //alert(this.row.UseFor_IT);
	  
	    var textToBeCopied = this.UseFor_IT_SQL;  
		let textarea = null;
		
		textarea = document.createElement("textarea");
		textarea.style.height = "0px";
		textarea.style.left = "-100px";
		textarea.style.opacity = "0";
		textarea.style.position = "fixed";
		textarea.style.top = "-100px";
		textarea.style.width = "0px";
		document.body.appendChild(textarea);
		// Set and select the value (creating an active Selection range).
		textarea.value = textToBeCopied;
		textarea.select();
		// Ask the browser to copy the current selection to the clipboard.
		let successful = document.execCommand("copy");
		if (successful) {
		  // do something
		  this.Totast();
		} else {
		  // handle the error
		}
		if (textarea && textarea.parentNode) {
		  textarea.parentNode.removeChild(textarea);
		} 
  }
  
  Totast(){
	 //this.snackbar_msg = msg;
	 var x = document.getElementById("snackbar2"); 
		x.className = "show";
		setTimeout(() => { x.className = x.className.replace("show", ""); }, 3000); 
  }
  
  UploadDocs(event,Type) 
	{
		this.selectedFiles = event.target.files[0];
		
		if(event.target.files && event.target.files[0])
		{
		
			console.log(this.selectedFiles);
			console.log(this.selectedFiles.name); 
			var str = this.selectedFiles.name;
			var ar =  str.split(".");
			console.log(ar); 
			var ext;
			for(var i=0; i<ar.length; i++) ext=ar[i].toLowerCase();
			console.log(ext); 
			 
			if(ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'pdf'){
				console.log('Extenstion is vaild !');
				var file_size = event.target.files[0]['size'];
				const Total_Size = Math.round((file_size / 1024)); 
				
				console.log(Total_Size+ ' kb'); 
				 
				if(Total_Size >= 1024*2) { // allow only 2 mb
					this.api.ErrorMsg('File size is greater than 2 mb');

					
					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }

				}else{
					 
					this.Upload(Type);
				}
			}else{
				console.log('Extenstion is not vaild !');
				 
				this.api.ErrorMsg('Please choose vaild file ! Example :- PNG,JPEG,JPG,PDF');

					//if(Type == 'PanCard'){ this.StepFinalForm.get('Pan_Card').setValue(''); }
	 
			}
		
		}
	}
	
  async Upload(Type){
	  const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to upload document?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm){
	
			const formData = new FormData(); 
			
			formData.append('User_Id',this.api.GetUserId());
			formData.append('SR_Id',this.row.Id); 
			formData.append('Document_Type',Type); 
			formData.append('Document',this.selectedFiles); 
			 
		    
			this.api.IsLoading();
			this.api.HttpPostType('sr/EditSR/SR_Document_Update',formData).then((result) => {
			this.api.HideLoading(); 
				if(result['Status'] == true){ 
					this.api.ToastMessage(result['Message']); 
				    this.Documents = result['Documents'];
				}else{
					//alert(result['Message']);
					this.api.ErrorMsg(result['Message']); 
				}
			}, (err) => { 
			  this.api.HideLoading();
			  //console.log(err.message);
			  this.api.ErrorMsg(err.message);
			}); 
		}
  }




  
  SRClose_By_UW_And_OPS(){
	  
    console.log(this.SRPolicyUpdateUW_OPS_Form.value);
 	
    this.isSubmitted = true;
    if(this.SRPolicyUpdateUW_OPS_Form.invalid){
      return;
    }else{
		 
		var fields = this.SRPolicyUpdateUW_OPS_Form.value; 
		const formData = new FormData();
		
	}  
	
  }
  
  Get_Operation_Employee(){
	this.api.Call('sr/Sales/Get_Operation_Employee?User_Id='+this.api.GetUserId()).then((result) => {
		if(result['Status'] == true){
			this.OperationsEmp_Ar = result['Data'];
			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
	}, (err) => { 
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  Get_Accounts_Employee(){
	this.api.Call('sr/Sales/Get_Accounts_Employee').then((result) => {
		if(result['Status'] == true){
			this.AccountsEmp_Ar = result['Data'];
			//this.api.ToastMessage(result['Message']); 
		}else{
			this.api.ErrorMsg(result['Message']);
		}
	}, (err) => { 
	  this.api.HideLoading();
	  //console.log(err.message);
	 this.api.ErrorMsg(err.message);
	});
  }
  
  Edit(){
	//alert(this.Id); 
	this.CloseModel();
	this.router.navigate(['/sr/sr-edit/'+this.Id]); 
  }
  
 
  
async ChangeStatusByAccounts(SR_Status){ //Approve,Reject
	  	
		const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to change status this data?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm) { 
 
	   this.api.IsLoading();
		this.api.Call('sr/SRActions/SR_Accounts_To_UW_OR_OPS?SR_Id='+this.Id+'&SR_Status='+SR_Status+'&User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();
			
			//swal("Status!", "Your imaginary data has been status change!", "success");
			if(result['Status'] == true){
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
			}else{
				//alert(result['Message']);
				this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  //alert(err.message);
		  this.api.ErrorMsg(err.message);
		   
	    });
	}
 
  }
  
 async ChangeStatusByOperation(SR_Status){ //Approve,Reject
	  	
		const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to change status this data?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm) { 
 
	   this.api.IsLoading();
		this.api.Call('sr/SRActions/SRAssign_OPS_To_UW?SR_Id='+this.Id+'&SR_Status='+SR_Status+'&User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();

			if(result['Status'] == true){
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
			}else{
				//alert(result['Message']);
				this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  //alert(err.message);
		  this.api.ErrorMsg(err.message);
		   
	    });
	}
 
  }
 
 async ChangeStatusByUW(SR_Status){ //Complete
	  	
		const Is_Confirm = await swal({
		  title: "Are you sure?",
		  text: "Are you sure that you want to change status this data?",
		  icon: "warning",
		  buttons: ["Cancel", "Yes"],
		});
	 
	if (Is_Confirm) { 
 
	   this.api.IsLoading();
		this.api.Call('sr/SRActions/SR_Complete_To_UW?SR_Id='+this.Id+'&SR_Status='+SR_Status+'&User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();
			
			//swal("Status!", "Your imaginary data has been status change!", "success");
			if(result['Status'] == true){
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
			}else{
				//alert(result['Message']);
				this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  //alert(err.message);
		  this.api.ErrorMsg(err.message);
		   
	    });
	}
 
  }
  
  
  SRAssignOperations(){
	 //alert(this.Operations_User_Id);
	if(this.Operations_User_Id != 0){
		
		console.log(this.Remarks);
		
		const formData = new FormData();
		formData.append('SR_Id',this.Id);
		formData.append('Operation_Id',this.Operations_User_Id);
		formData.append('User_Id',this.api.GetUserId());
		formData.append('Remarks',this.Remarks);
			 
		
		this.api.HttpPostType('sr/SRActions/SRAssign_UW_To_Operations',formData).then((result) => {
			if(result['Status'] == true){
				this.api.ToastMessage(result['Message']);
				
				this.CloseModel();
				
			}else{
				this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		  this.api.HideLoading();
		  //console.log(err.message);
		 this.api.ErrorMsg(err.message);
		}); 
	}else{
		this.api.ErrorMsg('Please choose Operations Employee !');
	}	
  }
  
  SRAssignAccounts(){
	 //alert(this.Accounts_User_Id);
	if(this.Accounts_User_Id != 0){
		
		console.log(this.Remarks);
		
		const formData = new FormData();
		formData.append('SR_Id',this.Id);
		formData.append('Accounts_Id',this.Accounts_User_Id);
		formData.append('User_Id',this.api.GetUserId());
		formData.append('Remarks',this.Remarks);
		
		this.api.HttpPostType('sr/SRActions/SRAssign_UW_To_Accounts',formData).then((result) => {
			if(result['Status'] == true){
				this.api.ToastMessage(result['Message']); 
				
				this.CloseModel();
			 
			}else{
				this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		  this.api.HideLoading();
		  //console.log(err.message);
		 this.api.ErrorMsg(err.message);
		}); 
	}else{
		this.api.ErrorMsg('Please choose Accounts Employee !');
	}	
  }
  
  

}
