import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { ApiService } from '../../providers/api.service';

@Component({
  selector: 'app-pos-categorization',
  templateUrl: './pos-categorization.component.html',
  styleUrls: ['./pos-categorization.component.css']
})
export class PosCategorizationComponent implements OnInit {
Id:any;

AddForm: FormGroup;
isSubmitted  = false;

  constructor( public dialogRef: MatDialogRef<PosCategorizationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public api : ApiService, public formBuilder: FormBuilder) {
	this.AddForm  =  this.formBuilder.group({
			Category: [''],
			Remark: [''],
	 });

	 this.Id = this.data.Id;
  }

  ngOnInit(){ }


  //===== CLOSE MODAL =====//
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }


  //===== SUBMIT FORM =====//
  SubmitForm(){

		if(this.AddForm.invalid){
		  return;
		}else{

			const formData = new FormData();

			formData.append('User_Id',this.api.GetUserData('User_Id'));
			formData.append('Id',this.Id);
			formData.append('Category',this.AddForm.value['Category']);
			formData.append('Remark',this.AddForm.value['Remark']);

			this.api.IsLoading();
			this.api.HttpPostType('daily-tracking-circle/AllClubReport/SubmitProspect',formData).then((result) => {
			this.api.HideLoading();

				if(result['Status'] == true){
					this.CloseModel();

					this.api.ToastMessage(result['Message']);
				}else{
					this.api.ErrorMsg(result['Message']);
				}

			}, (err) => {
			  this.api.HideLoading();
			 this.api.ErrorMsg(err.message);
			});

		}
  }


}
