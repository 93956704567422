import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { ApiService } from '../../providers/api.service';
import swal from 'sweetalert';
import { MatDialog } from '@angular/material/dialog';
import { GemsDetailsViewRemarkComponent } from '../../modals/gems-details-view-remark/gems-details-view-remark.component';

@Component({
  selector: 'app-agent-prime-request',
  templateUrl: './agent-prime-request.component.html',
  styleUrls: ['./agent-prime-request.component.css']
})
export class AgentPrimeRequestComponent implements OnInit {

  Agent_Id:any;
  DataAr:any=[]; 

  RemarksRequestPrime:any='';
  ShowErrorRequired: boolean=false; 
  Type: any;

  constructor(
    public dialogRef: MatDialogRef<AgentPrimeRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	private formBuilder: FormBuilder,
	public api : ApiService,
	public dialog: MatDialog
  ) { 
    this.Agent_Id = this.data.Id;
    this.Type=this.data.Type;
  }

  ngOnInit() {
    
     
  }
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }

  SubmitRequests(){


    var Remarks=this.RemarksRequestPrime;
    if(Remarks==''){

      this.ShowErrorRequired=true;
      return false;
    }
    else{
      this.ShowErrorRequired=false;
    }

    const formData = new FormData();
    formData.append('User_Id',this.api.GetUserId());
    formData.append('Remark',Remarks);
    formData.append('Agent_Id',this.Agent_Id);
    formData.append('Type',this.Type);
    

      this.api.IsLoading();

			 this.api.HttpPostType('other/Gems/SubmitPrimeRequest',formData).then((result) => {

	 		  this.api.HideLoading();
				if(result['Status'] == true ){

					 alert(result['Message']);
					 this.CloseModel();

				}
				else{
					alert(result['Message']);
				}

			}, (err) => {
       

			    this.api.HideLoading();
         
			});

  }

    
      
 
  

}
