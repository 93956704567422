import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../providers/api.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AddPayorComponent } from '../add-payor/add-payor.component';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-invoice.component.html',
  styleUrls: ['./generate-invoice.component.css']
})
export class GenerateInvoiceComponent implements OnInit {

  modalForm: FormGroup;
  isSubmitted = false;

  Payors:any=[];

 constructor(public api : ApiService, private route: Router, 
  public dialog: MatDialog,
  private formBuilder: FormBuilder, private http: HttpClient,  
  private dialogRef: MatDialogRef<GenerateInvoiceComponent>, 
  @Inject(MAT_DIALOG_DATA) public data: any) {
    
    this.modalForm = this.formBuilder.group({
      Payor_Type: ['', Validators.required],
      Customer_Type: ['New', Validators.required],
      Part_Name: [],
      Agent_Category:[],
      Part_Address: [],
      Pan_No: [],
      GST_No: [],
      Aadhar_No: [],
      GST_Per: [],
      Amount_With_GST: [],
      Amount_Without_GST : [] 
 
    });

  }

  ngOnInit() {

    this.GetPayors();

  }

  //FORM CONTROLS
  get formControls() { return this.modalForm.controls; }
 
  AddNewPayor(){

    const dialogRef = this.dialog.open(AddPayorComponent, {
      width: '50%',
      height:'60%',
      disableClose : true,
      data: {Id: 0}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.GetPayors();
    });

  }


  GetPayors(){

    const formData = new FormData();
    formData.append('User_Id', this.api.GetUserId());
     
    this.api.IsLoading();
    this.api.HttpPostType('../v2/reports/Invoicing/GetPayors',formData).then((result) => {
      this.api.HideLoading(); 
        if(result['Status'] == true){
             this.Payors = result['Data'];

             this.GetDetails();

        }else{
            this.api.ErrorMsg(result['Message']);
        }

     }, (err) => {
      this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
      this.api.HideLoading();
     });

  }

  //===== GET REJECTION DETAILS =====//
  GetDetails(){

    const formData = new FormData();
    formData.append('User_Id', this.api.GetUserId());
    formData.append('Posting_Id', this.data.Id);
    formData.append('Amount', this.data.Amount);
    //formData.append('Customer_Type', this.modalForm.value.Customer_Type);
    formData.append('Customer_Type', 'New');

    this.api.IsLoading();
    this.api.HttpPostType('../v2/reports/Invoicing/CustomerDetails',formData).then((result) => {
      this.api.HideLoading(); 
        if(result['Status'] == true){
            
          this.modalForm.patchValue(result['Data']);

        }else{
            this.api.ErrorMsg(result['Message']);
        }

     }, (err) => {
      this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
      this.api.HideLoading();
     });

  }


 
   submitForm() {

    this.isSubmitted = true;
    if (this.modalForm.invalid) {
      return;
    } else {
      
      var fields = this.modalForm.value;
      const formData = new FormData();
 
      formData.append('User_Id', this.api.GetUserId());
      formData.append('Posting_Id', this.data.Id);
      
      Object.keys(this.modalForm.controls).forEach(key => {
        formData.append(key,this.modalForm.get(key).value);
      });
      

      this.api.IsLoading();
      this.api.HttpPostType('../v2/reports/Invoicing/Submit', formData).then((result) => {
      this.api.HideLoading();

        if (result['Status'] == true) {

          this.api.Toast('Success', result['Message']);
          this.dialogRef.close();

          window.open(result['InvoiceUrl'], "", "left=100,top=50,width=800%,height=600");

          
        } else {


          const msg = 'msg';
          this.api.Toast('Warning', result['Message']);
        }
      }, (err) => {

        this.api.HideLoading();
        const newLocal = 'Warning';
        this.api.Toast(newLocal, 'Network Error : ' + err.name + '(' + err.statusText + ')');
      });
    }

  }


  //===== CLOSE MODAL =====//
  close() {
      this.dialogRef.close();
  }



}
