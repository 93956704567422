import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup,FormControl,FormArray, Validators } from  '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../providers/api.service';
import { Router,ActivatedRoute } from  '@angular/router';

@Component({
  selector: 'app-payout-rm-authority-view',
  templateUrl: './payout-rm-authority-view.component.html',
  styleUrls: ['./payout-rm-authority-view.component.css']
})
export class PayoutRmAuthorityViewComponent implements OnInit {

  
  
Id:any;
Business_Month:any;

PayInForm:FormGroup;
isSubmitted  = false;

//disabled = false;
//ShowFilter = false;
//limitSelection = false;

dropdownSettings:any={};
LOB_dropdownSettings:any={};
cities:any = [];
selectedItems: any = [];

User_Rights:any = [];
rowAr:any = [];

Brokers_Ar:any = [];
RM_Ar:any = [];
Agents_Ar:any = [];
Agents_Ar_Temp:any = [];
Ins_Companies_Ar:any = [];
States_Ar:any = [];
RTO_Ar:any = [];
LOB_Ar:any = [];

Fuel_Type_Ar:any = [];
File_Type_Ar:any = [];
Body_Type_Ar:any = [];

Products_Ar:any = [];
SubProducts_Ar:any = [];

Segment_Ar:any = [];
Classes_Ar:any = [];
Sub_Classes_Ar:any = [];
 

ItemSelection:any=[];	
ItemSelectionState:any=[];
ItemSelectionProducts:any=[];	
ItemSelectionSubProducts:any=[];	
ItemSelectionSegment:any=[];	
ItemSelectionClass:any=[];	



ItemSelectionNMSegment:any=[];
ItemSelectionNMClass:any=[];
ItemSelectionNMProducts:any=[];	
 
	 

Agents_Placeholder:string = 'Select Agents (0)';

isChecked_Motor: any = false;
isChecked_Health: any = false;
isChecked_NonMotor: any = false;

ButtonType:string='Save';
AssginType:string=''; // Admin,HOD
Effective_Date_Error:string='';
MinEffective_Date:any;

AgentGroupIndex:any=0;

AgentGroupItemSelection:any=[];

selectedFiles: File;
Attachment:File;

  constructor(public api : ApiService,
	public dialogRef: MatDialogRef<PayoutRmAuthorityViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
	private router: Router,private activatedRoute: ActivatedRoute,
  private fb: FormBuilder) { }

  ngOnInit() {
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'Id',
			textField: 'Name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			allowSearchFilter: true
		};
		this.LOB_dropdownSettings = {
			singleSelection: false,
			enableCheckAll: false,
			idField: 'Id',
			textField: 'Name',
		};
		
		
	this.Id = this.data.Id;
	this.Business_Month = this.data.Business_Month;
	//this.Id = this.activatedRoute.snapshot.paramMap.get('Id');
	console.log(this.Id);
	
	this.GetRow(); 
	
	this.PayInForm = this.fb.group({
		//city: [this.selectedItems]
		Broker_Ids: ['',[Validators.required]],
		//RM_Ids: ['',[Validators.required]],
		//Agent_Ids: ['',[Validators.required]],
		Ins_Compaines_Ids: ['',[Validators.required]],
		States_Ids: [''],
		RTO_Ids: [''],
		Manufacturer_Year: [{value: '',disabled: true},],
		
		Fuel_Type: [''],
		File_Type: [''],
		Body_Type: [''],
		
		Product_Ids: ['',[Validators.required]],
		SubProduct_Ids: ['',[Validators.required]],
		Segment_Ids: ['',[Validators.required]],
		Class_Ids: ['',[Validators.required]],
		Sub_Class_Ids: [''],
		
		//PayInOD: [''],
		//PayOutOD: [''],
		//PayInTP: [''],
		//PayOutTP: [''],
		
		//PayInNet: [''],
		//PayOutNet: [''],
		
		//PayInReward: [''],
		//PayOutReward: [''],
		//PayInScheme: [''],
		//PayOutScheme: [''],
		
		Effective_Date: ['',[Validators.required]],
		Remark: ['',[Validators.required]],
		
		AgentGroup: this.fb.array([]) ,
		
	}); 
	
	//this.onChangeEffective_Date();
	 
  }
  
  get FC() { return this.PayInForm.controls; }
  
  
  AgentGroup() : FormArray {
    return this.PayInForm.get("AgentGroup") as FormArray
  }
  
  removeNewAgentGroup(i:number) {
    this.AgentGroup().removeAt(i);
  }
   
  NewAgentGroup(): FormGroup {
    return this.fb.group({
      AgentGroup_Ids: [''], 
      PayOutOD: {value: this.rowAr.PayOutOD, disabled: (this.rowAr.PayOutOD==0)?true:false},
      PayOutTP: {value: this.rowAr.PayOutTP, disabled: (this.rowAr.PayOutTP==0)?true:false},
      PayOutNet: {value: this.rowAr.PayOutNet, disabled: (this.rowAr.PayOutNet==0)?true:false},
      PayOutReward: {value: this.rowAr.PayOutReward, disabled: (this.rowAr.PayOutReward==0)?true:false},
      PayOutScheme: {value: this.rowAr.PayOutScheme, disabled: (this.rowAr.PayOutScheme==0)?true:false},
	  AttachmentSource: [],
	  Attachment_Status: ['0']
    })
  }
  
  addAgentGroup(index) {
	  
		this.AgentGroupIndex++;
		console.log(this.AgentGroupIndex);
		
		console.log(this.PayInForm.get("AgentGroup").value);
		
		if(this.PayInForm.get("AgentGroup").value.length>0){
			//this.AgentGroup().push(this.NewAgentGroup());
			
			 
			const formData = new FormData();
		 
			formData.append('PayIn_Id',this.Id);
			formData.append('User_Id',this.api.GetUserId());
			formData.append('AgentGroup',JSON.stringify(this.PayInForm.get("AgentGroup").value));
			formData.append('Agents_Ar_Temp',JSON.stringify(this.Agents_Ar_Temp));
			 
			
			this.api.IsLoading();
			this.api.HttpPostType('pay/PayoutRMAuthority/AddAgentGroup',formData).then((result) => {
			this.api.HideLoading();
			  
				if(result['Status'] == true){
					this.Agents_Ar = result['Agents_Ar'];
					this.AgentGroup().push(this.NewAgentGroup());
				}else{
					 this.api.ErrorMsg(result['Message']);
				}
				 
				
			}, (err) => { 
			   this.api.HideLoading();
			   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
			}); 
			 
			 
			
		}else{
			this.AgentGroup().push(this.NewAgentGroup());
		}
  }
   
  
  
  CloseModel(): void { 
    this.dialogRef.close({ 
      Status: 'Model Close'
    });
  }
  
  
  Parse(j){return JSON.parse(j);}
  
  GetRow(){
	   this.api.IsLoading();
		this.api.Call('pay/PayoutRMAuthority/View?Id='+this.Id+'&User_Id='+this.api.GetUserId()).then((result) => {
		this.api.HideLoading();
		
			if(result['Status'] == true){
				var row = result['Data'];
				this.rowAr = row;
				console.log(row);
				//console.log(JSON.parse(row.Product_Ids));
				
				this.Brokers_Ar = this.Parse(row.Broker_Ids);
				this.Ins_Companies_Ar = this.Parse(row.Ins_Compaines_Ids);
				this.Agents_Ar = this.Parse(row.Agent_Ids);
				this.Agents_Ar_Temp = this.Parse(row.Agent_Ids);
				this.States_Ar = this.Parse(row.States_Ids);
				this.RTO_Ar = this.Parse(row.RTO_Ids);
				 
				this.Fuel_Type_Ar = this.Parse(row.Fuel_Type);
				this.File_Type_Ar = this.Parse(row.File_Type_Ids);
				this.Body_Type_Ar = this.Parse(row.Body_Type); 
				
				this.Products_Ar = this.Parse(row.Product_Ids);
				this.SubProducts_Ar = this.Parse(row.SubProduct_Ids);
				this.Segment_Ar = this.Parse(row.Segment_Ids);
				this.Classes_Ar = this.Parse(row.Class_Ids);
				this.Sub_Classes_Ar = this.Parse(row.Sub_Class_Ids);
				
				var LOB = this.Parse(row.LOB_Ids);
				console.log(LOB);
				
				this.addAgentGroup(0);
				
				for(var i=0; i<LOB.length; i++){
					if(LOB[i]=='Motor'){
						this.isChecked_Motor = true;
					}
					if(LOB[i]=='Health'){
						this.isChecked_Health = true;
					}
					if(LOB[i]=='Non Motor'){
						this.isChecked_NonMotor = true;
					}
				}
				
				
				const f = this.PayInForm;
				
				f.get('Broker_Ids').setValue(this.Parse(row.Broker_Ids));
				f.get('Ins_Compaines_Ids').setValue(this.Parse(row.Ins_Compaines_Ids));
				//f.get('Agent_Ids').setValue(this.Parse(row.Agent_Ids));
				f.get('States_Ids').setValue(this.Parse(row.States_Ids));
				f.get('RTO_Ids').setValue(this.Parse(row.RTO_Ids)); 
				f.get('Manufacturer_Year').setValue(row.Manufacturer_Year); 
				
				f.get('Fuel_Type').setValue(this.Parse(row.Fuel_Type));
				f.get('File_Type').setValue(this.Parse(row.File_Type_Ids));
				f.get('Body_Type').setValue(this.Parse(row.Body_Type));
				f.get('Product_Ids').setValue(this.Parse(row.Product_Ids));
				f.get('SubProduct_Ids').setValue(this.Parse(row.SubProduct_Ids));
				f.get('Segment_Ids').setValue(this.Parse(row.Segment_Ids));
				f.get('Class_Ids').setValue(this.Parse(row.Class_Ids));
				f.get('Sub_Class_Ids').setValue(this.Parse(row.Sub_Class_Ids));
				
				
				//f.get('PayInOD').setValue(row.PayInOD);
				//f.get('PayOutOD').setValue(row.PayOutOD);
				//f.get('PayInTP').setValue(row.PayInTP);
				//f.get('PayOutTP').setValue(row.PayOutTP);
				
				//f.get('PayInNet').setValue(row.PayInNet);
				//f.get('PayOutNet').setValue(row.PayOutNet);
				
				//f.get('PayInReward').setValue(row.PayInReward);
				//f.get('PayOutReward').setValue(row.PayOutReward);
				//f.get('PayInScheme').setValue(row.PayInScheme);
				//f.get('PayOutScheme').setValue(row.PayOutScheme);
				
				if(row.PayOutOD==0){
					//f.get('PayOutOD').disable();
					//f.get('PayOutOD').setValue('');
				}else{
					//f.get('PayOutOD').setValidators([Validators.required]);
				}
				
				if(row.PayOutTP==0){
					//f.get('PayOutTP').disable();
					//f.get('PayOutTP').setValue('');
				}else{
					//f.get('PayOutTP').setValidators([Validators.required]);
				}
				
				if(row.PayOutNet==0){
					//f.get('PayOutNet').disable();
					//f.get('PayOutNet').setValue('');
				}else{
					//f.get('PayOutNet').setValidators([Validators.required]);
				}
				
				if(row.PayOutReward==0){
					//f.get('PayOutReward').disable();
					//f.get('PayOutReward').setValue('');
				}else{
					//f.get('PayOutReward').setValidators([Validators.required]);
				}
				
				if(row.PayOutScheme==0){
					//f.get('PayOutScheme').disable();
					//f.get('PayOutScheme').setValue('');
				}else{
					//f.get('PayOutScheme').setValidators([Validators.required]);
				}
				
				f.get('Effective_Date').setValue(row.Effective_Date);
				this.MinEffective_Date = new Date(row.Effective_Date_YMD);
			
				 
			}else{
				alert(result['Message']);
			}
			
		}, (err) => { 
		  // Error log
		  this.api.HideLoading();
		  //console.log(err.message);
		  alert(err.message);
	    });
    }
	
	
	 
  onItemSelect(item: any,Type:any) {
	console.log('Type : '+ Type);
	console.log('onItemSelect', item);
	
	if(Type == 'State'){
		this.ItemSelectionState.push(item.Id); 
		console.log(this.ItemSelectionState);
		this.GetRTO('OneByOneSelect');
		
	}
	
	 
	
	
	 
  }
  
  onItemDeSelect(item: any,Type:any){
		
		console.log('Type : '+ Type);
		console.log('onDeSelect', item);
		
		
		if(Type == 'State'){
			var index = this.ItemSelectionState.indexOf(item.Id);
			if (index > -1) {
			   this.ItemSelectionState.splice(index, 1); 
			}
			//this.ItemSelectionState.push(item.Id); 
			console.log(this.ItemSelectionState);
			this.GetRTO('OneByOneDeSelect');
			
		}
		
	}
	onItemSelectAgentGroup(item: any,Type:any,index:any){ 
		this.AgentGroupIndex = index;
		console.log('Index : '+ index);
		console.log('Type : '+ Type);
		console.log('onItemSelect', item);

		var Agent_Id = item.Id;

		const PayInGroupFields = (<FormArray>this.PayInForm.get("AgentGroup")).at(index);
		//console.log(PayInGroupFields); 

		console.log(PayInGroupFields.value['AgentGroup_Ids']); 
		console.log(PayInGroupFields.value['AgentGroup_Ids'].length);
		
		 

		if(PayInGroupFields.value['AgentGroup_Ids'].length == 1){
			this.onChangeRMA(Agent_Id,PayInGroupFields,'Single');
		}else{
			this.onChangeRMA(Agent_Id,PayInGroupFields,'Multiple');
		}  
		
		
		this.AgentGroupItemSelection.push({Id: item.Id,Name : item.Name});
		console.log(this.AgentGroupItemSelection);
	}


	onChangeRMA(Agent_Id,PayInGroupFields,SelectionType){
		 
		const formData = new FormData();
		//formData.append('RMA',RMA);
		formData.append('Agent_Id',Agent_Id);
		formData.append('SelectionType',SelectionType); 
		formData.append('Business_Month',this.Business_Month);
		formData.append('PayIn_Id',this.Id);
		formData.append('AgentType',this.rowAr.AgentType);
		formData.append('User_Id',this.api.GetUserId());
		
		this.api.IsLoading();
		this.api.HttpPostType('pay/PayIn/GetAgentBusiness',formData).then((result) => {
		this.api.HideLoading();
			if(result['Status'] == true){
				//this.Agents_Ar = result['Data'];
	
				//alert(RMA);
				var RMA = result['RMA'];
			 
				if(this.rowAr.PayInOD!=0){ 

					this.rowAr.PayOutOD = (this.rowAr.PayInOD-(this.rowAr.PayInOD*RMA/100)); 

					PayInGroupFields.patchValue({
						PayOutOD: this.rowAr.PayOutOD
					}); 

				}
				if(this.rowAr.PayInTP!=0){ 
					this.rowAr.PayOutTP = (this.rowAr.PayInTP-(this.rowAr.PayInTP*RMA/100)); 
					PayInGroupFields.patchValue({
						PayOutTP: this.rowAr.PayOutTP
					}); 

				}
				if(this.rowAr.PayInNet!=0){ 
					this.rowAr.PayOutNet = (this.rowAr.PayInNet-(this.rowAr.PayInNet*RMA/100)); 
					PayInGroupFields.patchValue({
						PayOutNet: this.rowAr.PayOutNet
					}); 
				}
				if(this.rowAr.PayInReward!=0){ 
					this.rowAr.PayOutReward = (this.rowAr.PayInReward-(this.rowAr.PayInReward*RMA/100)); 
					PayInGroupFields.patchValue({
						PayOutReward: this.rowAr.PayOutReward
					}); 
				}
				if(this.rowAr.PayInScheme!=0){ 
					this.rowAr.PayOutScheme = (this.rowAr.PayInScheme-(this.rowAr.PayInScheme*RMA/100)); 
					PayInGroupFields.patchValue({
						PayOutScheme: this.rowAr.PayOutScheme
					}); 
				}
				  
				//(<FormArray>this.PayInForm.get("AgentGroup")).clear(); 
	
				//this.AgentGroup().push(this.NewAgentGroup());
	
			}else{
				//this.Agents_Ar = [];
				this.api.ErrorMsg(result['Message']); 
	
				//(<FormArray>this.PayInForm.get("AgentGroup")).clear(); 
	
				//this.AgentGroup().push(this.NewAgentGroup());
			}
			
		}, (err) => { 
			this.api.HideLoading();
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
		}); 
	
	}

	
   
	
	onSelectAll(items: any,Type:any) {
		console.log('Type : '+ Type);
		console.log('onSelectAll', items);
		if(Type == 'RM'){
			//this.GetAgents();
		}
		if(Type == 'State'){
			this.GetRTO('SelectAll');
		}
		
		if(Type == 'Product'){
			//this.SubProducts('SelectAll');
		}
		if(Type == 'SubProduct'){
			//this.Segments('SelectAll');
		}
		if(Type == 'Segment'){
			//this.Classes('SelectAll');
		}
		if(Type == 'Class'){
			//this.SubClasses('SelectAll');
		}
		if(Type == 'NM_Class'){
			//this.ItemSelectionNMSegment = [];
			//this.NM_Class('SelectAll');
			
		}
		if(Type == 'NM_Product'){
			//this.ItemSelectionNMClass = [];
			//this.NM_Product('SelectAll');
			
		}
		if(Type == 'NM_Sub_Product'){
			//this.ItemSelectionNMProducts = [];
			//this.NM_Sub_Product('SelectAll');
			
		}
		
		
		
		
	  
		
	}
	onDeSelectAll(items: any,Type:any) {
		console.log('Type : '+ Type);
		console.log('onDeSelectAll');
		if(Type == 'RM'){
			//this.Agents_Ar = [];
			//this.Agents_Placeholder = 'Select Agents (0)';
		}
		
		if(Type == 'State'){
			this.ItemSelectionState = [];
			this.GetRTO('DeSelectAll');
		}
	
		if(Type == 'Product'){
			//this.SubProducts('DeSelectAll');
		}
		if(Type == 'SubProduct'){
			//this.Segments('DeSelectAll');
		}
		if(Type == 'Segment'){
			//this.Segments('DeSelectAll');
		}
		if(Type == 'Class'){
			//this.SubClasses('DeSelectAll');
		}
		if(Type == 'NM_Class'){
			//this.ItemSelectionNMSegment = [];
			//this.NM_Class('DeSelectAll');
			
		}
		if(Type == 'NM_Product'){
			//this.ItemSelectionNMClass = [];
			//this.NM_Product('DeSelectAll');
			
		}
		if(Type == 'NM_Sub_Product'){
			//this.ItemSelectionNMProducts = [];
			//this.NM_Sub_Product('DeSelectAll');
			
		}
		 
	}
	
	
	GetRTO(Type){
		this.RTO_Ar = [];
		this.PayInForm.get('RTO_Ids').setValue(null);
		
	    const formData = new FormData();
		formData.append('Type',Type);
		formData.append('States_Ids',this.ItemSelectionState.join());
		this.api.HttpPostType('pay/PayIn/RTO',formData).then((result) => {
			if(result['Status'] == true){
				 
				this.RTO_Ar = result['Data'];
				//alert(this.RTO_Ar.length);
				//this.Agents_Placeholder = 'Select Agents ('+this.Agents_Ar.length+')';
			}else{
				this.RTO_Ar = [];
				this.api.ErrorMsg(result['Message']);
			}
			
		}, (err) => { 
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    });  
   }
   
	
	onChangeInput(e,Type,index){ 
	var InputValue = e.target.value;
	var fields = this.PayInForm.value; 
		
	const PayInGroupFields = (<FormArray>this.PayInForm.get("AgentGroup")).at(index);
	 
	//console.log(PayInGroupFields);
	console.log(PayInGroupFields.value['PayOutOD']);
	
	var PayOutOD = PayInGroupFields.value['PayOutOD'];
	var PayOutTP = PayInGroupFields.value['PayOutTP'];
	var PayOutNet = PayInGroupFields.value['PayOutNet'];
	var PayOutReward = PayInGroupFields.value['PayOutReward'];
	var PayOutScheme = PayInGroupFields.value['PayOutScheme'];
	//var Attachment_Status = PayInGroupFields.value['Attachment_Status'];
	
	//console.log(InputValue);
	
	if(this.api.GetUserData('User_Role') != 'Management'){ //Allow HOD,RM
		var flag=0;
		
		if(this.rowAr.PayInODAfterTDS<PayOutOD){
			console.log('PayOutOD : attachment need');
			flag=1;
		}else if(this.rowAr.PayInTPAfterTDS<PayOutTP){
			console.log('PayOutTP : attachment need');
			flag=1;
		}else if(this.rowAr.PayInNetAfterTDS<PayOutNet){
			console.log('PayOutNet : attachment need');
			flag=1;
		}else if(this.rowAr.PayInRewardAfterTDS<PayOutReward){
			console.log('PayOutReward : attachment need');
			flag=1;
		}else if(this.rowAr.PayInSchemeAfterTDS<PayOutScheme){
			console.log('PayOutScheme : attachment need');
			flag=1;
		}else{
			flag=0;
		}
			
		 
		if(flag==1){
			document.getElementById("AttachmentDiv_"+index).style.display='block';
			PayInGroupFields.get('Attachment_Status').setValue('1');
		}else{
			document.getElementById("AttachmentDiv_"+index).style.display='none';
			PayInGroupFields.get('Attachment_Status').setValue('0');
		}
		
	}
 
		
	}
	
	onChangeAttachment(event,index) 
	{
		const PayInGroupFields = (<FormArray>this.PayInForm.get("AgentGroup")).at(index);
		 
		this.selectedFiles = event.target.files[0];
		if(event.target.files && event.target.files[0])
		{
		
			console.log(this.selectedFiles);
			console.log(this.selectedFiles.name); 
			var str = this.selectedFiles.name;
			var ar =  str.split(".");
			console.log(ar); 
			var ext;
			for(var i=0; i<ar.length; i++) ext=ar[i].toLowerCase();
			console.log(ext); 
			
			if(ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'pdf'){
				console.log('Extenstion is vaild !');
				var file_size = event.target.files[0]['size'];
				const Total_Size = Math.round((file_size / 1024)); 
				
				console.log(Total_Size+ ' kb'); 
				 
				if(Total_Size >= 1024*3) { // allow only 2 mb
					this.api.ErrorMsg('File size is greater than 3 mb');
					PayInGroupFields.get('Attachment_Status').setValue('1');
				}else{
					//alert('hello');
					 //PayInGroupFields.patchValue({
						//AttachmentSource: JSON.stringify(this.selectedFiles),
						//Attachment_Status : '2'
					  //});
					  //JSON.stringify(this.selectedFiles)
					PayInGroupFields.get('Attachment_Status').setValue('2');
					
				}
			}else{
				console.log('Extenstion is not vaild !');
				 
				this.api.ErrorMsg('Please choose vaild file ! Example :- PNG,JPEG,JPG,PDF');
				PayInGroupFields.get('Attachment_Status').setValue('1');
				 
			}
		
		}
	}
	
	onChangeEffective_Date(){
		const Effective_Date_Control = this.PayInForm.get('Effective_Date');
		
	   this.PayInForm.get('Effective_Date').valueChanges
		.subscribe(v => {
			  
			
			if(v!=null){
				
				
				
			   var dateOne = new Date(this.rowAr.Effective_Date_YMD); //Year, Month, Date    
			   var dateTwo = new Date(v); //Year, Month, Date  
				console.log(dateTwo);
			   
			   if (dateOne > dateTwo) {  
					this.Effective_Date_Error = 'Effective Date Can not Less than to be Current Effective Date !';
					Effective_Date_Control.setValue(null);
					//alert("Date One is greater than Date Two.");    
				}else {    
					//alert("Date Two is greater than Date One.");  
					this.Effective_Date_Error = '';		
				}  
				
				/*
				
				var old = new Date(this.rowAr.Effective_Date_YMD);
				var newD = new Date(v);
				
				console.log(old);
				console.log(newD);
			 
				if (newD.getTime() == old.getTime()){
					console.log("equal"); 
					this.Effective_Date_Error = '';
				}else if (newD.getDate() > old.getDate()){ 
					console.log("greater than");
					this.Effective_Date_Error = '';
				}else{
					console.log("lesser then");
					this.Effective_Date_Error = 'Effective Date Can not Less than to be Current Effective Date !';
					Effective_Date_Control.setValue(null);
				} 
				*/
				
			}
			
		});
		
	}
	
	
	AddPayIn(){  
	   
    console.log(this.PayInForm.value['AgentGroup'].length);
	
	for(var i=0; i<this.PayInForm.value['AgentGroup'].length; i++){
		
		if(this.PayInForm.value['AgentGroup'][i]['AgentGroup_Ids']==''){
			alert('Please select agents.');
			return true;
		}
		
		if(this.PayInForm.value['AgentGroup'][i]['Attachment_Status']=='1'){
			alert('Attachment Required !');
			return true;
		}
		
	 
	}
	
	 
	
    console.log(this.PayInForm.value);
	 
		
    this.isSubmitted = true;
    if(this.PayInForm.invalid){
      return;
    }else{
		var fields = this.PayInForm.value; 
		
		 
		
		const formData = new FormData();
		
		var LOB_Ids_Ar = [];
	
		if(this.isChecked_Motor==true){
			LOB_Ids_Ar.push('Motor'); 
		}
		if(this.isChecked_Health==true){
			LOB_Ids_Ar.push('Health');
		}
		if(this.isChecked_NonMotor==true){
			LOB_Ids_Ar.push('Non Motor');
		}
		console.log(LOB_Ids_Ar);
		//console.log(LOB_Ids_Ar.join());
		   
		formData.append('Business_Month',this.Business_Month);
		
		formData.append('PayIn_Id',this.Id);
		formData.append('User_Id',this.api.GetUserId());
		formData.append('User_Role',this.api.GetUserData('User_Role'));
		formData.append('Broker_Ids',JSON.stringify(fields['Broker_Ids']));
		//formData.append('RM_Ids',JSON.stringify(fields['RM_Ids']));
		//formData.append('Agent_Ids',JSON.stringify(fields['Agent_Ids']));
		formData.append('Ins_Compaines_Ids',JSON.stringify(fields['Ins_Compaines_Ids']));
		formData.append('States_Ids',JSON.stringify(fields['States_Ids']));
		formData.append('RTO_Ids',JSON.stringify(fields['RTO_Ids']));
		
		formData.append('LOB_Ids',JSON.stringify(LOB_Ids_Ar));
		
		formData.append('Fuel_Type_Ids',JSON.stringify(fields['Fuel_Type']));
		formData.append('File_Type_Ids',JSON.stringify(fields['File_Type']));
		formData.append('Body_Type',JSON.stringify(fields['Body_Type']));
		
		formData.append('Product_Ids',JSON.stringify(fields['Product_Ids']));
		formData.append('SubProduct_Ids',JSON.stringify(fields['SubProduct_Ids']));
		formData.append('Segment_Ids',JSON.stringify(fields['Segment_Ids']));
		formData.append('Class_Ids',JSON.stringify(fields['Class_Ids']));
		formData.append('Sub_Class_Ids',JSON.stringify(fields['Sub_Class_Ids']));
		formData.append('AgentGroup_Ar',JSON.stringify(fields['AgentGroup']));
		 
		//formData.append('PayInOD',fields['PayInOD']);
		//formData.append('PayOutOD',fields['PayOutOD']);
		//formData.append('PayInTP',fields['PayInTP']);
		//formData.append('PayOutTP',fields['PayOutTP']);
		
		//formData.append('PayInNet',fields['PayInNet']);
		//formData.append('PayOutNet',fields['PayOutNet']);
		
		//formData.append('PayInReward',fields['PayInReward']);
		//formData.append('PayOutReward',fields['PayOutReward']);
		//formData.append('PayInScheme',fields['PayInScheme']);
		//formData.append('PayOutScheme',fields['PayOutScheme']);
		
		
		formData.append('PayInOD',this.rowAr.PayInOD);
		formData.append('PayInTP',this.rowAr.PayInTP);
		formData.append('PayInNet',this.rowAr.PayInNet);
		formData.append('PayInReward',this.rowAr.PayInReward);
		formData.append('PayInScheme',this.rowAr.PayInScheme);
		 
		for(var i=0; i<this.PayInForm.value['AgentGroup'].length; i++){
		
			if(this.PayInForm.value['AgentGroup'][i]['Attachment_Status']=='2'){
				const selectedFileList = (<HTMLInputElement>document.getElementById('Attachment_'+ i)).files;
				const file = selectedFileList.item(0);
				//console.log(file);
				formData.append('Attachment_'+i,file);
			}
		}
		
		formData.append('Effective_Date',fields['Effective_Date']);
		formData.append('Remark',fields['Remark']);
		 
		
		this.api.IsLoading();
		this.api.HttpPostType('pay/PayoutRMAuthority/SaveAndRequestAgentGroupWise',formData).then((result) => {
		this.api.HideLoading();
		
			 
			if(result['Status'] == true){
				 
				this.api.ToastMessage(result['Message']);
				this.CloseModel();
				
				//this.router.navigate(['/rm/payout/Payout-RM-Authority']);
			}else{
				 this.api.ErrorMsg(result['Message']);
			}
		}, (err) => { 
		   this.api.HideLoading();
		   this.api.ErrorMsg('Network Error, Please try again ! '+ err.message);
	    }); 
		
		
	}
	
	
   }
	
	
	

}
