import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../providers/api.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-employee-rights',
	templateUrl: './employee-rights.component.html',
	styleUrls: ['./employee-rights.component.css']
})
export class EmployeeRightsComponent implements OnInit {


	AddForm: FormGroup;
	isSubmitted = false;

	Id: any;
	Agent_Id: any;
	row: any = [];

	Is_SR_Creation: any = false;
	Is_Operation: any = false;
	Is_Sales_Support: any = false;
	Is_Accounts: any = false;
	Is_Sales: any = false;
	Underwriter_Motor: any = false;
	Underwriter_Non_Motor: any = false;
	Underwriter_Health: any = false;
	Underwriter_Life: any = false;
	Underwriter_Travel: any = false;
	Underwriter_PA: any = false;
	Is_Finance_Revenue_Figure_View: any = false;
	Is_UAT: any = false;

	Finance_Coordinator: any = false;
	Is_FC_Type: any = false;
	Finance_Manager: any = false;
	Finance_Telecaller: any = false;
	Is_Finance_Reconciler: any = false;
	Is_Finance_SR_Report: any = false;
	Is_Finance_SR_Report_View: any = false;
	Is_Finance_SR_Excel_Export: any = false;

	Is_AddInsuranceBranch: any = false;
	Is_Add_MakeModel: any = false;
	Is_AddBank: any = false;
	Is_SR_Edit: any = false;
	Is_POS_Club: any = false;
	Is_POS_Login: any = false;

	CutCopyPaste: any = false;

	Is_Cross_Sell: any = false;
	Is_Cross_Sell_Report_View: any = false;

	Is_Cross_Sell_Report: any = false;
	Is_Cross_Sell_Report_View_2: any = false;
	Is_Cross_Sell_Excel_Report: any = false;

	Is_POSCategorization_Report: any = false;
	Is_POSCategorization_Report_View: any = false;
	Is_POSCategorization_Excel_Report: any = false;

	Is_SP_POS_Conversion_Report: any = false;
	Is_SP_POS_Conversion_Report_View: any = false;
	Is_SP_POS_Conversion_Excel_Report: any = false;

	Is_Agent_Report: any = false;
	Is_Agent_Report_View: any = false;
	Is_Agent_Report_Excel_Export: any = false;
	Agent_QC: any = false;
	SPC_QC: any = false;
	Fleet_QC: any = false;
	Edit_Agent: any = false;
	Is_Merging: any = false;

	Is_SR_Report: any = false;
	Is_SR_Report_Excel_Export: any = false;

	Is_SR_Report_Motor: any = false;
	Is_SR_Report_View_Motor: any = false;

	Is_SR_Report_Health: any = false;
	Is_SR_Report_View_Health: any = false;

	Is_SR_Report_NonMotor: any = false;
	Is_SR_Report_View_NonMotor: any = false;

	Is_SR_Report_Travel: any = false;
	Is_SR_Report_View_Travel: any = false;

	Is_SR_Report_PA: any = false;
	Is_SR_Report_View_PA: any = false;

	Is_SR_Report_Life: any = false;
	Is_SR_Report_View_Life: any = false;


	POS_ORC_Report: any = false;
	ORC_QC: any = false;
	ORC_Banking_QC: any = false;
	POS_ORC_Excel_Export: any = false;

	Is_Survey: any = false;
	Is_Survey_Report_View: any = false;
	Is_Survey_Create: any = false;
	Is_Survey_Action: any = false;
	Is_Survey_Excel_Export: any = false;

	Is_Agent_RM_Mapping: any = false;
	Is_Agent_Mapping_QC_Type: any = false;
	Is_Agent_Status_Action: any = false;

	//Is_LI_SR_Report:any = false;
	//Is_LI_SR_Report_View:any = false;
	//Is_LI_SR_Excel_Export:any = false;

	Is_Brokerage: any = false;
	Is_Bussiness_Report: any = false;
	Is_BBR_Report: any = false;
	Is_PO_Requests_Report: any = false;
	Is_Files_PO_Request: any = false;
	Is_Recovery: any = false;
	Is_PayIn: any = false;
	Is_PayIn_Request: any = false;
	Is_Payout_Request: any = false;
	Is_Payout_Posting: any = false;
	Is_PO_Statment: any = false;
	Is_Agent_TDS: any = false;
	Is_Pivot_Report: any = false;
	Is_Reconciliation_Report: any = false;

	Is_Employee: any = false;
	Is_Employee_View: any = false;
	Is_Employee_Add: any = false;
	Is_Employee_Edit: any = false;
	Is_Employee_Rights: any = false;

	Sales_PE: any = false;
	Sales_PE_Report_View: any = false;

	Is_Cross_Selling: any = false;

	Is_RM_Tracking_Report: any = false;
	Is_RM_Tracking_Report_View: any = false;

	Is_Manager_Tracking_Report: any = false;
	Is_Manager_Tracking_Report_View: any = false;

	Is_Daily_Tracker: any = false;
	Is_Daily_Tracker_View: any = false;

	Is_Daily_Tracking_Circle: any = false;
	Is_Circle_Non_Motor_View: any = false;
	Is_Circle_Health_View: any = false;
	Is_Circle_Life_View: any = false;
	Is_Circle_Mutual_Fund_View: any = false;
	Is_Circle_Finance_View: any = false;

	Is_64VB_Report: any = false;
	Is_64VB_Report_View: any = false;
	Is_64VB_Report_Export: any = false;
	Is_64VB_Report_Manage: any = false;

	Is_Mandate_Letter_Report: any = false;
	Is_Mandate_Letter_Report_View: any = false;
	Is_Mandate_Letter_Report_Export: any = false;

	Is_Extra_Payout_Report: any = false;
	Is_Extra_Payout_Report_View: any = false;
	Is_Extra_Payout_Report_Export: any = false;

	Is_Bulk_Upload: any = false;
	Is_Bulk_Upload_Extra_Payout: any = false;
	Is_Bulk_Upload_Recovery: any = false;

	Finance_Open_Lead: any = false;

	Is_Finance_Manager_Report: any = false;
	Is_Finance_Manager_Report_View: any = false;

	Is_Finance_DSA_Report: any = false;
	Is_Finance_DSA_Report_View: any = false;
	Is_Finance_DSA_Report_Export: any = false;

	Is_Customize_Report: any = false;
	Is_Agent_Fields_Update: any = false;

	Is_PO_Request_Approval: any = false;

	Is_PO_Request_Approval_Type: any='0';

	Is_NonMotor_File2FilePayIn: any = false;
  

	url: string = '';
	urlSafe: SafeResourceUrl;

	constructor(public dialogRef: MatDialogRef<EmployeeRightsComponent>, @Inject(MAT_DIALOG_DATA)
	public data: any,
		public api: ApiService,
		public formBuilder: FormBuilder,
		public sanitizer: DomSanitizer
	) {

	}

	ngOnInit() {
		this.Id = this.data.Id;

		this.url = environment.apiUrl + '/em/AllEmployee/ExportColumns?Emp_Id=' + this.Id;
		this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

		this.GetRights();
	}

	CloseModel(): void {
		this.dialogRef.close({
			Status: 'Model Close'
		});
	}

	GetRights() {
		//this.api.IsLoading();
		this.api.Call('em/AllEmployee/GetRights?Emp_Id=' + this.Id + '&User_Id=' + this.api.GetUserId()).then((result) => {
			//this.api.HideLoading();

			if (result['Status'] == true) {
				//this.CloseModel();

				this.row = result['Data'];
				//alert(this.row['Is_Operation']);

				this.Is_SR_Creation = (this.row['Is_SR_Creation'] == 1) ? true : false;
				this.Is_Operation = (this.row['Is_Operation'] == 1) ? true : false;
				this.Is_Sales_Support = (this.row['Is_Sales_Support'] == 1) ? true : false;
				this.Is_Accounts = (this.row['Is_Accounts'] == 1) ? true : false;
				this.Is_Sales = (this.row['Is_Sales'] == 1) ? true : false;
				this.Underwriter_Motor = (this.row['Underwriter_Motor'] == 1) ? true : false;
				this.Underwriter_Non_Motor = (this.row['Underwriter_Non_Motor'] == 1) ? true : false;
				this.Underwriter_Health = (this.row['Underwriter_Health'] == 1) ? true : false;
				this.Underwriter_Life = (this.row['Underwriter_Life'] == 1) ? true : false;
				this.Underwriter_Travel = (this.row['Underwriter_Travel'] == 1) ? true : false;
				this.Underwriter_PA = (this.row['Underwriter_PA'] == 1) ? true : false;

				this.Is_UAT = (this.row['Is_UAT'] == 1) ? true : false;
				this.Is_Agent_Fields_Update = (this.row['Is_Agent_Fields_Update'] == 1) ? true : false;

				this.Finance_Coordinator = (this.row['Finance_Coordinator'] == 1) ? true : false;
				this.Is_FC_Type = this.row['Is_FC_Type'];
				this.Is_Finance_Reconciler = (this.row['Is_Finance_Reconciler'] == 1) ? true : false;
				this.Finance_Manager = (this.row['Finance_Manager'] == 1) ? true : false;
				this.Finance_Telecaller = (this.row['Finance_Telecaller'] == 1) ? true : false;

				this.Is_Finance_Manager_Report = (this.row['Is_Finance_Manager_Report'] == 1) ? true : false;
				this.Is_Finance_Manager_Report_View = this.row['Is_Finance_Manager_Report_View']; // == 1)? true:false;

				this.Is_Finance_DSA_Report = (this.row['Is_Finance_DSA_Report'] == 1) ? true : false;
				this.Is_Finance_DSA_Report_View = this.row['Is_Finance_DSA_Report_View'];
				this.Is_Finance_DSA_Report_Export = (this.row['Is_Finance_DSA_Report_Export'] == 1) ? true : false;

				this.Is_Finance_SR_Report = (this.row['Is_Finance_SR_Report'] == 1) ? true : false;
				this.Is_Finance_SR_Report_View = this.row['Is_Finance_SR_Report_View']; // == 1)? true:false;
				this.Is_Finance_SR_Excel_Export = (this.row['Is_Finance_SR_Excel_Export'] == 1) ? true : false;
				this.Is_Finance_Revenue_Figure_View = (this.row['Is_Finance_Revenue_Figure_View'] == 1) ? true : false;
				
				this.Is_Survey = (this.row['Is_Survey'] == 1) ? true : false;
				this.Is_Survey_Report_View = this.row['Is_Survey_Report_View']; // == 1)? true:false;
				this.Is_Survey_Create = (this.row['Is_Survey_Create'] == 1) ? true : false;
				this.Is_Survey_Action = (this.row['Is_Survey_Action'] == 1) ? true : false;
				this.Is_Survey_Excel_Export = (this.row['Is_Survey_Excel_Export'] == 1) ? true : false;

				this.Is_AddInsuranceBranch = (this.row['Is_AddInsuranceBranch'] == 1) ? true : false;
				this.Is_Add_MakeModel = (this.row['Is_Add_MakeModel'] == 1) ? true : false;
				this.Is_AddBank = (this.row['Is_AddBank'] == 1) ? true : false;
				this.Is_SR_Edit = (this.row['Is_SR_Edit'] == 1) ? true : false;
				this.Is_POS_Club = (this.row['Is_POS_Club'] == 1) ? true : false;
				this.Is_POS_Login = (this.row['Is_POS_Login'] == 1) ? true : false;

				this.CutCopyPaste = (this.row['CutCopyPaste'] == 1) ? true : false;

				this.Is_Cross_Sell = (this.row['Is_Cross_Sell'] == 1) ? true : false;
				this.Is_Cross_Sell_Report_View = this.row['Is_Cross_Sell_Report_View'];

				this.Is_Cross_Sell_Report = (this.row['Is_Cross_Sell_Report'] == 1) ? true : false;
				this.Is_Cross_Sell_Report_View_2 = this.row['Is_Cross_Sell_Report_View_2'];
				this.Is_Cross_Sell_Excel_Report = (this.row['Is_Cross_Sell_Excel_Report'] == 1) ? true : false;

				this.Is_Daily_Tracker = (this.row['Is_Daily_Tracker'] == 1) ? true : false;
				this.Is_Daily_Tracker_View = this.row['Is_Daily_Tracker_View'];

				this.Is_SP_POS_Conversion_Report = (this.row['Is_SP_POS_Conversion_Report'] == 1) ? true : false;
				this.Is_SP_POS_Conversion_Report_View = this.row['Is_SP_POS_Conversion_Report_View'];
				this.Is_SP_POS_Conversion_Excel_Report = (this.row['Is_SP_POS_Conversion_Excel_Report'] == 1) ? true : false;

				this.Is_SP_POS_Conversion_Report = (this.row['Is_SP_POS_Conversion_Report'] == 1) ? true : false;
				this.Is_SP_POS_Conversion_Report_View = this.row['Is_SP_POS_Conversion_Report_View'];
				this.Is_POSCategorization_Excel_Report = (this.row['Is_POSCategorization_Excel_Report'] == 1) ? true : false;

				this.Is_Agent_Report = (this.row['Is_Agent_Report'] == 1) ? true : false;
				this.Is_Agent_Report_View = this.row['Is_Agent_Report_View'];
				this.Is_Agent_Report_Excel_Export = (this.row['Is_Agent_Report_Excel_Export'] == 1) ? true : false;
				this.Agent_QC = (this.row['Agent_QC'] == 1) ? true : false;
				this.SPC_QC = (this.row['SPC_QC'] == 1) ? true : false;
				this.Fleet_QC = (this.row['Fleet_QC'] == 1) ? true : false;
				this.Edit_Agent = (this.row['Edit_Agent'] == 1) ? true : false;
				this.Is_Merging = (this.row['Is_Merging'] == 1) ? true : false;
				this.Is_Agent_Status_Action = (this.row['Is_Agent_Status_Action'] == 1) ? true : false;

				this.Is_SR_Report = (this.row['Is_SR_Report'] == 1) ? true : false;
				this.Is_SR_Report_Excel_Export = (this.row['Is_SR_Report_Excel_Export'] == 1) ? true : false;

				this.Is_SR_Report_Motor = (this.row['Is_SR_Report_Motor'] == 1) ? true : false;
				this.Is_SR_Report_View_Motor = this.row['Is_SR_Report_View_Motor'];

				this.Is_SR_Report_Health = (this.row['Is_SR_Report_Health'] == 1) ? true : false;
				this.Is_SR_Report_View_Health = this.row['Is_SR_Report_View_Health'];

				this.Is_SR_Report_NonMotor = (this.row['Is_SR_Report_NonMotor'] == 1) ? true : false;
				this.Is_SR_Report_View_NonMotor = this.row['Is_SR_Report_View_NonMotor'];

				this.Is_SR_Report_Travel = (this.row['Is_SR_Report_Travel'] == 1) ? true : false;
				this.Is_SR_Report_View_Travel = this.row['Is_SR_Report_View_Travel'];

				this.Is_SR_Report_PA = (this.row['Is_SR_Report_PA'] == 1) ? true : false;
				this.Is_SR_Report_View_PA = this.row['Is_SR_Report_View_PA'];

				this.Is_SR_Report_Life = (this.row['Is_SR_Report_Life'] == 1) ? true : false;
				this.Is_SR_Report_View_Life = this.row['Is_SR_Report_View_Life'];

				this.Is_Customize_Report = (this.row['Is_Customize_Report'] == 1) ? true : false;;

				this.POS_ORC_Report = (this.row['POS_ORC_Report'] == 1) ? true : false;
				this.ORC_QC = (this.row['ORC_QC'] == 1) ? true : false;
				this.ORC_Banking_QC = (this.row['ORC_Banking_QC'] == 1) ? true : false;
				this.POS_ORC_Excel_Export = (this.row['POS_ORC_Excel_Export'] == 1) ? true : false;

				this.Is_Agent_RM_Mapping = (this.row['Is_Agent_RM_Mapping'] == 1) ? true : false;

				if (this.row['Is_Agent_Mapping_QC'] == 1) {
					this.Is_Agent_Mapping_QC_Type = 'QC';
				} else if (this.row['Is_Agent_Mapping_Banking_QC'] == 1) {
					this.Is_Agent_Mapping_QC_Type = 'Banking';
				} else {
					this.Is_Agent_Mapping_QC_Type = false;
				}

				this.Is_Brokerage = (this.row['Is_Brokerage'] == 1) ? true : false;
				this.Is_Bussiness_Report = (this.row['Is_Bussiness_Report'] == 1) ? true : false;
				this.Is_BBR_Report = (this.row['Is_BBR_Report'] == 1) ? true : false;
				this.Is_PO_Requests_Report = (this.row['Is_PO_Requests_Report'] == 1) ? true : false;
				this.Is_Files_PO_Request = (this.row['Is_Files_PO_Request'] == 1) ? true : false;
				this.Is_Recovery = (this.row['Is_Recovery'] == 1) ? true : false;
				this.Is_PayIn = (this.row['Is_PayIn'] == 1) ? true : false;
				this.Is_PayIn_Request = (this.row['Is_PayIn_Request'] == 1) ? true : false;
				this.Is_Payout_Request = (this.row['Is_Payout_Request'] == 1) ? true : false;
				this.Is_Payout_Posting = (this.row['Is_Payout_Posting'] == 1) ? true : false;
				this.Is_PO_Statment = (this.row['Is_PO_Statment'] == 1) ? true : false;
				this.Is_Agent_TDS = (this.row['Is_Agent_TDS'] == 1) ? true : false;
				this.Is_Pivot_Report = (this.row['Is_Pivot_Report'] == 1) ? true : false;
				this.Is_Reconciliation_Report = (this.row['Is_Reconciliation_Report'] == 1) ? true : false;

				this.Is_Employee = (this.row['Is_Employee'] == 1) ? true : false;
				this.Is_Employee_View = (this.row['Is_Employee_View'] == 1) ? true : false;
				this.Is_Employee_Add = (this.row['Is_Employee_Add'] == 1) ? true : false;
				this.Is_Employee_Edit = (this.row['Is_Employee_Edit'] == 1) ? true : false;
				this.Is_Employee_Rights = (this.row['Is_Employee_Rights'] == 1) ? true : false;

				this.Sales_PE = (this.row['Sales_PE'] == 1) ? true : false;
				this.Sales_PE_Report_View = this.row['Sales_PE_Report_View'];

				//===== Cross Selling Start =====//
				this.Is_Cross_Selling = (this.row['Is_Cross_Selling'] == 1) ? true : false;

				this.Is_RM_Tracking_Report = (this.row['Is_RM_Tracking_Report'] == 1) ? true : false;
				this.Is_RM_Tracking_Report_View = this.row['Is_RM_Tracking_Report_View'];

				this.Is_Manager_Tracking_Report = (this.row['Is_Manager_Tracking_Report'] == 1) ? true : false;
				this.Is_Manager_Tracking_Report_View = this.row['Is_Manager_Tracking_Report_View'];

				this.Is_Daily_Tracker = (this.row['Is_Daily_Tracker'] == 1) ? true : false;
				this.Is_Daily_Tracker_View = this.row['Is_Daily_Tracker_View'];
				//===== Cross Selling End =====//

				//===== Daily Tracking Circle Start =====//
				this.Is_Daily_Tracking_Circle = (this.row['Is_Daily_Tracking_Circle'] == 1) ? true : false;
				this.Is_Circle_Non_Motor_View = this.row['Is_Circle_Non_Motor'];
				this.Is_Circle_Health_View = this.row['Is_Circle_Health'];
				this.Is_Circle_Life_View = this.row['Is_Circle_Life'];
				this.Is_Circle_Finance_View = this.row['Is_Circle_Finance'];
				this.Is_Circle_Mutual_Fund_View = this.row['Is_Circle_Mutual_Fund'];
				//===== Daily Tracking Circle End =====//

				//===== Is 64 VB Report Start =====//
				this.Is_64VB_Report = (this.row['Is_64VB_Report'] == 1) ? true : false;
				this.Is_64VB_Report_View = this.row['Is_64VB_Report_View'];
				this.Is_64VB_Report_Export = (this.row['Is_64VB_Report_Export'] == 1) ? true : false;
				this.Is_64VB_Report_Manage = (this.row['Is_64VB_Report_Manage'] == 1) ? true : false;
				//===== Is 64 VB Report End =====//

				//===== Mandate Letter Report Start =====//
				this.Is_Mandate_Letter_Report = (this.row['Is_Mandate_Letter_Report'] == 1) ? true : false;
				this.Is_Mandate_Letter_Report_View = this.row['Is_Mandate_Letter_Report_View'];
				this.Is_Mandate_Letter_Report_Export = (this.row['Is_Mandate_Letter_Report_Export'] == 1) ? true : false;
				//===== Mandate Letter Report End =====//

				

				//===== Bulk Upload Data Start =====//
				this.Is_Bulk_Upload = (this.row['Is_Bulk_Upload'] == 1) ? true : false;
				this.Is_Bulk_Upload_Extra_Payout = (this.row['Is_Bulk_Upload_Extra_Payout'] == 1) ? true : false;
				this.Is_Bulk_Upload_Recovery = (this.row['Is_Bulk_Upload_Recovery'] == 1) ? true : false;
				//=====Bulk Upload Data End=====//

				this.Finance_Open_Lead = (this.row['Finance_Open_Lead'] == 1) ? true : false;

				this.Is_PO_Request_Approval = (this.row['Is_PO_Request_Approval'] == '1' || this.row['Is_PO_Request_Approval'] == '2') ? true : false;

				if(this.row['Is_PO_Request_Approval'] == '1'){ this.Is_PO_Request_Approval_Type = '1';}
				if(this.row['Is_PO_Request_Approval'] == '2'){ this.Is_PO_Request_Approval_Type = '2';}
				
				this.Is_NonMotor_File2FilePayIn = (this.row['Is_NonMotor_File2FilePayIn'] == 1) ? true : false; 
					
				

				//this.api.ToastMessage(result['Message']);
			} else {
				this.api.ErrorMsg(result['Message']);
			}

		}, (err) => {
			// Error log
			this.api.HideLoading();
			//console.log(err.message);
			this.api.ErrorMsg(err.message);
		});
	}


	//====== SUBMIT RIGHTS FORM =====//
	SubmitForm() {

		const formData = new FormData();

		formData.append('User_Id', this.api.GetUserId());
		formData.append('Emp_Id', this.Id);

		formData.append('Is_SR_Creation', this.Is_SR_Creation);
		formData.append('Is_Operation', this.Is_Operation);
		formData.append('Is_Sales_Support', this.Is_Sales_Support);
		formData.append('Is_Accounts', this.Is_Accounts);
		formData.append('Is_Sales', this.Is_Sales);
		formData.append('Underwriter_Motor', this.Underwriter_Motor);
		formData.append('Underwriter_Non_Motor', this.Underwriter_Non_Motor);
		formData.append('Underwriter_Health', this.Underwriter_Health);
		formData.append('Underwriter_Life', this.Underwriter_Life);
		formData.append('Underwriter_Travel', this.Underwriter_Travel);
		formData.append('Underwriter_PA', this.Underwriter_PA);
		formData.append('Is_Finance_Revenue_Figure_View', this.Is_Finance_Revenue_Figure_View); 
		formData.append('Is_UAT', this.Is_UAT);

		formData.append('Is_Agent_Fields_Update', this.Is_Agent_Fields_Update);

		formData.append('Finance_Coordinator', this.Finance_Coordinator);
		formData.append('Is_FC_Type', this.Is_FC_Type);
		formData.append('Finance_Manager', this.Finance_Manager);
		formData.append('Finance_Telecaller', this.Finance_Telecaller);
		formData.append('Is_Finance_Reconciler', this.Is_Finance_Reconciler);
		formData.append('Is_Finance_SR_Report', this.Is_Finance_SR_Report);
		formData.append('Is_Finance_SR_Report_View', this.Is_Finance_SR_Report_View);
		formData.append('Is_Finance_SR_Excel_Export', this.Is_Finance_SR_Excel_Export);


		formData.append('Is_Finance_DSA_Report', this.Is_Finance_DSA_Report);
		formData.append('Is_Finance_DSA_Report_View', this.Is_Finance_DSA_Report_View);
		formData.append('Is_Finance_DSA_Report_Export', this.Is_Finance_DSA_Report_Export);

		formData.append('Is_Survey', this.Is_Survey);
		formData.append('Is_Survey_Report_View', this.Is_Survey_Report_View);
		formData.append('Is_Survey_Create', this.Is_Survey_Create);
		formData.append('Is_Survey_Action', this.Is_Survey_Action);
		formData.append('Is_Survey_Excel_Export', this.Is_Survey_Excel_Export);

		formData.append('Is_AddInsuranceBranch', this.Is_AddInsuranceBranch);
		formData.append('Is_Add_MakeModel', this.Is_Add_MakeModel);
		formData.append('Is_AddBank', this.Is_AddBank);
		formData.append('Is_SR_Edit', this.Is_SR_Edit);
		formData.append('Is_POS_Club', this.Is_POS_Club);
		formData.append('Is_POS_Login', this.Is_POS_Login);

		formData.append('CutCopyPaste', this.CutCopyPaste);

		formData.append('Is_Cross_Sell', this.Is_Cross_Sell);
		formData.append('Is_Cross_Sell_Report_View', this.Is_Cross_Sell_Report_View);

		formData.append('Is_Cross_Sell_Report', this.Is_Cross_Sell_Report);
		formData.append('Is_Cross_Sell_Report_View_2', this.Is_Cross_Sell_Report_View_2);
		formData.append('Is_Cross_Sell_Excel_Report', this.Is_Cross_Sell_Excel_Report);

		formData.append('Is_Daily_Tracker', this.Is_Daily_Tracker);
		formData.append('Is_Daily_Tracker_View', this.Is_Daily_Tracker_View);

		formData.append('Is_POSCategorization_Report', this.Is_POSCategorization_Report);
		formData.append('Is_POSCategorization_Report_View', this.Is_POSCategorization_Report_View);
		formData.append('Is_POSCategorization_Excel_Report', this.Is_POSCategorization_Excel_Report);

		formData.append('Is_SP_POS_Conversion_Report', this.Is_SP_POS_Conversion_Report);
		formData.append('Is_SP_POS_Conversion_Report_View', this.Is_SP_POS_Conversion_Report_View);
		formData.append('Is_SP_POS_Conversion_Excel_Report', this.Is_SP_POS_Conversion_Excel_Report);

		formData.append('Is_Agent_Report', this.Is_Agent_Report);
		formData.append('Is_Agent_Report_View', this.Is_Agent_Report_View);
		formData.append('Is_Agent_Report_Excel_Export', this.Is_Agent_Report_Excel_Export);
		formData.append('Agent_QC', this.Agent_QC);
		formData.append('SPC_QC', this.SPC_QC);
		formData.append('Fleet_QC', this.Fleet_QC);
		formData.append('Edit_Agent', this.Edit_Agent);
		formData.append('Is_Merging', this.Is_Merging);
		formData.append('Is_Agent_Status_Action', this.Is_Agent_Status_Action);

		formData.append('POS_ORC_Report', this.POS_ORC_Report);
		formData.append('ORC_QC', this.ORC_QC);
		formData.append('ORC_Banking_QC', this.ORC_Banking_QC);
		formData.append('POS_ORC_Excel_Export', this.POS_ORC_Excel_Export);

		formData.append('Is_SR_Report', this.Is_SR_Report);
		formData.append('Is_SR_Report_Excel_Export', this.Is_SR_Report_Excel_Export);

		formData.append('Is_SR_Report_Motor', this.Is_SR_Report_Motor);
		formData.append('Is_SR_Report_View_Motor', this.Is_SR_Report_View_Motor);

		formData.append('Is_SR_Report_Health', this.Is_SR_Report_Health);
		formData.append('Is_SR_Report_View_Health', this.Is_SR_Report_View_Health);

		formData.append('Is_SR_Report_NonMotor', this.Is_SR_Report_NonMotor);
		formData.append('Is_SR_Report_View_NonMotor', this.Is_SR_Report_View_NonMotor);

		formData.append('Is_SR_Report_Travel', this.Is_SR_Report_Travel);
		formData.append('Is_SR_Report_View_Travel', this.Is_SR_Report_View_Travel);

		formData.append('Is_SR_Report_PA', this.Is_SR_Report_PA);
		formData.append('Is_SR_Report_View_PA', this.Is_SR_Report_View_PA);

		formData.append('Is_SR_Report_Life', this.Is_SR_Report_Life);
		formData.append('Is_SR_Report_View_Life', this.Is_SR_Report_View_Life);

		formData.append('Is_Customize_Report', this.Is_Customize_Report);

		formData.append('Is_Agent_RM_Mapping', this.Is_Agent_RM_Mapping);
		formData.append('Is_Agent_Mapping_QC_Type', this.Is_Agent_Mapping_QC_Type);

		formData.append('Is_Brokerage', this.Is_Brokerage);
		formData.append('Is_Bussiness_Report', this.Is_Bussiness_Report);
		formData.append('Is_BBR_Report', this.Is_BBR_Report);
		formData.append('Is_PO_Requests_Report', this.Is_PO_Requests_Report);
		formData.append('Is_Files_PO_Request', this.Is_Files_PO_Request);
		formData.append('Is_Recovery', this.Is_Recovery);
		formData.append('Is_PayIn', this.Is_PayIn);
		formData.append('Is_PayIn_Request', this.Is_PayIn_Request);
		formData.append('Is_Payout_Request', this.Is_Payout_Request);
		formData.append('Is_Payout_Posting', this.Is_Payout_Posting);
		formData.append('Is_PO_Statment', this.Is_PO_Statment);
		formData.append('Is_Agent_TDS', this.Is_Agent_TDS);
		formData.append('Is_Pivot_Report', this.Is_Pivot_Report);
		formData.append('Is_Reconciliation_Report', this.Is_Reconciliation_Report);

		formData.append('Is_Employee', this.Is_Employee);
		formData.append('Is_Employee_View', this.Is_Employee_View);
		formData.append('Is_Employee_Add', this.Is_Employee_Add);
		formData.append('Is_Employee_Edit', this.Is_Employee_Edit);
		formData.append('Is_Employee_Rights', this.Is_Employee_Rights);

		formData.append('Sales_PE', this.Sales_PE);
		formData.append('Sales_PE_Report_View', this.Sales_PE_Report_View);

		//===== Cross Selling Start=====//
		formData.append('Is_Cross_Selling', this.Is_Cross_Selling);

		formData.append('Is_RM_Tracking_Report', this.Is_RM_Tracking_Report);
		formData.append('Is_RM_Tracking_Report_View', this.Is_RM_Tracking_Report_View);

		formData.append('Is_Manager_Tracking_Report', this.Is_Manager_Tracking_Report);
		formData.append('Is_Manager_Tracking_Report_View', this.Is_Manager_Tracking_Report_View);

		formData.append('Is_Daily_Tracker', this.Is_Daily_Tracker);
		formData.append('Is_Daily_Tracker_View', this.Is_Daily_Tracker_View);
		//===== Cross Selling End =====//

		//=====Daily Tracking Circle Start =====//
		formData.append('Is_Daily_Tracking_Circle', this.Is_Daily_Tracking_Circle);
		formData.append('Is_Circle_Non_Motor_View', this.Is_Circle_Non_Motor_View);
		formData.append('Is_Circle_Health_View', this.Is_Circle_Health_View);
		formData.append('Is_Circle_Life_View', this.Is_Circle_Life_View);
		formData.append('Is_Circle_Finance_View', this.Is_Circle_Finance_View);
		formData.append('Is_Circle_Mutual_Fund_View', this.Is_Circle_Mutual_Fund_View);
		//=====Daily Tracking Circle End =====//

		//===== Is 64 VB Report Start =====//
		formData.append('Is_64VB_Report', this.Is_64VB_Report);
		formData.append('Is_64VB_Report_View', this.Is_64VB_Report_View);
		formData.append('Is_64VB_Report_Export', this.Is_64VB_Report_Export);
		formData.append('Is_64VB_Report_Manage', this.Is_64VB_Report_Manage);
		//===== Is 64 VB Report End =====//

		//===== Mandate Letter Report Start =====//
		formData.append('Is_Mandate_Letter_Report', this.Is_Mandate_Letter_Report);
		formData.append('Is_Mandate_Letter_Report_View', this.Is_Mandate_Letter_Report_View);
		formData.append('Is_Mandate_Letter_Report_Export', this.Is_Mandate_Letter_Report_Export);
		//===== Mandate Letter Report End =====//

		//===== Bulk Upload Data Start =====//
		formData.append('Is_Bulk_Upload', this.Is_Bulk_Upload);
		formData.append('Is_Bulk_Upload_Extra_Payout', this.Is_Bulk_Upload_Extra_Payout);
		formData.append('Is_Bulk_Upload_Recovery', this.Is_Bulk_Upload_Recovery);
		//=====Bulk Upload Data End=====//

		formData.append('Is_Finance_Manager_Report', this.Is_Finance_Manager_Report);
		formData.append('Is_Finance_Manager_Report_View', this.Is_Finance_Manager_Report_View);
		formData.append('Is_NonMotor_File2FilePayIn', this.Is_NonMotor_File2FilePayIn);

		formData.append('Finance_Open_Lead', this.Finance_Open_Lead);

		formData.append('Is_PO_Request_Approval', '0');
		
		if(this.Is_PO_Request_Approval_Type == '1'){ 
			formData.append('Is_PO_Request_Approval', '1');
		}
		if(this.Is_PO_Request_Approval_Type == '2'){ 
			formData.append('Is_PO_Request_Approval', '2');
		}

		
		this.api.IsLoading();
		this.api.HttpPostType('em/AllEmployee/AssignRights', formData).then((result) => {
			this.api.HideLoading();

			if (result['Status'] == true) {
				this.CloseModel();

				this.api.ToastMessage(result['Message']);
			} else {
				this.api.ErrorMsg(result['Message']);
			}

		}, (err) => {
			// Error log
			this.api.HideLoading();
			//console.log(err.message);
			this.api.ErrorMsg(err.message);
		});

	}

	ORCSingleCheck(e, Type) {
		console.log(e.target.value);
		console.log(Type);
		if (Type == 'ORC_QC') {
			this.ORC_Banking_QC = false;
		}
		if (Type == 'ORC_Banking_QC') {
			this.ORC_QC = false;
		}

	}


}
