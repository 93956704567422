import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../../../providers/api.service';

@Component({
  selector: 'app-add-finance-payin',
  templateUrl: './add-finance-payin.component.html',
  styleUrls: ['./add-finance-payin.component.css']
})

export class AddFinancePayinComponent implements OnInit {

  PayInForm: FormGroup;
  isSubmitted = false;

  dropdownSettings: any = {};
  dropdownSettings1: any = {};
  dropdownSettingsSingle: any = {};
  selectedItems: any = [];

  value: any;
  selected: any;

  User_Rights: any = [];

  Financier_Ar: any = [];
  States_Ar: any = [];
  Products_Ar: any = [];
  SubProducts_Ar: any = [];
  Product_Type_Ar: any = [];

  Business_Type_Ar: any = [];
  DSA_Ar: any = [];
  Slab_Ar: any = [];
  SlabTypeSelected: any = [];

  PayInApplicableType_Ar: any = [];

  Id: any = 0;
  ViewType: any = 0;
  Action: any = '';

  constructor(public api: ApiService, private router: Router, private fb: FormBuilder, public dialogRef: MatDialogRef<AddFinancePayinComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.Id = this.data.Id;
    this.ViewType = this.data.Type;

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false
    };

    this.dropdownSettingsSingle = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'Id',
      textField: 'Name',
    };

  }

  ngOnInit() {

    this.PayInForm = this.fb.group({
      Financier_Name: ['', [Validators.required]],
      Product_Name: ['', [Validators.required]],
      Product_Type: ['', [Validators.required]],
      States_Ids: ['', [Validators.required]],
      Business_Type: ['', [Validators.required]],
      DSA: ['', [Validators.required]],
      InterestRateFrom: ['', [Validators.required, Validators.pattern('^[0-9]{0,2}(\.[0-9]{0,2})?$')]],
      InterestRateTo: ['', [Validators.required, Validators.pattern('^[0-9]{0,2}(\.[0-9]{0,2})?$')]],
      SlabType: ['', [Validators.required]],
      PayIn: ['0'],
      PayOut: ['0'],
      PayInGroup: this.fb.array([]),
      Effective_Date: ['', [Validators.required]],
      Extra_Remark: ['', [Validators.required]],
    });

    this.ComponentsData();
    this.PayInGroup().push(this.NewPayInGroup());

  }


  //===== SET FORM VALIDATION =====//
  get FC() { return this.PayInForm.controls; }


  //===== CLOSE MODAL =====//
  CloseModel(): void {
    this.dialogRef.close({
      Status: 'Model Close'
    });
  }


  PayInGroup(): FormArray {
    return this.PayInForm.get("PayInGroup") as FormArray
  }


  removePayInGroup(i: number) {
    this.PayInGroup().removeAt(i);
  }


  NewPayInGroup(): FormGroup {

    return this.fb.group({
      PayInPremimSlabFrom: [''],
      PayInPremimSlabUpTo: [''],

      PayIn: [{ value: '' }],
      PayOut: [{ value: '' }],

    })
  }


  AddMore() {
    this.PayInGroup().push(this.NewPayInGroup());
  }


  PayIn(e: any) {
    var PayInNet = e.target.value;
    const PayOutNet_Control = this.PayInForm.get('PayOut');
    console.log(PayInNet);
    console.log(PayInNet * 30 / 100);
    PayOutNet_Control.setValue(PayInNet - (PayInNet * 30 / 100));
  }


  checkPayInPremimSlab(e, index) {
    document.getElementById("PayInPremimSlabUpTo_Ids_" + index).innerHTML = '';
    document.getElementById("PayInPremimSlabFrom_Ids_" + index).innerHTML = '';

  }


  PayInGroupPercentage(e: any, index: any, Type: any) {

    const PayInGroupFields = (<FormArray>this.PayInForm.get("PayInGroup")).at(index);

    var PayIn_Index_value = e.target.value;
    console.log(PayIn_Index_value * 30 / 100);

    let PayOut_value = PayIn_Index_value - (PayIn_Index_value * 30 / 100);

    if (Type == 'PayIn') {
      PayInGroupFields.patchValue({
        PayOut: PayOut_value
      });
      document.getElementById("PayIn_Net_Ids_" + index).innerHTML = '';
    }

  }


  //===== GET COMPONENTS DATA =====//
  ComponentsData() {

    const formData = new FormData();
    formData.append('User_Id', this.api.GetUserId());
    formData.append('Id', this.Id);
    formData.append('ViewType', this.ViewType);

    this.api.IsLoading();
    this.api.HttpPostType('../v2/finance/FinancePayin/GetComponentsData', formData).then((result) => {
      this.api.HideLoading();
      if (result['Status'] == true) {

        this.User_Rights = result['Data']['SR_User_Rights'];
        this.Financier_Ar = result['Data']['FinancerAr'];
        this.Products_Ar = result['Data']['ProductAr'];
        this.DSA_Ar = result['Data']['DSAAr'];
        this.States_Ar = result['Data']['StateAr'];
        this.Slab_Ar = result['Data']['Slab_Ar'];
        this.Business_Type_Ar = result['Data']['Business_Type_Ar'];

        if (this.ViewType == 'View') {
          this.Product_Type_Ar = result['Data']['ProductTypeAr'];
          this.SlabTypeSelected = result['Data']['PayInFormAr']['SlabType'];
          this.PayInForm.patchValue(result['Data']['PayInFormAr']);
        }

      } else {

        this.api.ErrorMsg(result['Message']);
        this.CloseModel();
      }

    }, (err) => {
      // Error log
      this.api.HideLoading();
      this.api.ErrorMsg('Network Error, Please try again ! ' + err.message);
      this.CloseModel();
    });
  }


  //===== GET PRODUCT TYPE =====//
  GetProductType() {

    this.PayInForm.get('Product_Type').setValue('');

    if (this.PayInForm.value['Product_Name'].length == 0) {
      this.Product_Type_Ar = [];
      return;
    }

    const formData = new FormData();
    formData.append('Product_Ids', JSON.stringify(this.PayInForm.value['Product_Name']));

    this.api.HttpPostType('../v2/finance/FinancePayin/GetProductType', formData).then((result) => {

      this.api.HideLoading();
      if (result['Status'] == true) {
        this.Product_Type_Ar = result['Data'];
      }
    }, (err) => {
      // Error log
      this.api.HideLoading();
    });

  }


  //===== ACTION TYPE =====//
  ActionBtn(type: any) {
    this.Action = type;
    this.AddPayIn();
  }


  //===== ADD PAYIN DATA =====//
  AddPayIn() {

    if (this.PayInForm.value['SlabType'].length > 0 && this.PayInForm.value['SlabType'] == 'Slab') {

      var PayInGroupForm = this.PayInForm.get("PayInGroup").value;
      for (var i = 0; i < PayInGroupForm.length; i++) {

        var from = PayInGroupForm[i]['PayInPremimSlabFrom'];
        var upTo = PayInGroupForm[i]['PayInPremimSlabUpTo'];

        var PayIn = PayInGroupForm[i]['PayIn'];
        var PayOut = PayInGroupForm[i]['PayOut'];

        if (from == '' || from == null) {
          document.getElementById("PayInPremimSlabFrom_Ids_" + i).innerHTML = 'Please enter Pay-In Premimum Slab From';
        } else {
          document.getElementById("PayInPremimSlabFrom_Ids_" + i).innerHTML = '';
        }

        if (upTo == '' || upTo == null) {
          document.getElementById("PayInPremimSlabUpTo_Ids_" + i).innerHTML = 'Please enter Pay-In Premimum Slab UpTo';
        } else {
          document.getElementById("PayInPremimSlabUpTo_Ids_" + i).innerHTML = '';
        }

        if (from > upTo) {
          var msg = 'Please Enter Premimum Slab UpTo Greater than equal to Premimum Slab From';
          //alert(msg);
          document.getElementById("PayInPremimSlabUpTo_Ids_" + i).innerHTML = msg;
        } else {
          //document.getElementById("PayInNetPremimSlabUpTo_Ids_"+i).innerHTML='';
        }

        document.getElementById("PayIn" + i).innerHTML = 'Please enter Pay-In';

        if ((from == '' || from == null) || (upTo == '' || upTo == null)) {
          return;
        }

      }
    }


    this.isSubmitted = true;
    if (this.PayInForm.invalid) {
      return;

    } else {

      var fields = this.PayInForm.value;
      const formData = new FormData();

      formData.append('PayIn_Id', this.Id);
      formData.append('ViewType', this.ViewType);

      formData.append('User_Id', this.api.GetUserId());
      formData.append('Action', this.Action);

      formData.append('Financier_Name', JSON.stringify(fields['Financier_Name']));
      formData.append('Product_Name', JSON.stringify(fields['Product_Name']));
      formData.append('Product_Type', JSON.stringify(fields['Product_Type']));
      formData.append('States_Ids', JSON.stringify(fields['States_Ids']));
      formData.append('Business_Type', JSON.stringify(fields['Business_Type']));
      formData.append('DSA', JSON.stringify(fields['DSA']));
      formData.append('InterestRateFrom', fields['InterestRateFrom']);
      formData.append('InterestRateTo', fields['InterestRateTo']);

      if (this.ViewType == 'Add') {
        formData.append('SlabType', JSON.stringify(fields['SlabType']));
      } else {
        formData.append('SlabType', JSON.stringify(this.SlabTypeSelected));
      }

      formData.append('PayIn', fields['PayIn']);
      formData.append('PayOut', fields['PayOut']);

      formData.append('PayInGroup', JSON.stringify(fields['PayInGroup']));

      formData.append('Extra_Remark', fields['Extra_Remark']);
      formData.append('Effective_Date', fields['Effective_Date']);

      this.api.IsLoading();
      this.api.HttpPostType('../v2/finance/FinancePayin/AddPayin', formData).then((result) => {
        this.api.HideLoading();

        if (result['Status'] == true) {
          this.api.ToastMessage(result['Message']);
          this.CloseModel();

        } else {
          this.api.ErrorMsg(result['Message']);
        }
      }, (err) => {
        this.api.HideLoading();
        this.api.ErrorMsg('Network Error, Please try again ! ' + err.message);
      });

    }

  }


}