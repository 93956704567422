import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";

import { environment } from '../../environments/environment';
import swal from 'sweetalert';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

//import { SessionExpiredComponent } from '../modals/session-expired/session-expired.component';
//import { MatDialog,MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

apiUrl:string;
BaseUrlCrm: string;

   private messageSource = new BehaviorSubject({IsLogged :"FALSE"});
   currentMessage = this.messageSource.asObservable();
 
  constructor(
  private http: HttpClient,
  private spinner: NgxSpinnerService,
  private router:Router,
  //public dialog: MatDialog
  ) { 
	console.log('API Calling...'+ environment.apiUrl );
	 this.apiUrl = environment.apiUrl;
	 this.BaseUrlCrm = environment.BaseUrlCrm;
  }
  
  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  
  public IsLoading(){
	 this.spinner.show();  
  }
  public HideLoading(){
	 this.spinner.hide();  
  }
 
  public ErrorMsg(msg){
	 swal("Warning!", msg, "warning");
  }
  public ToastMessage(msg){
	 swal("success!", msg, "success");
  }
 
  public getHeader(){
	
	let api_key = this.GetUserData('JwtToken');
    return {};
	/*
	const httpOptions = {
		headers: new HttpHeaders({
			//"Access-Control-Allow-Origin": "*",
		  	Authorization: `Bearer ${api_key}`,
		}),
	  };
     
	return httpOptions;
	*/

  }

  public Call(paramsNames){

	const index = paramsNames.indexOf("?");
	var other_prams='';
	if (index !== -1) {
	  //alert(`The ? mark is found at index ${index}.`);
	  other_prams='&source=bms';
	} else {
		//alert("The ? mark is not found in the string.");
		other_prams='?source=bms';
	}
	 
	 var Returns = new Promise((resolve, reject) => {
		this.http.get(this.apiUrl+'/'+paramsNames+other_prams,this.getHeader())
		  .subscribe(res => {
			resolve(res);
			  
		  }, (err) => {
			reject(err);
			 
		  });
	  });  

	  Returns.then(async (res) => {
		if (res["Status"] == false && res["Token"] == false) {
			this.SessionExpired(res['Message']);
			return false;
		}
	});
	return Returns;
	   
  }
  
  public Call_2(paramsNames){
	   
	 return new Promise((resolve, reject) => {
		this.http.get(paramsNames)
		  .subscribe(res => {
			resolve(res);
			  
		  }, (err) => {
			reject(err);
			 
		  });
	  });  
	   
  }
  public Call3(paramsNames) {
	
	return new Promise((resolve, reject) => {
		this.http.get(this.BaseUrlCrm + '/' + paramsNames)
			.subscribe(res => {
				resolve(res);

			}, (err) => {
				reject(err);

			});
	});

} 
 

  public Call4(urls,paramsNames) {
	// this.apiUrl="https://api.policyonweb.com/API/v1";

	 
	return new Promise((resolve, reject) => {
		this.http.get(this.apiUrl + '/'+urls+''+paramsNames,this.getHeader())
			.subscribe(res => {
				resolve(res);

			}, (err) => {
				reject(err);

			});
	});



}


 

 public HttpPostType(apiName,data){

	const index = apiName.indexOf("?");
	var other_prams='';
	if (index !== -1) {
	  //alert(`The ? mark is found at index ${index}.`);
	  other_prams='&source=bms';
	} else {
		//alert("The ? mark is not found in the string.");
		other_prams='?source=bms';
	}

	var Returns = new Promise((resolve, reject) => {
        this.http
          .post(this.apiUrl + "/" + apiName+other_prams, data, this.getHeader())
          .subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
      });


	  Returns.then(async (res) => {
		if (res["Status"] == false && res["Token"] == false) {
			this.SessionExpired(res['Message']);
			return false;
		}
	 });
    return Returns;
 
	  /*
	 return new Promise((resolve, reject) => {
		this.http.post(this.apiUrl+'/'+apiName,data,requestOptions)
		  .subscribe(res => {
			resolve(res);
			  
		  }, (err) => {
			reject(err);
			 
		  });
	  }); 
	  */
	   

	  
  }

	SessionExpired(msg:any): void {

		this.ErrorMsg(msg);

		this.IsLoading(); 
		setTimeout(() => {
			/* spinner ends after 5 seconds */ 
			
			localStorage.removeItem('User');
			this.changeMessage({IsLogged :"FALSE"});
			this.HideLoading();
			
			this.router.navigate(['/login']);
		}, 1000);


		/*
			const dialogRef = this.dialog.open(SessionExpiredComponent, {
			width: '30%',
			height:'62%',
			disableClose : true,
			data: {Id : 0}
			});

			dialogRef.afterClosed().subscribe(result => {
			console.log(result);
			});
		*/
	
	}
  

  public HttpPostType1(apiName, data) {
 
	return new Promise((resolve, reject) => {
		this.http.post(this.BaseUrlCrm + '/' + apiName, data)
			.subscribe(res => {
				resolve(res);

			}, (err) => {
				reject(err);

			});
	});


}

  public HttpPostTypeCrm(apiName,data){
	 
	  
	return new Promise((resolve, reject) => {
	   this.http.post('http://13.127.142.101/sanity/ci/crmdev/api/'+apiName,data)
		 .subscribe(res => {
		   resolve(res);
			 
		 }, (err) => {
		   reject(err);
			
		 });
	 });  

	 
 }
  public Toast(Type,Message){
	  alert(Message);
  }
  

  public HttpPostTypeProxy(apiName, data) {

		

	return new Promise((resolve, reject) => {
		this.http.post(this.apiUrl + '/' + apiName, data)
			.subscribe(res => {
				resolve(res);

			}, (err) => {
				reject(err);

			});
	});


}

  public HttpForSR(Type,apiName,data){
	 
		 
		if(Type == 'post'){
			
			return new Promise((resolve, reject) => {
			   this.http.post(this.apiUrl+'/crm/'+apiName,data)
				 .subscribe(res => {
				   resolve(res);
					 
				 }, (err) => {
				   reject(err);
					
				 });
			 });  
		}else{
		   return new Promise((resolve, reject) => {
			   this.http.get(this.apiUrl+'/crm/'+apiName)
				 .subscribe(res => {
				   resolve(res);
					 
				 }, (err) => {
				   reject(err);
					
				 });
			 });  
   
		}  
	  
	}
	 
  
  public GetUserData(key){
	const data = JSON.parse(localStorage.getItem('User'));
	if(data != null){
		return data[key];
	}else{
		return 'FALSE';
	}
 	 
  }
  public GetUserId(){
	 
	if(this.GetUserData('Logged_In') == 'FALSE' ){
		return 0;
	}else{
		return this.GetUserData('User_Id');
	}
	
 	 
  } 
  
   public GetIs_Remember(key){
	const data = JSON.parse(localStorage.getItem('Is_Remember_Data'));
	if(data == null){
		return 0;
	}else{
		return data[key];
	}
 	 
  }
  
  
  public StandrdToDDMMYYY(d){
	  return d; 
	  console.log(d); 
	  console.log(new Date(d));
	  if(d=='' || d == null || d == '00-00-0000' ){
		  return '' ;
	  }else{
		  d = new Date(d);
		return d.getFullYear() + "-"+(d.getMonth()+1) +"-"+d.getDate(); // + ' '+d.toString().split(' ')[4];
		//return d.getDate() + "-"+(d.getMonth()+1) +"-"+d.getFullYear();
	  // for time part you may wish to refer http://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
	  }
	  
	}
	
	getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }


  public HttpGetType(apiName) {
	
		var Login_User_Id = this.GetUserData("Id");
		// var Login_User_Type = this.GetUserType();
		var currentUrl = this.router.url;

		if (apiName.indexOf("?") > -1)
		apiName +=
			"&Login_User_Id=" + Login_User_Id + "&SectionUrl=" + currentUrl;
		else
		apiName +=
			"?Login_User_Id=" + Login_User_Id + "&SectionUrl=" + currentUrl;

		var Returns = new Promise((resolve, reject) => {
		this.http.get(this.apiUrl + "/" + apiName).subscribe(
			(res) => {
			resolve(res);
			},
			(err) => {
			// reject(err);
			if (err.error.Status == "TokenError") {
			} else {
				reject(err);
			}
			}
		);
		});

		return Returns;

		//yaha tak
	}

  
}
